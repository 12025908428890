import { recordErrorInCrashalytics } from 'tatvarnsdk/Web';
export const couponActivated = (planDetails) => {
  if (planDetails.discountedPrice !== undefined && planDetails.discountedPrice !== null) {
    return true;
  }
  return false;
};

export const calculateDiscountFromCoupon = (planDetails) => {
  try {
    const originalPrice = planDetails.unit_amount;
    const discountedPrice = planDetails.discountedPrice;
    const discount = originalPrice - discountedPrice;
    return discount > 0 ? discount / 100 : 0;
  } catch (e) {
    recordErrorInCrashalytics(
      'calculateDiscountFromCoupon => app/modules/subscription/components/upgradesubscriptioncard/utils/couponutils',
      e
    );
    return 0;
  }
};
