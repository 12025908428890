import React from 'react';
import { BtnContainer, BtnText, Icon } from './IconWithText.styles';

export const IconWithText = ({ icon, id, onClick }) => {
  return (
    <BtnContainer onClick={onClick}>
      {icon ? <Icon src={icon} /> : null}
      <BtnText id={id} />
    </BtnContainer>
  );
};
