import styled from 'styled-components';

export const IllustrationWrapper = styled.div`
  margin: 0;
  flex-shrink: 0;
  height: ${(props) => (props.height ? props.height : '1.875rem')};
  height: ${(props) => (props.width ? props.width : '1.875rem')};
`;
export const IllustrationImage = styled.img`
  height: ${(props) => (props.height ? props.height : '1.875rem')};
  height: ${(props) => (props.width ? props.width : '1.875rem')};
`;
