import styled from 'styled-components';
import { media } from '@app/themes/index';
import { PrimaryButton } from '@app/modules/common/index';

export const StyledSavedCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 1.25rem;
  width: 60.5625rem;
  height: 17.25rem;
  flex-shrink: 0;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  background-color: ${(props) => props.theme.black};
  box-shadow: 4px 4px 20px 1px rgba(68, 68, 68, 0.25);
  color: ${(props) => props.theme.white};
  font-size: 1.375rem;
  ${media.lessThan('tablet')`
  flex-direction: column;
  min-height: 33rem;
  width: 26.4rem;
  `}
  .highlight {
    font-weight: 500;
    color: ${(props) => props.theme.highlight};
  }
`;

export const SubscriptionCardCover = styled.div`
  width: 30.875rem;
  height: 16.74575rem;
  flex-shrink: 0;
  position: relative;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
  width: 25.875rem;
  `}
  .card {
    opacity: ${(props) => (props.fail || props.retry ? 0.4 : 1)};
  }
`;
export const DescriptionCardCover = styled.div`
  margin-left: 2.813rem;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${media.lessThan('tablet')`
  padding-bottom: 1rem;
  margin-left: 0.813rem;
  `}
`;

export const PaymentFailed = styled.div`
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
  width: 25.875rem;
  `}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.25rem;
  z-index: 1;
`;
export const FailButton = styled(PrimaryButton)`
  position: absolute;
  margin-left: 38.75%;
  margin-top: -1.25rem;
  z-index: 2;
`;
export const Text = styled.div`
  color: ${(props) => props.theme.white};
  text-align: center;
  font-size: 2.5rem;
  width: 50%;
  font-style: normal;
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
`;
