import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  MainContainer,
  MyComponentWrapper,
  ButtonWrapper,
  SalutationWrapper,
  SignatureWrapper,
  ButtonStyle,
  HeaderWrapper
} from './DeleteAccount.styles';
import { deleteAccount } from './functionsUtils';
import { Translation, HeaderWithLogo, Loading } from '../common/index';
import { logToCrashalytics } from 'tatvarnsdk/Web';
import { getAuth } from 'firebase/auth';
//TODO: change inline css to styled-component
export default function DeleteAccount({ isLoggedIn }) {
  const [message, setMessage] = useState('');
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(true);
  useEffect(() => {
    logToCrashalytics('Delete Account Container');
    if (isLoggedIn) {
      setMessage('body_message');
    } else {
      setMessage('please_login');
    }
  }, [isLoggedIn]);
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderWithLogo />
      </HeaderWrapper>
      {!loading ? (
        <>
          <SalutationWrapper>
            <h3>
              <Translation id="dear_user" />
            </h3>
          </SalutationWrapper>
          <MyComponentWrapper>
            <Translation id={message} />
          </MyComponentWrapper>
          <SignatureWrapper>
            <h3>
              <Translation id="tatva_wellness" />
            </h3>
          </SignatureWrapper>
          {isLoggedIn && deleteBtn ? (
            <ButtonWrapper>
              <div onClick={() => setPopup(true)}>
                <ButtonStyle>
                  <h2>
                    <Translation id="delete_account" />
                  </h2>
                </ButtonStyle>
              </div>
            </ButtonWrapper>
          ) : null}
          <Dialog open={popup} onClose={() => setPopup(false)}>
            <DialogTitle>
              <Translation text="Are You Sure?" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Translation id="subscriptions_inst" />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonWrapper>
                <div
                  onClick={async () => {
                    setPopup(false);
                    setDeleteBtn(false);
                    const res = await deleteAccount(setLoading, setMessage);
                    if (res.success) {
                      setMessage('DELETE_SUCCESS_MSG');
                      getAuth().signOut();
                    } else {
                      setMessage('DELETE_FAILURE_MSG');
                    }
                    setLoading(false);
                  }}
                  style={{ marginBottom: '0.625rem', marginRight: '1.25rem' }}
                  color="primary"
                  autoFocus
                >
                  <ButtonStyle>
                    <h1>
                      <Translation id="delete_account" />
                    </h1>
                  </ButtonStyle>
                </div>
              </ButtonWrapper>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div className="Loading">
          <Loading />
        </div>
      )}
    </MainContainer>
  );
}
