import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeConfig } from '@app/routeConfig';
import {
  StyledUpgradeSubscriptionCard,
  PurchaseButton,
  StyledModal,
  StyledCancelButton,
  StyledConfirmButton,
  StyledDisciplineButton,
  UpgradeSubscriptionCardBody
} from './UpgradeSubscriptionCard.styles';
import { SubscriptionCardWrapper, getTitleAndInfo, Translation } from '@modules/common/index';
import Description from '../Description/Description';
import { couponActivated, calculateDiscountFromCoupon } from './utils/couponUtils';
import { getCheckoutDetails } from 'tatvarnsdk/Web';
function UpgradeSubscriptionCard({
  plan,
  userProfile,
  userSubscription,
  buttonTextId,
  userCardDetails,
  coupon,
  disciplineList
}) {
  const [disciplineObj, setDisciplineObj] = useState(null);
  const history = useHistory();
  const { title } = getTitleAndInfo(plan);

  const planDetails = getCheckoutDetails(plan, userProfile);
  const [isOpen, setIsOpen] = useState(false);

  const handlePurchase = () => {
    let couponObj = coupon ? { ...coupon, discount_amt: calculateDiscountFromCoupon(plan) } : null;
    if (userCardDetails && userCardDetails.length > 0) {
      history.push({
        pathname: routeConfig.payment.route,
        state: {
          planId: plan.id,
          userProfile: userProfile,
          userSubscription: userSubscription,
          cardDetails: userCardDetails,
          disciplineObj: disciplineObj,
          coupon: couponObj
        }
      });
    } else {
      history.push({
        pathname: routeConfig.payment.route,
        state: {
          planId: plan.id,
          userProfile: userProfile,
          userSubscription: userSubscription,
          disciplineObj: disciplineObj,
          coupon: couponObj
        }
      });
    }
  };

  return (
    <StyledUpgradeSubscriptionCard>
      <UpgradeSubscriptionCardBody onClick={title === 'GOLD' ? () => setIsOpen(true) : handlePurchase}>
        <div className="title">
          {planDetails.couponDiscount !== null ? (
            <>
              <span className="price stroked">
                <Translation text={planDetails.planPrice} />
              </span>
              <span className="couponPrice">
                <Translation text={planDetails.titlePrice} />
              </span>
              <span className="subtitle">
                <Translation text={planDetails.subtitle} />
              </span>
            </>
          ) : (
            <>
              <span className="price">
                <Translation text={planDetails.titlePrice === 0 ? 'FREE' : planDetails.titlePrice} />
              </span>
              <span className="subtitle">
                <Translation text={planDetails.subtitle} />
              </span>
            </>
          )}
        </div>
        <div className="card">
          <SubscriptionCardWrapper plan={plan} type={'UPGRADE_SUBSCRIPTION'} />
        </div>
        <Description description={planDetails.descriptionList} />
        <PurchaseButton textId={planDetails.btnId} />
      </UpgradeSubscriptionCardBody>
      {title === 'GOLD' && (
        <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="title">
            <Translation id="SELECT_DISCIPLINE" />
          </div>
          <div className="disciplines">
            {disciplineList?.map((discipline) => (
              <StyledDisciplineButton
                text={discipline.name}
                key={discipline.id}
                active={disciplineObj?.id === discipline.id}
                onClick={() => setDisciplineObj(discipline)}
              />
            ))}
          </div>
          {/* <p className="info">
            <Translation id="UPGRADE_INFO" />
          </p> */}
          <div className="confirmSection">
            <StyledCancelButton
              textId={'cancel'}
              onClick={() => {
                setIsOpen(false);
                setDisciplineObj(null);
              }}
            />
            <StyledConfirmButton
              textId={'confirm'}
              onClick={() => {
                if (disciplineObj === null) {
                  return;
                }
                handlePurchase();
              }}
            />
          </div>
        </StyledModal>
      )}
    </StyledUpgradeSubscriptionCard>
  );
}

export default UpgradeSubscriptionCard;
