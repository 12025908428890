export const getMonthAndYearList = () => {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const monthOptions = [];
  const yearOptions = [];

  for (let i = 0; i < 12; i++) {
    const month = months[i];
    const monthObj = {
      label: month,
      value: month
    };
    monthOptions.push(monthObj);
  }
  let year = new Date().getFullYear();
  for (let i = 0; i < 12; i++) {
    const yearInt = Number(year) + i;
    const yearObj = {
      label: String(yearInt),
      value: String(yearInt)
    };
    yearOptions.push(yearObj);
  }

  return { monthOptions, yearOptions };
};

export const getColorStyles = ({ theme, color }) => {
  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: theme.transparent13,
      color: theme.background,
      boxShadow: 'none'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? theme.highlight
          : isFocused
          ? theme.highlight
          : undefined,
        ':active': {
          ...styles[':active'],
          backgroundColor: theme.textPrimary
        },
        ':hover': {
          ...styles[':hover'],
          border: 'none'
        }
      };
    },
    input: (styles) => ({ ...styles, color: theme.textMuted }),
    placeholder: (styles) => ({ ...styles, color: theme.textMuted }),
    singleValue: (styles) => ({ ...styles, color: color ? color : theme.textPrimary })
  };
};
