import React from 'react';
import {textStyle,titleStyle} from '../../PrivacyPolicy.styles'

export default function PrivacyPolicy() {
  return (
    <div>
      <strong style={titleStyle}>Privacy Policy</strong>
      <p style={textStyle}>
        Tatva Wellness Ltd. built the Tatva app as a Commercial app. This SERVICE is provided by Tatva Wellness Ltd. and
        is intended for use as is.
      </p>
      <p style={textStyle}>
        This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal
        Information if anyone decided to use our Service.
      </p>
      <p style={textStyle}>
        If you choose to use our Service, then you agree to the collection and use of information in relation to this
        policy. The Personal Information that we collect is used for providing and improving the Service. We will not
        use or share your information with anyone except as described in this Privacy Policy.
      </p>
      <p style={textStyle}>
        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are
        accessible at Tatva unless otherwise defined in this Privacy Policy.
      </p>
    </div>
  );
}
