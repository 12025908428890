import styled from 'styled-components';

export const BackButtonContainer = styled.div`
  padding-right: 0.9rem;
  padding-left: 0.62rem;
  display: flex;
  border-radius: 60px;
  border: 1px solid ${(props) => props.theme.highlight};
  flex-direction: row;
  align-items: center;
  height: 2.5rem;
  justify-content: space-between;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  color: ${(props) => props.theme.highlight};
  font-size: 1.125rem;
  margin-left: 0.62rem;
`;
