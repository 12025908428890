import styled from 'styled-components';
import { Modal, StyledContainer } from '@app/modules/common/index';
import { media } from '@app/themes/index';

//TODO: convert all pixels to rem or %
export const StyledNav = styled.div`
  padding: 1.2rem 0;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.textPrimary};
  box-shadow: 0rem 0.25rem 3.125rem 0rem rgba(155, 155, 155, 0.25);
  img {
    height: 1.875rem;
  }
  .logo {
    width: 12.5rem;
    height: 4rem;
    img {
      height: 100%;
    }
  }
  //TODO: make .link styles global-> atleast for navbar
  .link {
    cursor: pointer;
    display: flex;
    width: fit-content;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    &.disabled {
      cursor: default;
    }
    &:hover:not(.disabled) {
      color: ${(props) => props.theme.highlight};
    }
    a,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      color: inherit;
    }
  }
  .menuToggleBtn {
    margin: 0.5rem 0.2rem;
    cursor: pointer;
    ${media.greaterThan('tablet')`
      display: none;
    `};
  }
`;

export const NavContainer = styled(StyledContainer)`
  display: flex;
`;

export const InnerMenu = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  .options {
    display: flex;
    gap: 1.56rem;
    ${media.lessThan('tablet')`
      display: none;
    `}
  }
`;

export const ExtendedMenu = styled.div`
  width: 12.5rem;
  min-height: 8.375rem;
  padding-bottom: 1rem;
  flex-shrink: 0;
  position: absolute;
  top: 2.75rem;
  right: 0.87rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};
  z-index: 1;
  display: ${(props) => (props.extendNavbar ? 'block' : 'none')};
  ${media.greaterThan('tablet')`
    display: none;
  `};
`;

export const NavbarModal = styled(Modal)`
  ${media.greaterThan('tablet')`
    display: none;
  `};
`;
export const CrossWrapper = styled.div`
  margin-left: 11rem;
  margin-top: 0.5rem;
  &:hover {
    color: ${(props) => props.theme.highlight};
    cursor: pointer;
  }
`;
export const Menu = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-size: 1rem;
  font-weight: 400;
  .subMenu {
  }
`;
export const Divider = styled.div`
  width: 9.87519rem;
  height: 0.0625rem;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.textMuted};
  margin-top: 0.79rem;
  margin-bottom: 0.79rem;
`;
export const List = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
