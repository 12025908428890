import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

export const StyledPhoneInput = styled(PhoneInput)`
  font-size: 1.125rem;
  .form-control {
    width: 100%;
    height: 3rem;
    padding-top: 0.62rem;
    padding-bottom: 0.62rem;
    padding-right: 0.81rem;
    font-size: 1.125rem;
  }
  .flag-dropdown {
    height: 3rem;
    top: auto;
    .selected-flag {
      padding-left: 0.87rem;
    }
    .country-list {
      width: fit-content;
      .search {
        display: flex;
        padding: 1rem;
        align-items: center;
        .search-emoji {
          font-size: 1.5rem;
        }
        .search-box {
          margin-left: 0;
          font-size: 1.125rem;
        }
      }
    }
  }
`;
