import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { media } from '@app/themes/index';

export const QrContainer = styled.div`
  width: 48.375rem;
  color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding-top: 4.813rem;
  ${media.lessThan('tablet')`
      display: none;
    `}
  .title {
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.04375rem;
  }
  .title_content {
    margin-top: 0.75rem;
    width: 18.5rem;
    font-size: 1.5625rem;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.03125rem;
  }
  .qr_code {
    margin-top: 4.57rem;
    width: 18.58925rem;
    height: 18.58925rem;
    flex-shrink: 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.white};
  }
  .instructions {
    display: flex;
    margin-top: 4.41rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--alert-background, #fef7ea);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    row-gap: 0.5rem;
  }
`;

export const StyledQRCode = styled(QRCode)`
  height: 15.3rem;
  width: 15.3rem;
`;
