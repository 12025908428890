import styled from 'styled-components';
import { Oval } from 'react-loading-icons';

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
export const StyledLoading = styled(Oval)`
  margin: auto;
  transform: scale(calc(100% * ${(props) => props.scale}));
  stroke: ${(props) => props.theme.highlight};
`;
