import styled from 'styled-components';
import { StyledContainer } from '../common/index';
import { media } from '@app/themes/index';

export const StyledLoadingPage = styled(StyledContainer)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;
export const LoadingPageContainer = styled.div`
  width: 86.25rem;
  height: 51.625rem;
  display: flex;
  border-radius: 0.625rem;
  background: ${(props) => props.theme.background};
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 9px 24px 0px rgba(0, 0, 0, 0.18);
  ${media.lessThan('tablet')`
  transform: scale(0.8, 0.8);
    `}
`;
