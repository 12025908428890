import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  border-radius: 3.75rem;
  background-color: ${(props) => props.theme.highlight};
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0.25rem 1.5rem;
  cursor: pointer;
  color: ${(props) => props.theme.white};
  font-size: 1rem;
  font-weight: 500;
`;
