import { PrimaryButton } from '@app/modules/common/index';
import styled from 'styled-components';
import { media } from '@app/themes/index';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';

export const SavedCard = styled.div`
  padding-right: 3.313rem;
  display: flex;
  flex-direction: column;
`;
export const OuterCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.7rem;
  width: 31.25rem;
  height: 7.32713rem;
  align-items: center;
  padding-bottom: 0.976rem;
  padding-top: 0.976rem;
  border-radius: 0.625rem;
  ${media.lessThan('tablet')`
  width: 18.125rem;
  border-radius: 1rem;
  `}
  background-color: ${(props) => props.theme.textPrimary};
`;

export const CardDetail = styled.div`
  color: ${(props) => props.theme.background};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
`;

export const RadioContainer = styled.div`
  display: flex;
  margin-right: 0.938rem;
  align-self: center;
  margin-left: 1.125rem;
`;

export const Text = styled.div`
  margin: 0 0.1rem;
`;
export const CardNameText = styled.div`
  margin: 0.25rem 0.1rem;
`;
export const ExpiryText = styled.div`
  margin: 0.2rem 0.1rem;
`;

export const ChangePaymentWrapper = styled.div`
  /* margin-top: 10rem; */
  display: flex;
  flex-direction: row;
  margin-top: 2.361rem;
  color: ${(props) => props.theme.highlight};
  width: 14rem;
  /* H3 Bold */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  ${media.lessThan('tablet')`
  margin-top: 1.1rem;
  width: 15rem;
  `}
`;

export const Arrow = styled.span`
  border: solid ${(props) => props.theme.highlight};
  border-width: 0 0.188rem 0.188rem 0;
  margin-top: ${(props) => (props.expandCard ? '0.75rem' : '0.4rem')};
  margin-left: 0.7rem;
  width: 0.6rem;
  height: 0.6rem;
  transform: rotate(${(props) => (props.expandCard ? 225 : 45)}deg);
  -webkit-transform: rotate(${(props) => (props.expandCard ? 225 : 45)}deg);
`;

export const AddPaymentWrapper = styled.div`
  /* align-self: flex-end; */
  margin-top: 1.188rem;
  display: flex;
  flex-direction: row;
  width: 11.5rem;
  cursor: pointer;
  font-weight: 400;
  color: ${(props) => props.theme.highlight};
  &:hover {
    color: ${(props) => props.theme.white};
  }
`;
export const AddPayment = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-left: 0.25rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 0.2rem;
`;
export const PayButton = styled(PrimaryButton)`
  width: 7.25rem;
  height: 2.5rem;
  padding: 0.875rem 0.625rem;
  ${media.lessThan('tablet')`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  `}
`;

export const PlusArrow = styled(AiOutlinePlus)`
  margin-top: 0.2rem;
  flex-shrink: 0;
`;
