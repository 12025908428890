import styled from 'styled-components';

export const TextStyle = styled.div`
  color: white;
  margin-right: 1.25rem;
  margin-left: 1.875rem;
  margin-bottom: 0.625rem;
  margin-top: 5rem;
  align-self: center;
  font-size: 3rem;
  white-space: nowrap;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #212529;
  //overflow: hidden;
  white-space: nowrap;
  /* @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 767px;
    height: 100vh;
    margin: 0 auto;
    background-color: #212529;
    background-size: cover;
  } */
`;

export const HeaderContainer = styled.div`
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  align-self: flex-start;
  margin-left: 5rem;
  font-size: 1.25rem;
  @media (max-width: 620px) {
    margin-left: 2.9rem;
  }
  @media (max-width: 410px) {
    margin-left: 2.1rem;
  }
`;
