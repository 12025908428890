import styled from 'styled-components';
import { media } from '@app/themes/index';
import { PrimaryButton } from '@app/modules/common/index';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

export const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 36.5rem;
  width: 32.0625rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  padding-left: 2.69rem;
  padding-right: 2.69rem;
  overflow: auto;
  background-color: ${(props) => props.theme.black};
  box-shadow: 0.25rem 0.25rem 1.25rem 0.063rem rgba(68, 68, 68, 0.25);
  color: ${(props) => props.theme.white};
  font-size: 1.375rem;
  padding-bottom: 2rem;
  ::-webkit-scrollbar-track {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  .highlight {
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    color: ${(props) => props.theme.highlight};
  }
`;

export const Divider = styled.div`
  width: 17.75rem;
  height: 0.063rem;
  background-color: ${(props) => props.theme.white};
  margin-top: 2.188rem;
  /* margin-bottom: 1.25rem; */
`;

export const PopUpHeader = styled.div`
  display: inline;
  position: fixed;
  width: 28rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${(props) => props.theme.black};
`;

export const CrossWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  flex-shrink: 0;
  color: ${(props) => props.theme.highlight};
  &:hover {
    color: ${(props) => props.theme.white};
    cursor: pointer;
  }
`;
export const CardDiv = styled.div`
  margin-top: 1.25rem;
  width: 16rem;
  /* width: 25.125rem; */
`;
export const TrashBin = styled(BsFillTrashFill)`
  color: ${(props) => props.theme.highlight};
  cursor: pointer;
  margin-top: 1.7rem;
`;
export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
`;

export const RadioContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  cursor: pointer;
`;

export const RetryButton = styled(PrimaryButton)`
  margin-top: 1.5rem;
  font-size: 1.1rem;
  align-self: flex-end;
  cursor: pointer;
`;

export const CardBody = styled.div`
  margin-top: 5.81rem;
`;

export const Cross = styled(AiOutlineClose)`
  width: 80%;
  height: 80%;
`;

export const LoaderContainer = styled.div`
  width: 32.0625rem;
  height: 5rem;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.black};
  box-shadow: 0.25rem 0.25rem 1.25rem 0.063rem rgba(68, 68, 68, 0.25);
  ${media.lessThan('tablet')`
  height: 3rem;
    `}
`;
