import styled from 'styled-components';
import { media } from '@app/themes/index';

export const InputText = styled.input`
  padding: 0.62rem 0.81rem 0.62rem 0.81rem;
  width: ${(props) => (props.width ? props.width : '20rem')};
  height: ${(props) => (props.height ? props.height : '3rem')};
  color: ${(props) => props.theme.textPrimary};
  background-color: ${(props) => props.theme.transparent13};
  font-size: 1.125rem;
  display: flex;
  border-radius: 0.625rem;
  justify-content: flex-start;
  margin-top: 0.38rem;
  border: 0.0625rem solid ${(props) => props.theme.textPrimary};
  ${media.lessThan('tablet')`
  width: ${(props) => (props.mediaWidth ? props.mediaWidth : '19rem')};
  `}
`;
