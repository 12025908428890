import React from 'react';
import { getAuth } from 'firebase/auth';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { CrossWrapper, Divider, ExtendedMenu, List, Menu } from './VerticalNavbar.styles';
import Routes from '../Routes/Routes';
import { Translation } from '@app/modules/common/index';
import NavbarLink from '../NavbarLink/NavbarLink';

function VerticalNavbar({ isOpen, setIsOpen, logOut }) {
  const currentUser = getAuth().currentUser;
  return (
    <ExtendedMenu extendNavbar={isOpen}>
      <CrossWrapper onClick={() => setIsOpen((prevState) => !prevState)}>
        <AiOutlineClose />
      </CrossWrapper>
      <Menu>
        {/* <List className="link disabled">{currentUser?.displayName}</List>
        <Divider /> */}
        <List className="link">
          <NavbarLink link={'dashboard'}>
            <Translation id="HOME" />
          </NavbarLink>
        </List>
        <Divider />
        <List className="link">
          <NavbarLink link={'subscription'}>
            <Translation id="SUBSCRIPTION" />
          </NavbarLink>
        </List>
        <Divider />
        <ul className="subMenu">
          <List className="link" onClick={() => logOut()}>
            <Translation id="LOG_OUT" />
          </List>
          {/* <Divider />
          <List className="link">
            <Link to={'/delete'} target="_blank" rel="noopener noreferrer">
              <Translation id="DELETE_ACCOUNT" />
            </Link>
          </List> */}
        </ul>
      </Menu>
    </ExtendedMenu>
  );
}

export default VerticalNavbar;
