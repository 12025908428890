/* eslint-disable no-empty */
/* eslint-disable no-console */
import momentTz from 'moment-timezone';
import { recordErrorInCrashalytics } from 'tatvarnsdk/Web';

Date.prototype.toShortFormat = function () {
  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day} ${monthName} ${year}`;
};

export const getDateFromTimestampObject = (timestamp) => {
  try {
    return new Date(timestamp._seconds * 1000);
  } catch (e) {
    recordErrorInCrashalytics('function utils getDateFromTimestampObject', e);
  }
};

export const changeTimeZone = (day, time, timeZone) => {
  const convertedData = momentTz.tz(`${day} ${time}`, 'dddd HH:mm', timeZone);
  return convertedData.local().format('HH:mm');
};
