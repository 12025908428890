import { PrimaryButton, StyledContainer } from '@app/modules/common/index';
import styled from 'styled-components';
import { media } from '@app/themes/index';

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  /* ${media.lessThan('tablet')`
  margin-left: 2.88rem;
  `} */
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 9.87rem;
  margin-right: 9.87rem;
  margin-top: 8rem;
  ${media.lessThan('tablet')`
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  `}
  .error404 {
    margin-top: auto;
    margin-bottom: auto;
    .row1 {
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      ${media.lessThan('tablet')`
      display: none;
  `}
    }
    .row1_oops {
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 3.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      ${media.greaterThan('tablet')`
      display: none;
  `}
    }
    .row2 {
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      ${media.lessThan('tablet')`
      font-size: 1.5rem;
      font-weight: 300;
  `}
    }
  }
  .sticker {
    margin-top: auto;
    margin-bottom: auto;
    width: 26.94163rem;
    height: 31.25rem;
    flex-shrink: 0;
    ${media.lessThan('tablet')`
    width: 14.92825rem;
    height: 17.3155rem;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    `}
  }
  .oops {
    margin-top: auto;
    margin-bottom: auto;
    .row1 {
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      ${media.lessThan('tablet')`
      display: none;
  `}
    }
    .row1_error_404 {
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 3.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      ${media.greaterThan('tablet')`
      display: none;
  `}
    }
  }
  ${media.lessThan('tablet')`
  flex-direction: column;
  `}
`;
export const HeaderWrapper = styled.div`
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  align-self: flex-start;
  font-size: 1.25rem;
  width: 12.5rem;
  height: 4rem;
  margin-left: 5rem;
  ${media.lessThan('tablet')`
  width: 5.80644rem;
  height: 1.875rem;
  flex-shrink: 0;
  `}
`;

export const ScheduleButtons = styled.div`
  display: flex;
  flex-direction: column;
  height: 8.25rem;
  width: 14.75569rem;
  margin-top: 7rem;
  display: flex;
  flex-shrink: 0;
  ${media.lessThan('tablet')`
  height: 6rem;
  width: 20rem;
  /* margin-top: 5rem; */
  align-self: flex-end;
  margin-left: auto;
  margin-right: auto;
  `}
`;
export const HomeButton = styled(PrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 0.87rem;
  cursor: pointer;
`;
export const ReportButton = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.highlight};
  border: 0.063rem solid ${(props) => props.theme.highlight};
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.87rem;
  cursor: pointer;
`;
