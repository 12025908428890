import styled from 'styled-components';
import { media } from '@app/themes/index';
import { PrimaryButton, AdornmentInput, MobileInput } from '@modules/common';
import { MdSms } from 'react-icons/md';

export const FormContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  padding-top: 3.62rem;
  width: 37.875rem;
  flex-shrink: 0;
  ${media.lessThan('tablet')`
    padding-left: 4rem;
    padding-right: 9rem;
    padding-top: 3.62rem;
  `}
  .title {
    color: ${(props) => props.theme.black};
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00625rem;
    margin-top: 10.31rem;
    margin-bottom: 1.5rem;
  }
  .resend {
    color: var(--Neutral-100, #bdc0c1);
    text-align: center;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.00625rem;
    margin-top: 2.06rem;
  }
  .resend.active {
    cursor: pointer;
    color: ${(props) => props.theme.highlight};
  }
  .signup_text {
    margin-top: 1.62rem;
    text-align: center;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.00625rem;
    .grey_text {
      color: ${(props) => props.theme.greyText};
    }
    .highlight_text {
      cursor: pointer;
      color: ${(props) => props.theme.highlight};
    }
  }
`;
export const Captcha = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 25.1875rem;
  ${media.lessThan('tablet')`
  margin-top: 1.5rem;
    `}
  #recaptcha-container {
    transform: scale(0.68, 0.68);
    /* width: 100%;
    & > div > div {
      width: 100%;
    } */
    ${media.lessThan('tablet')`
      transform: scale(1, 1);
    `}
  }
`;
export const StyledLogoImg = styled.img`
  width: 12.5rem;
  height: 4rem;
  flex-shrink: 0;
`;
export const StyledInput = styled(AdornmentInput)`
  /* width: 25.125rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-width: 0.1rem;
  border-color: ${(props) => props.theme.inputBorder};
  border-style: solid;
  border-radius: 0.5rem; */
  margin-top: 1rem;
  .adornment {
    color: ${(props) => props.theme.greyText};
  }
`;
export const SMSIcon = styled(MdSms)`
  width: 1.25rem;
  height: 1.25rem;
`;
export const StyledMobileInput = styled(MobileInput)`
  &:focus-within .form-control {
    border: 0.0625rem solid ${(props) => props.theme.highlight};
  }
  &:focus-within .flag-dropdown {
    border: 0.0625rem solid ${(props) => props.theme.highlight};
  }
  .flag-dropdown .selected-flag {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-tel-input .form-control {
    padding-left: 2.5rem;
  }
`;
export const OtpButton = styled(PrimaryButton)`
  width: 25.1875rem;
  height: 3.125rem;
  flex-shrink: 0;
  padding: 0.75rem 0.94rem;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  background-color: ${(props) => (props.disabled ? 'grey' : props.theme.highlight)};
`;
