import styled from 'styled-components';

export const StyledOptions = styled.ul`
  list-style-type: none;
  .subMenuContainer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    &:hover .subMenu {
      display: flex;
      flex-direction: column;
    }
  }
  .subMenu {
    position: absolute;
    z-index: 100;
    top: 3rem;
    right: 0;
    display: none;
    font-size: 1rem;
    font-weight: 400;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.9rem;
    padding-bottom: 0.8rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background-color: ${(props) => props.theme.background};
    .link {
      height: 100%;
      width: 100%;
      padding: 0.8rem;
    }
    .link:not(:first-child) {
      border-top: 0.125rem solid ${(props) => props.theme.textPrimary};
    }
  }
`;
