import React, { useState, useEffect } from 'react';
import logoDark from '@app/images/logos/tatva-navbar-logo-dark.svg';
import { browserHistory } from 'react-router';
import {
  StyledNav,
  NavContainer,
  InnerMenu,
  ExtendedMenu,
  NavbarModal,
  CrossWrapper,
  Menu,
  Divider,
  List
} from './Navbar.styles';
import hamBurgerIcon from '@app/images/icons/ham-menu.svg';
// import crossIcon from '@app/images/icons/cross-menu.svg';
import { AiOutlineClose } from 'react-icons/ai';
import Routes from './components/Routes/Routes';
import Options from './components/Options/Options';
import { getAuth } from 'firebase/auth';
import { Link, useHistory } from 'react-router-dom';
import { Translation } from '../common/index';
import { logToCrashalytics } from 'tatvarnsdk/Web';
import VerticalNavbar from './components/VerticalNavbar/VerticalNavbar';

function Navbar() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const logOut = () => {
    getAuth().signOut();
    browserHistory.replace('/');
  };
  const onHomeClicked = () => {
    history.push('/dashboard');
  };
  useEffect(() => {
    logToCrashalytics('Navbar Container');
  }, []);
  //TODO: scale the size with size increase at 4k
  return (
    <StyledNav>
      <NavContainer>
        <div className="logo link">
          <img src={logoDark} alt="Tatva" onClick={onHomeClicked} />
        </div>
        <InnerMenu>
          <Routes />
          <Options />
          <div className="menuToggleBtn" onClick={() => setIsOpen((prevState) => !prevState)}>
            {!isOpen && <img src={hamBurgerIcon} alt="ham-icon-open" />}
          </div>
        </InnerMenu>
      </NavContainer>
      <NavbarModal isOpen={isOpen} setIsOpen={isOpen}>
        <VerticalNavbar isOpen={isOpen} setIsOpen={setIsOpen} logOut={logOut} />
      </NavbarModal>
    </StyledNav>
  );
}

export default Navbar;
