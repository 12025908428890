import React from 'react';
import { IllustrationWrapper, IllustrationImage } from './Illustrations.styles';
// import { paymentSuccessSticker } from '@app/themes/images';

export const Illustrations = ({ image }) => {
  return (
    <IllustrationWrapper>
      <IllustrationImage src={image} alt="toast-close-icon" />
    </IllustrationWrapper>
  );
};
