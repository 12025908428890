import React from 'react';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Profile from '../Profile/Profile';
import { StyledOptions } from './Options.styles';
import { Translation } from '@app/modules/common/index';
import defaultprofile from './defaultprofile.svg';
function Options() {
  const currentUser = getAuth().currentUser;

  const logOut = () => {
    getAuth().signOut();
  };

  return (
    <StyledOptions className="options">
      {/* <li className="link">
        <img src={searchIcon} alt="icon-search" />
      </li>
      <li className="link">
        <img src={notificationBellIcon} alt="icon-notification" />
      </li> */}
      <li className="subMenuContainer">
        <Profile imgUrl={`${currentUser?.photoURL || defaultprofile}`} height="100%" width="100%" />
        <ul className="subMenu">
          <li className="link disabled">{currentUser?.displayName}</li>
          <li className="link" onClick={() => logOut()}>
            <Translation id="LOG_OUT" />
          </li>
          {/* <li className="link">
            <Link to={'/delete'} target="_blank" rel="noopener noreferrer">
              <Translation id="DELETE_ACCOUNT" />
            </Link>
          </li> */}
        </ul>
      </li>
    </StyledOptions>
  );
}

export default Options;
