const valid = require('card-validator');

export const initialFormData = {
  name: '',
  cardNumber: '',
  expiryDate: '',
  expiryYear: '',
  cvv: ''
};

export const initialErrors = {
  name: false,
  cardNumber: false,
  expiryDate: false,
  expiryYear: false,
  cvv: false
};

export const initialEMessage = {
  name: '',
  cardNumber: '',
  expiryDate: '',
  expiryYear: '',
  cvv: ''
};

export const handleInputChange = ({ name, value, newCard, setNewCard, setErrors, setEMessage }) => {
  setNewCard({
    ...newCard,
    [name]: value
  });
  switch (name) {
    case 'name':
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          name: true
        }));
        setEMessage((prev) => ({
          ...prev,
          name: '*This field cannot be empty'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'cardNumber':
      const cardNo_without_space = value.replace(/\s/g, '');
      let numberValidation = valid.number(cardNo_without_space);
      if (cardNo_without_space === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else if (!numberValidation.isValid) {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*Card number invalid'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'cvv':
      let maxLength = valid.number(newCard.cardNumber).card?.code.size;
      let cvvValidation = valid.cvv(value.trim(), maxLength);
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else if (!cvvValidation.isValid) {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*CVV invalid'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
  }
};

export const isFormValid = ({ errors }) => {
  return Object.values(errors).every((error) => error === false);
};

export const isFormEmpty = ({ newCard }) => {
  return Object.values(newCard).every((newCard) => (newCard === null ? false : newCard.length !== 0));
};

export const checkDate = ({ year, month }) => {
  if (year !== '' && month !== '') {
    var month_val = Number(month);
    var year_val = Number(year);
    const d = new Date();
    let cur_year = d.getFullYear();
    let cur_month = d.getMonth();
    if (year_val === cur_year && month_val < cur_month) {
      return true;
    }
  }
  return false;
};
