import React from 'react';
import { NavLink } from 'react-router-dom';
import { routeConfig } from '@app/routeConfig';
import { OuterNavbarLink, StyledNavbarLink } from './NavbarLink.styles';

function NavbarLink({ link, children }) {
  return (
    <OuterNavbarLink>
      <StyledNavbarLink to={routeConfig[link]['route']}>{children}</StyledNavbarLink>
    </OuterNavbarLink>
  );
}

export default NavbarLink;
