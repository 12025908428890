import React from 'react';
import { CrossWrapper, Description, FailedButton, PopUpCard, PopUpHeader, Title } from './AlertPopUp.styles';
import { Translation } from '@app/modules/common/index';
export default function AlertPopUp({ title, description1, description2, buttonText, buttonAction, setIsOpen }) {
  return (
    <PopUpCard>
      <PopUpHeader>
        <Title>
          <Translation text={title} />
        </Title>
        <CrossWrapper onClick={() => setIsOpen(false)} />
      </PopUpHeader>
      <Description>
        <Translation text={description1} />
      </Description>
      <Description>
        <Translation text={description2} />
      </Description>
      {buttonAction && buttonText && <FailedButton onClick={buttonAction} text={buttonText} />}
    </PopUpCard>
  );
}
