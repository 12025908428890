import React from 'react';
import logoDark from '@app/images/logos/tatva-navbar-logo-dark.svg';
import { HeaderWrapper } from './HeaderWithLogo.styles';
import { useHistory, Redirect } from 'react-router-dom';

export const HeaderWithLogo = () => {
  const history = useHistory();
  const onHomeClicked = () => {
    history.push('/dashboard');
  };
  return (
    <HeaderWrapper>
      <img src={logoDark} alt="Tatva Web-App" height="100%" onClick={onHomeClicked} />
    </HeaderWrapper>
  );
};
