import styled from 'styled-components';

export const DescriptionWrapper = styled.ul`
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  font-size: 1.25rem;
  li::marker {
    /* content: '•'; */
    color: ${(props) => props.theme.highlight};
    font-size: 1.8rem;
    /* width: 0.625rem;
    height: 0.625rem; */
  }
  li {
    /* padding-left: 0.3rem; */
    vertical-align: center;
  }
  li span {
    position: relative;
    left: -0.3rem;
  }
`;
