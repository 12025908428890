import { generateMedia } from 'styled-media-query';

export const screenBreakPoints = {
  MOBILE: 319,
  TABLET: 767,
  DESKTOP: 1023,
  LARGEDESKTOP: 1439
};

const media = generateMedia({
  mobile: `${screenBreakPoints.MOBILE / 16}em`,
  tablet: `${screenBreakPoints.TABLET / 16}em`,
  desktop: `${screenBreakPoints.DESKTOP / 16}em`,
  largeDesktop: `${screenBreakPoints.LARGEDESKTOP / 16}em`
});

export default media;
