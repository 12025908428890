import styled from 'styled-components';

export const OuterCircle = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.highlight};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border-width: 2rem;
  stroke-width: 0.2rem;
  justify-content: center;
  align-items: center;
`;

export const InnerCircle = styled.div`
  display: flex;
  border-width: 2rem;
  background-color: ${(props) => props.backgroundColor};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const Highlight = styled.div`
  background-color: ${(props) => props.theme.highlight};
  width: 0.5rem;
  height: 0.5rem;
  align-self: center;
  border-radius: 50%;
`;
