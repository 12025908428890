import styled from 'styled-components';
import { StyledContainer } from '../common/index';
import { media } from '@app/themes/index';

export const StyledSubscription = styled.div`
  flex-grow: 1;
  display: flex;
`;
export const SubscriptionContainer = styled(StyledContainer)`
  margin-top: 5.13rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  // TODO: according to design
  /* width: 80%; */
  ${media.lessThan('tablet')`
      width: 100vw;
      margin-top: 3rem;
  `} .heading {
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 1.25rem;
    color: ${(props) => props.theme.textPrimary};
    ${media.lessThan('tablet')`
      text-align: center;
      margin-bottom: 0.5rem;
    `}
  }
  .details {
    display: flex;
    justify-content: space-between;
    ${media.lessThan('tablet')`
      display:${(props) => (props.userCardDetailsLength === 0 || props.userSubscription === null ? 'none' : 'flex')};
      flex-direction: column;
      align-items: center;
      margin-top: 1.5rem;
    `}
    .currentSubscription {
      .userPlan {
        width: 31rem;
        height: 17.25rem;
        margin: 1.5rem 0;
        font-size: 1.3rem;
        ${media.lessThan('tablet')`
        width: 25rem;
        height: 13.55rem;
        font-size: 1rem;
        `}
      }
      ${media.lessThan('tablet')`
        margin-bottom: 10rem;
      `}
    }
    .paymentMethod {
      ${media.lessThan('tablet')`
      margin-top: 10rem;
      `}
    }
  }
  .upgradeSubscription {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    ${media.lessThan('tablet')`
      padding: 0;
    `}
    .illustration {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      img {
        width: 31.10369rem;
        height: 24.82469rem;
      }
    }
    .heading {
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
      text-align: center;
      color: ${(props) => props.theme.textPrimary};
      ${media.lessThan('tablet')`
      text-align: center;
    `}
    }
    .sub-heading {
      margin-top: 1.06rem;
      text-align: center;
      font-style: normal;
      font-size: 1.4375rem;
      line-height: 1.25rem;
      color: ${(props) => props.theme.white};
    }
    .upgradeSubscriptionBody {
      background-color: ${(props) => props.theme.black};
      padding: 2rem 0;
      margin: 1rem 0;
      border-radius: 1.25rem;
      box-shadow: 4px 4px 20px 1px rgba(68, 68, 68, 0.25);
      ${media.lessThan('tablet')`
        background-color: inherit;
        box-shadow: none;
      `}
      .plans {
        display: flex;
        gap: 2rem;
        margin: 2rem auto;
        font-size: 1rem;
        overflow-x: auto;
        padding-bottom: 1rem;
        ${media.lessThan('tablet')`
          width: 100%;
          overflow-x: scroll;
          padding: 0 3rem;
        `}
      }
      .coupon {
        width: 50%;
        margin: auto;
        background-color: ${(props) => props.theme.black};
        color: ${(props) => props.theme.white};
        font-size: 1.125rem;
        font-weight: 700;
        ${media.lessThan('tablet')`
          width: 95%;
        `}
        .couponInput {
          position: relative;
          margin: 1rem 0;
          input {
            width: 100%;
            height: 3.5rem;
            padding: 0 2rem;
            background: ${(props) => props.theme.black};
            border: 1px solid ${(props) => props.theme.white};
            color: ${(props) => props.theme.white};
            font-size: 0.9375rem;
            font-weight: 400;
          }
          .btn {
            position: absolute;
            top: 0.52rem;
            right: 0.3rem;
          }
        }
        .error {
          color: ${(props) => props.theme.error};
          font-size: 1rem;
        }
      }
    }
  }
`;
export const Divider = styled.div`
  width: 95%;
  margin-top: 1.3rem;
  margin-bottom: 0.86rem;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  height: 0.1rem;
  align-self: center;
  background-color: ${(props) => props.theme.darkGrey};
  flex-shrink: 0;
  stroke-width: 1px;
  ${media.greaterThan('tablet')`
  display: none;
  `}
`;
