import { createSelector } from 'reselect';
import get from 'lodash/get';
import { initialState } from './reducer';

export const selectSubscriptionDomain = (state) => state.subscription || initialState;

export const selectSubscription = () => createSelector(selectSubscriptionDomain, (substate) => substate);

export const selectError = () => createSelector(selectSubscriptionDomain, (substate) => get(substate, 'error'));

export const selectLoading = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'loading', false));

export const selectCouponLoading = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'couponLoading', false));

export const selectCoupon = () => createSelector(selectSubscriptionDomain, (substate) => get(substate, 'coupon', null));

export const selectCouponError = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'couponError', false));

export const selectPlans = () => createSelector(selectSubscriptionDomain, (substate) => get(substate, 'planData', []));

export const selectDisciplines = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'disciplineList', []));

export const selectUserProfile = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'userProfile', null));

export const selectUserSubscription = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'userSubscription', null));

export const selectUserPlanDescription = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'userPlanDescription', null));

export const selectUserCardDetails = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'userCardDetails', []));

export const selectUserCardDetailsLoading = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'userCardDetailsLoading', false));

export const selectSecretkey = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'secretkey', null));

export const selectPaymentMethodId = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'paymentMethodId', null));

export const selectPaymentStatus = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'paymentStatus', null));

export const selectRetryPaymentInitiated = () =>
  createSelector(selectSubscriptionDomain, (substate) => get(substate, 'retryPaymentInitiated', false));

export default selectSubscription;
