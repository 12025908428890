import styled from 'styled-components';
import { StyledContainer } from '../common/index';
import { media } from '@app/themes/index';

export const DownloadContainer = styled(StyledContainer)`
  margin-top: 5.13rem;
  flex-grow: 1;
  display: flex;
  ${media.lessThan('tablet')`
      width: 100vw;
  `}
  .container {
    display: flex;
    flex-direction: column;
    .title {
      justify-content: center;
      flex-shrink: 0;
      color: ${(props) => props.theme.white};
      font-size: 3.9375rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .content {
      display: flex;
      flex-direction: row;
      margin-top: 3rem;
      .imageBox {
        width: 12.11425rem;
        height: 3.81463rem;
        flex-shrink: 0;
        margin-right: 3rem;
        cursor: pointer;
      }
    }
  }
`;
