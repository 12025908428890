import React, { useState } from 'react';
import {
  DescriptionCardCover,
  FailButton,
  PaymentFailed,
  StyledSavedCard,
  SubscriptionCardCover,
  Text
} from './SubscriptionCard.styles';
import {
  Modal,
  SubscriptionCardWrapper,
  Translation,
  getDescriptionFromSubscribedPlan,
  parseSavedCards
} from '@app/modules/common/index';
import Description from '../Description/Description';
import CardPopUp from '../CardPopUp/CardPopUp';

export default function SubscriptionCard({
  plan,
  type,
  userSubscription,
  savedCards,
  isLoading,
  changeDefaultCard,
  isOpen,
  setIsOpen,
  retryCard,
  retryPaymentInitiated,
  userPlanDescription
}) {
  const { defaultCard, nonDefaultCards } = parseSavedCards(savedCards);

  return (
    <StyledSavedCard>
      <SubscriptionCardCover
        fail={userSubscription && userSubscription.status === 'paymentFailed'}
        retry={userSubscription && userSubscription.status === 'retrying'}
      >
        <div className="card">
          <SubscriptionCardWrapper plan={plan} type={type} />
        </div>
        {userSubscription && userSubscription.status === 'paymentFailed' && (
          <>
            <PaymentFailed>
              <Text>
                <Translation id="PAYMENT_FAILED" />
              </Text>
            </PaymentFailed>
            <FailButton text={'FIX NOW'} onClick={() => setIsOpen(true)} />
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
              <CardPopUp
                defaultCard={defaultCard}
                nonDefaultCards={nonDefaultCards}
                isLoading={isLoading}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                changeDefaultCard={changeDefaultCard}
                header_id={'select_retry'}
                retryCard={retryCard}
                retryPaymentInitiated={retryPaymentInitiated}
              />
            </Modal>
          </>
        )}
        {userSubscription && userSubscription.paymentStatus === 'retrying' && (
          <>
            <PaymentFailed>
              <Text>
                <Translation id="RETRYING_PAYMENT" />
              </Text>
            </PaymentFailed>
          </>
        )}
      </SubscriptionCardCover>
      <DescriptionCardCover>
        <Description description={userPlanDescription} />
      </DescriptionCardCover>
    </StyledSavedCard>
  );
}
