import styled from 'styled-components';

export const textStyle = {
  color: 'white',
  textAlign: 'justify',
  fontSize: 12,
  marginRight: 20
};

export const titleStyle = {
  color: 'white',
  textAlign: 'left',
  fontSize: 18,
  marginTop: 10
};

export const MainContainer = styled.div`
    && {
      display: flex;
      flex-direction: column;
      //max-width: ${(props) => props.maxwidth}px;
      width: 100%;
      min-height: 100vh;
      margin: 0 auto;
      background-color: #212529;
      //overflow: hidden;
    }
  `;

export const logoStyle = {
  paddingTop: 20,
  paddingBottom: 20,
  alignSelf: 'center'
};

export const bodyStyle = {
  paddingLeft: 20,
  paddingRignt: 20
};
