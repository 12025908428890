import React, { memo, useEffect, useState } from 'react';
import { Loading } from '../common/index';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { selectCategoriesWithClasses, selectUserAlert, selectLoading, selectZoomUrl } from './selectors';
import { dashboardCreators } from './reducer';
import { connect } from 'react-redux';
import dashboardSaga from './saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';

import ClassSlider from './components/ClassSlider/ClassSlider';
import { DashboardContainer, FailedModal, StyledDashboard } from './Dashboard.styles';
import AlertPopUp from './components/AlertPopUp/AlertPopUp';
import { routeConfig } from '@app/routeConfig';
import { logToCrashalytics } from 'tatvarnsdk/Web';
function Dashboard(props) {
  const {
    dispatchGetHomeScreenDetails,
    dispatchDeleteDocWithToken,
    dispatchRegisterZoom,
    categoriesWithClasses,
    userAlert,
    loading,
    userDocId, //todo: otp view
    setuserDocId
  } = props;
  const history = useHistory();
  useEffect(() => {
    logToCrashalytics('Dashboard');
    dispatchGetHomeScreenDetails();
    if (userDocId) {
      dispatchDeleteDocWithToken(userDocId);
      setuserDocId(null);
    }
  }, []);
  const onClassClicked = async (session) => {
    dispatchRegisterZoom(session);
  };
  const [isOpen, setIsOpen] = useState(userAlert ? true : false);
  const buttonAction = () => {
    history.push({
      pathname: routeConfig.subscription.route,
      state: { isOpen: isOpen, userAlert }
    });
  };

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
    };
    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, null, window.location.href);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <StyledDashboard>
      <DashboardContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            {(categoriesWithClasses || []).map((category) => {
              if (category.classes.length > 0) {
                return <ClassSlider category={category} key={category.name} onClicked={onClassClicked} />;
              }
            })}
          </>
        )}
        {/* {userAlert.body.paymentFailed ? setFailPayment(true) : setFailPayment(false)} */}
        {userAlert && (
          <FailedModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <AlertPopUp
              title={userAlert.title}
              description1={userAlert.description1}
              description2={userAlert.description2}
              buttonAction={buttonAction}
              buttonText={userAlert.actionButtonText}
              setIsOpen={setIsOpen}
            />
          </FailedModal>
        )}
      </DashboardContainer>
    </StyledDashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  categoriesWithClasses: selectCategoriesWithClasses(),
  userAlert: selectUserAlert(),
  loading: selectLoading(),
  zoomUrl: selectZoomUrl()
});

export function mapDispatchToProps(dispatch) {
  const { requestHomeScreenData, registerZoom, deleteDocToken } = dashboardCreators;
  return {
    dispatchGetHomeScreenDetails: () => dispatch(requestHomeScreenData()),
    dispatchRegisterZoom: (session) => dispatch(registerZoom(session)),
    dispatchDeleteDocWithToken: (docId) => dispatch(deleteDocToken(docId))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect, memo, injectSaga({ key: 'dashboard', saga: dashboardSaga }))(Dashboard);
