import React, { useEffect } from 'react';
import HeaderLogo from '@app/images/logos/tatva-navbar-logo-dark.svg';
import { logoStyle, bodyStyle, MainContainer } from './PrivacyPolicy.styles';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import InformationCollection from './components/InformationCollection/InformationCollection';
import LogData from './components/LogData/LogData';
import Cookies from './components/Cookies/Cookies';
import ServiceProviders from './components/ServiceProviders/Security/ServiceProviders';
import Security from './components/Security/Security';
import Others from './components/Others/Others';
import { logToCrashalytics } from 'tatvarnsdk/Web';

export default () => {
  useEffect(() => {
    logToCrashalytics('Privacy Policy Container');
  }, []);
  return (
    <MainContainer>
      <div style={logoStyle}>
        <img src={HeaderLogo} alt={'tatva-app'} />
      </div>
      <div style={bodyStyle}>
        <PrivacyPolicy />
        <InformationCollection />
        <LogData />
        <Cookies />
        <ServiceProviders />
        <Security />
        <Others />
      </div>
    </MainContainer>
  );
};
