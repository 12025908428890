import produce from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: paymentTypes, Creators: paymentCreators } = createActions({
  payForProduct: ['price', 'cardDetails', 'subscriptionData', 'coupon', 'paymentMethodId', 'product'],
  secretkeySuccess: ['secretkey', 'paymentMethodId'],
  secretkeyFail: ['error'],
  resetKeys: null,
  paymentSuccess: ['paymentStatus'],
  restorePaymentStatus: null,
  declineCardPayment: null
});
export const initialState = {
  loading: false,
  error: null,
  secretkey: null,
  paymentMethodId: null,
  paymentStatus: false
};

export const paymentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case paymentTypes.RESET_KEYS:
        draft.secretkey = null;
        draft.paymentMethodId = null;
        draft.coupon = null;
        draft.paymentStatus = false;
        break;
      case paymentTypes.PAY_FOR_PRODUCT:
        draft.loading = true;
        draft.error = null;
        break;
      case paymentTypes.SECRETKEY_SUCCESS:
        // draft.loading = false;
        draft.secretkey = action.secretkey;
        draft.paymentMethodId = action.paymentMethodId;
        break;
      case paymentTypes.SECRETKEY_FAIL:
        draft.loading = false;
        draft.secretkey = null;
        draft.error = action.error;
        break;
      case paymentTypes.PAYMENT_SUCCESS:
        draft.paymentStatus = action.paymentStatus;
        break;
      case paymentTypes.RESTORE_PAYMENT_STATUS:
        draft.loading = false;
        draft.paymentStatus = false;
        draft.error = null;
        break;
      case paymentTypes.DECLINE_CARD_PAYMENT:
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default paymentReducer;
