import styled from 'styled-components';

export const StyledBaseSubscriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.84;
  /* padding: 0.5em; */
  border-radius: 1.25rem;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  .textRight {
    text-align: right;
  }
  .bold {
    font-weight: 700;
  }
  .grid {
    flex-grow: 1;
    margin: 0.93em 1.5em;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    font-size: 1em;
  }
`;
export const StyledBaseSubscriptionCardTitle = styled.div`
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  margin-top: 1.31rem;
  margin-bottom: 0.2em;
`;
