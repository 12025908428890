import React, { useState } from 'react';
import { StyledPhoneInput } from './MobileInput.styles';
import 'react-phone-input-2/lib/style.css';

export function MobileInput(props) {
  const { value, onChange, className, ref } = props;
  const [valueState, setValueState] = useState('');
  return (
    <StyledPhoneInput
      className={className}
      ref={ref}
      country={'gb'}
      enableSearch={true}
      disableSearchIcon={true}
      value={value ? value : valueState}
      onChange={
        onChange
          ? onChange
          : (phone) => {
              setValueState(phone);
            }
      }
    />
  );
}
