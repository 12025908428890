import { PrimaryButton } from '@modules/common';
import styled from 'styled-components';

export const FormContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  padding-top: 3.62rem;
  width: 37.875rem;
  flex-shrink: 0;
  .title {
    color: ${(props) => props.theme.black};
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00625rem;
    margin-top: 10.31rem;
    margin-bottom: 1.5rem;
  }
  .resend {
    color: var(--Neutral-100, #bdc0c1);
    text-align: center;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.00625rem;
    margin-top: 2.06rem;
  }
  .signup_text {
    margin-top: 1.62rem;
    text-align: center;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.00625rem;
    .grey_text {
      color: ${(props) => props.theme.greyText};
    }
    .highlight_text {
      cursor: pointer;
      color: ${(props) => props.theme.highlight};
    }
  }
`;
export const StyledLogoImg = styled.img`
  width: 12.5rem;
  height: 4rem;
  flex-shrink: 0;
`;
export const StyledInput = styled.input`
  width: 25.125rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-width: 0.1rem;
  border-color: ${(props) => props.theme.inputBorder};
  border-style: solid;
  border-radius: 0.5rem;
  margin-top: 1rem;
`;
export const OtpButton = styled(PrimaryButton)`
  width: 25.1875rem;
  height: 3.125rem;
  flex-shrink: 0;
  padding: 0.75rem 0.94rem;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  background-color: ${(props) => (props.disabled ? 'grey' : props.theme.highlight)};
`;
