import React, { useState, useEffect } from 'react';
import { logToCrashalytics } from 'tatvarnsdk/Web';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, Translation } from '../common/index';
import { DownloadContainer } from './Download.styles';
import { appStore, playStore } from '@app/themes/images';

function Download() {
  const [os, setOs] = useState();
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || window.opera;
    if (/android/i.test(userAgent)) {
      setOs('Android');
      window.location.href = 'https://play.google.com/store/apps/details?id=app.tatva';
      return;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('IOS');
      window.location.href = 'https://apps.apple.com/in/app/tatva/id1611509432';
      return;
    }

    setOs('Unknown Device');
    return;
  };

  useEffect(() => {
    getMobileOperatingSystem();
    logToCrashalytics('Download Container');
  }, []);
  return (
    <DownloadContainer>
      <div className="container">
        <div className="title">
          <Translation id="REDIRECT_TO" />
        </div>
        {os && os === 'Unknown Device' ? (
          <div className="content">
            <div
              className="imageBox"
              onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=app.tatva')}
            >
              <img src={playStore} height="100%" width="100%" />
            </div>
            <div
              className="imageBox"
              onClick={() => (window.location.href = 'https://apps.apple.com/in/app/tatva/id1611509432')}
            >
              <img src={appStore} height="100%" width="100%" />
            </div>
          </div>
        ) : os === 'IOS' ? (
          <div className="content">
            <div
              className="imageBox"
              onClick={() => (window.location.href = 'https://apps.apple.com/in/app/tatva/id1611509432')}
            >
              <img src={appStore} height="100%" width="100%" />
            </div>
          </div>
        ) : (
          <div className="content">
            <div
              className="imageBox"
              onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=app.tatva')}
            >
              <img src={playStore} height="100%" width="100%" />
            </div>
          </div>
        )}
      </div>
    </DownloadContainer>
  );
}

export default Download;
