export const NOTIFICATION_TYPE = {
  DEFAULT: 0,
  SUCCESS: 1,
  ERROR: 2,
  WARNING: 3,
  INFO: 4
};

export const LEVELS = {
  0: 'Open',
  1: 'Beginner',
  2: 'Intermediate',
  3: 'Expert'
};

export const PAYMENT_METHODS = {
  1: 'Credit Card',
  2: 'Debit Card',
  3: 'Netbanking',
  4: 'Paypal'
};
