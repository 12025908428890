import { recordErrorInCrashalytics, logToCrashalytics, NOTIFICATION_TYPE, queryInDatabase } from 'tatvarnsdk/Web';
import { showToast } from '@modules/common/index';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, signInWithCustomToken } from 'firebase/auth';
import { firebaseApp } from '@app/firebase';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

let recaptchaVerifier;
export const formatTimer = (timer) => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
export const verifyUser = async (props) => {
  logToCrashalytics('verifyUser => app/scenes/LoginSignUpScreen/saga');
  const { mobile } = props;
  try {
    let mobileNo = mobile;
    if (mobile.charAt(0) !== '+') {
      mobileNo = `+${mobile}`;
    }
    const output = await queryInDatabase('users', 'mobileNumber', mobileNo);
    if (output.length < 1) {
      throw 'Please Sign Up!';
    } else {
      return true;
    }
  } catch (e) {
    showToast({
      titleId: 'USER_DOESNT_EXIST',
      message: e,
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    recordErrorInCrashalytics('LoginSignUp saga verifyUser', e);
    return false;
  }
};

export const reCaptcha = async (mobile) => {
  logToCrashalytics('reCaptcha => app/scenes/LoginSignUpScreen/saga');
  try {
    const auth = await getAuth(firebaseApp);
    mobile = '+' + mobile;
    recaptchaVerifier = await new RecaptchaVerifier('recaptcha-container', {}, auth);
    await recaptchaVerifier.render();
    const confirmationResult = await signInWithPhoneNumber(auth, mobile, recaptchaVerifier);
    await recaptchaVerifier.clear();
    showToast({
      titleId: 'success',
      message: 'OTP Sent Successfully',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
    return { confirmationResult: confirmationResult, status: true };
  } catch (error) {
    showToast({
      titleId: 'PLEASE_TRY_AGAIN',
      message: error.message,
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    await recaptchaVerifier.clear();
    recordErrorInCrashalytics('LoginSignUp saga reCaptcha', error);
    return { error: error.message, status: false };
  }
};

export const checkOTP = async (confirmationResult, code) => {
  logToCrashalytics('checkOTP => app/scenes/LoginSignUpScreen/saga');
  try {
    const res = await confirmationResult.confirm(code);
    showToast({
      titleId: 'success',
      message: 'User logged in successfully',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
  } catch (error) {
    showToast({
      titleId: 'WRONG_OTP',
      message: 'Invalid OTP',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    recordErrorInCrashalytics('LoginSignUp saga checkOTP', error);
    return false;
  }
};

export const checkQR = async (webQr) => {
  logToCrashalytics('checkQR => app/scenes/LoginSignUpScreen/saga');
  try {
    const db = await getFirestore();
    const unsubscribe = await onSnapshot(doc(db, 'web_qr_codes', webQr), async (doc) => {
      let docData = doc.data();
      if (docData && docData.token) {
        await signInWithCustomToken(getAuth(firebaseApp), docData.token)
          .then(() => {
            showToast({
              titleId: 'success',
              message: 'User logged in successfully',
              notificationType: NOTIFICATION_TYPE.SUCCESS
            });
          })
          .finally(() => {
            unsubscribe();
          });
      }
    });
  } catch (error) {
    showToast({
      titleId: 'LOGIN_FAILURE',
      message: 'Please try again!',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    recordErrorInCrashalytics('LoginSignUp saga checkQR', error);
    return false;
  }
};
