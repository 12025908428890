import React, { useEffect, useState } from 'react';
import {
  Row,
  InputTitle,
  OuterCard,
  RadioContainer,
  PlanName,
  InputStyle,
  ReactSelect,
  ErrorMessage,
  PayButton,
  Col,
  SelectCol
} from './CardDetails.styles';
import { Input, Radio, Translation, PrimaryButton } from '@modules/common/index';
import { getMonthAndYearList, getColorStyles } from '../../functionUtils';
import { useTheme } from 'styled-components';
import {
  handleInputChange,
  initialEMessage,
  initialErrors,
  isFormEmpty,
  isFormValid,
  checkDate
} from './functionsUtils';

export const CardDetails = ({ newCard, setNewCard, loading, onSubmit }) => {
  const theme = useTheme();
  const [errors, setErrors] = useState(initialErrors);
  const [eMessage, setEMessage] = useState(initialEMessage);
  const { monthOptions, yearOptions } = getMonthAndYearList();
  const [submit, setSubmit] = useState(false);
  const colourStyles = getColorStyles({ theme });
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'cardNumber') {
      value = value.replace(/[^0-9]/g, '');
      value = value.slice(0, 16);
      value = value.replace(/(\d{4})(?=\d)/g, '$1 ');
    }
    if (name === 'cvv') {
      value = value.replace(/[^0-9]/g, '');
      value = value.slice(0, 4);
    }
    handleInputChange({ name, value, newCard, setNewCard, setErrors, setEMessage });
  };
  useEffect(() => {
    setNewCard({ name: '', cardNumber: '', month: '', year: '', cvv: '' });
  }, []);
  useEffect(() => {
    if (
      isFormValid({ errors }) &&
      isFormEmpty({ newCard }) &&
      !checkDate({ year: newCard.year, month: newCard.month, setEMessage, eMessage })
    ) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [errors, newCard]);
  return (
    <>
      <OuterCard>
        <RadioContainer>
          <Radio selected={true} backgroundColor={theme.white} />
        </RadioContainer>
        <PlanName>
          <Translation id="card_type" />
        </PlanName>
      </OuterCard>
      <Row>
        <div className="inputBox">
          <Col>
            <div>
              <InputTitle>
                <Translation id="card_hold" />
              </InputTitle>
              <Input
                placeHolderText={'Full Name'}
                name="name"
                hasError={errors.name}
                width="29.063rem"
                height="3.125rem"
                value={newCard.name}
                onChange={handleChange}
              />
              {errors.name && (
                <ErrorMessage>
                  <Translation text={eMessage.name} />
                </ErrorMessage>
              )}
            </div>
            <div>
              <InputTitle>
                <Translation id="card_no" />
              </InputTitle>
              <Input
                placeHolderText={'XXXX XXXX XXXX XXXX'}
                name="cardNumber"
                width="29.063rem"
                height="3.125rem"
                value={newCard.cardNumber}
                hasError={errors.cardNumber}
                onChange={handleChange}
              />
              {errors.cardNumber && (
                <ErrorMessage>
                  <Translation text={eMessage.cardNumber} />
                </ErrorMessage>
              )}
            </div>
          </Col>
        </div>
        <SelectCol>
          <div className="selectMonthFirst">
            <InputTitle>
              <Translation id="exp_mon" />
            </InputTitle>
            <InputStyle>
              <ReactSelect
                classNamePrefix="Select"
                name="month"
                placeholder="XX"
                value={{ label: newCard.month, value: newCard.month }}
                onChange={(month) =>
                  setNewCard({
                    ...newCard,
                    month: month.value
                  })
                }
                options={monthOptions}
                styles={colourStyles}
              />
              {
                // (!newCard.month && (
                //   <ErrorMessage>
                //     <Translation id="EMPTY_ERROR" />
                //   </ErrorMessage>
                // )) ||
                checkDate({ year: newCard.year, month: newCard.month, setEMessage, eMessage }) && (
                  <ErrorMessage>
                    <Translation id="EMPTY_DATE" />
                  </ErrorMessage>
                )
              }
            </InputStyle>
          </div>
          <div className="selectMonthSecond">
            <InputTitle>
              <Translation id="exp_yr" />
            </InputTitle>
            <InputStyle>
              <ReactSelect
                classNamePrefix="Select"
                name="year"
                placeholder="XX"
                value={{ label: newCard.year, value: newCard.year }}
                onChange={(year) =>
                  setNewCard({
                    ...newCard,
                    year: year.value
                  })
                }
                options={yearOptions}
                styles={colourStyles}
              />
              {/* {!newCard.year && (
                <ErrorMessage>
                  <Translation id="EMPTY_ERROR" />
                </ErrorMessage>
              )} */}
            </InputStyle>
          </div>
        </SelectCol>
      </Row>
      <Row>
        <Col>
          <div className="cvv">
            <InputTitle>
              <Translation id="cvv" />
            </InputTitle>
            <Input
              placeHolderText={'XXX'}
              width={'13.75rem'}
              mediaWidth={'8.75rem'}
              value={newCard.cvv}
              onChange={handleChange}
              hasError={errors.cvv}
              name="cvv"
            />
            {errors.cvv && (
              <ErrorMessage>
                <Translation text={eMessage.cvv} />
              </ErrorMessage>
            )}
          </div>
          <div />
        </Col>
        <Col>
          <div />
          {!submit || loading ? (
            <PayButton textId="pay" disabled submit={submit} />
          ) : (
            <PayButton textId="pay" submit={submit} onClick={(e) => onSubmit(e)} />
          )}
        </Col>
      </Row>
    </>
  );
};
