import produce from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: subscriptionTypes, Creators: subscriptionCreators } = createActions({
  requestDetails: null,
  plansSuccess: [
    'planData',
    'userSubscription',
    'userPlanDescription',
    'paymentMethodsArray',
    'userProfile',
    'disciplineList'
  ],
  plansFails: ['error'],
  resetKeys: null,
  changeDefaultCard: ['cardId', 'customerId'],
  changeDefaultCardSuccess: ['cardDetails'],
  changeDefaultCardError: ['error'],
  addCardRequest: ['newCardDetails', 'customerId'],
  addCardSuccess: ['cardDetails'],
  addCardError: ['error'],
  deleteCardRequest: ['cardId', 'customerId'],
  deleteCardSuccess: ['cardDetails'],
  deleteCardError: ['error'],
  retryCardRequest: ['cardId', 'customerId', 'alert'],
  retryCardSuccess: ['cardDetails', 'userSubscription'],
  retryCardError: ['error'],
  applyCoupon: ['couponCode', 'plans', 'userProfile'],
  couponSuccess: ['coupon', 'discountedPlans'],
  couponFail: null,
  resetCoupon: ['planData'],
  resetCouponSuccess: ['planData'],
  deleteDocTokenSub: ['docId']
});
export const initialState = {
  userProfile: null,
  planData: [],
  userSubscription: null,
  loading: null,
  error: null,
  userPlanDescription: [],
  userCardDetailsLoading: false,
  userCardDetails: [],
  couponLoading: false,
  coupon: null,
  couponError: false,
  retryPaymentInitiated: false,
  disciplineList: []
};

export const subscriptionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case subscriptionTypes.REQUEST_DETAILS:
        draft.loading = true;
        draft.error = null;
        draft.couponError = false;
        break;
      case subscriptionTypes.PLANS_SUCCESS:
        draft.planData = action.planData;
        draft.userSubscription = action.userSubscription;
        draft.userPlanDescription = action.userPlanDescription;
        draft.userCardDetails = action.paymentMethodsArray;
        draft.userProfile = action.userProfile;
        draft.disciplineList = action.disciplineList;
        draft.loading = false;
        draft.userCardDetailsLoading = false;
        draft.couponLoading = false;
        break;
      case subscriptionTypes.RESET_KEYS:
        draft.retryPaymentInitiated = false;
        draft.coupon = null;
        draft.couponError = false;
        break;
      case subscriptionTypes.CHANGE_DEFAULT_CARD:
        draft.userCardDetailsLoading = true;
        break;
      case subscriptionTypes.CHANGE_DEFAULT_CARD_SUCCESS:
        draft.userCardDetails = action.cardDetails;
        draft.userCardDetailsLoading = false;
        draft.error = null;
        break;
      case subscriptionTypes.CHANGE_DEFAULT_CARD_ERROR:
        draft.userCardDetailsLoading = false;
        draft.error = action.error;
        break;
      case subscriptionTypes.ADD_CARD_REQUEST:
        draft.userCardDetailsLoading = true;
        break;
      case subscriptionTypes.ADD_CARD_SUCCESS:
        draft.userCardDetails = action.cardDetails;
        draft.userCardDetailsLoading = false;
        draft.error = null;
        break;
      case subscriptionTypes.ADD_CARD_ERROR:
        draft.userCardDetailsLoading = false;
        draft.error = action.error;
        break;
      case subscriptionTypes.DELETE_CARD_REQUEST:
        draft.userCardDetailsLoading = true;
        break;
      case subscriptionTypes.DELETE_CARD_SUCCESS:
        draft.userCardDetails = action.cardDetails;
        draft.userCardDetailsLoading = false;
        draft.error = null;
        break;
      case subscriptionTypes.DELETE_CARD_ERROR:
        draft.userCardDetailsLoading = false;
        draft.error = action.error;
        break;
      case subscriptionTypes.RETRY_CARD_REQUEST:
        draft.userCardDetailsLoading = true;
        break;
      case subscriptionTypes.RETRY_CARD_SUCCESS:
        draft.userCardDetails = action.cardDetails;
        draft.userSubscription = action.userSubscription;
        draft.userCardDetailsLoading = false;
        draft.retryPaymentInitiated = true;
        draft.error = null;
        break;
      case subscriptionTypes.RETRY_CARD_ERROR:
        draft.userCardDetailsLoading = false;
        draft.error = action.error;
        break;
      case subscriptionTypes.APPLY_COUPON:
        draft.couponLoading = true;
        draft.couponError = false;
        break;
      case subscriptionTypes.COUPON_SUCCESS:
        draft.couponLoading = false;
        draft.loading = false;
        draft.planData = action.discountedPlans;
        draft.coupon = action.coupon;
        draft.error = null;
        break;
      case subscriptionTypes.COUPON_FAIL:
        draft.couponLoading = false;
        draft.couponError = true;
        draft.coupon = null;
        break;
      case subscriptionTypes.RESET_COUPON:
        draft.couponLoading = true;
        draft.error = null;
        draft.coupon = null;
        break;
      case subscriptionTypes.RESET_COUPON_SUCCESS:
        draft.couponLoading = false;
        draft.planData = action.planData;
        break;
      case subscriptionTypes.DELETE_DOC_TOKEN_SUB:
        break;
      default:
        return state;
    }
  });

export default subscriptionReducer;
