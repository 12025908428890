import styled from 'styled-components';

export const StyledPausedSubscriptionCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.84;
  border-radius: 1.2em;
  /* padding: 0.5em; */
  box-shadow: 0rem 0.25rem 0.25rem 0.063rem rgba(0, 0, 0, 0.25);
  color: ${(props) => props.leftColor};
  background: ${(props) => props.leftBackground};
  .colorRight {
    color: ${(props) => props.theme.white};
  }
  .textRight {
    text-align: right;
  }
  .bold {
    font-weight: 700;
  }
`;
export const PausedSubscriptionCardTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 2em;
  font-weight: 700;
  margin: 0.5em 0.75em 0.2em 0.75em;
`;
export const SubscriptionInfoGrid = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0.93em 1.5em;
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-size: 1em;
`;

export const StyledRightBackground = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  /* scale: 1.1; */
  border-radius: 0 1.2em 1.2em 0;
  background: ${(props) => props.theme.bronzeCardBackground};
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 45% 100%);
`;

export const StyledWhiteBackground = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 1.2em 1.2em 0;
  background: ${(props) => props.theme.white};
  clip-path: polygon(59.7% 0, 100% 0, 100% 100%, 44.7% 100%);
`;
