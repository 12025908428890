import styled from 'styled-components';
import { BackButton, StyledContainer } from '../common/index';
import { media } from '@app/themes/index';

export const PaymentScreenContainer = styled(StyledContainer)`
  background-color: ${(props) => props.theme.background};
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.lessThan('tablet')`
  min-height: 100vh;
  `}
`;
export const LoadingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.textPrimary};
  ${media.lessThan('tablet')`
  margin-top: 3rem;
  align-items: flex-start;
  `}
`;
export const Body = styled.div`
  margin-left: 6.88rem;
  margin-right: 6.88rem;
  /* height: 54rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 7.5rem;
  ${media.lessThan('tablet')`
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  /* height: 44rem; */
  `}
`;
export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan('tablet')`
  flex-direction: column;
  justify-content: space-between;
  `}
`;
export const Row2 = styled.div`
  margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .cancelPolicy {
    width: 34rem;
    ${media.lessThan('tablet')`
    width: 25rem;
  `}
  }
`;
export const CouponContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
export const HeaderWrapper = styled.div`
  margin-right: 1.25rem;
  margin-top: 1.75rem;
  align-self: flex-start;
  display: flex;
  margin-left: 3.5rem;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
  margin-left: 1.31rem;
  `}
`;
export const BackButtonWrapper = styled(BackButton)`
  ${media.lessThan('tablet')`
  display: none;
  `}
`;

export const Arrow = styled.div`
  border: solid ${(props) => props.theme.highlight};
  border-width: 0 0.188rem 0.188rem 0;
  margin-top: 1rem;
  margin-left: 0.7rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.71rem;
  flex-shrink: 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  ${media.greaterThan('tablet')`
    display: none;
  `}
  ${media.lessThan('mobile')`
  width: 1.4rem;
  height: 1.4rem;
  `}
`;
