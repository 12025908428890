/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import dashboardReducer from '@app/modules/Dashboard/reducer';
import subscriptionReducer from '@app/modules/Subscription/reducer';
import paymentReducer from '@app/modules/Payment/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  const rootReducer = combineReducers({
    ...injectedReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    dashboard: dashboardReducer,
    subscription: subscriptionReducer,
    payment: paymentReducer
  });

  return rootReducer;
}
