import React, { useState, useEffect } from 'react';
import {
  AddPaymentBtn,
  ButtonRow,
  CardWrapper,
  CheckBox,
  CheckBoxChecked,
  CheckBoxUnChecked,
  CrossWrapper,
  DownloadReceiptBtn,
  ErrorMessage,
  InputBox,
  InputStyle,
  InputTitle,
  PopUpCard,
  PopUpHeader,
  ReactSelect,
  Row,
  Title
} from './PaymentPopUp.styles';
import { Input, Radio, Translation } from '@app/modules/common/index';
import { BiCheckboxChecked, BiCheckbox } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { getMonthAndYearList, getColorStyles } from '@app/modules/Payment/functionUtils';
import {
  checkDate,
  handleInputChange,
  initialEMessage,
  initialErrors,
  initialFormData,
  isFormEmpty,
  isFormValid
} from './functionsUtils';

export default function PaymentPopUp({ setIsOpenPayment, addNewCard }) {
  const theme = useTheme();
  const { monthOptions, yearOptions } = getMonthAndYearList();
  const [hasError, setHasError] = useState(true);
  const [check, setCheck] = useState(false);
  const [add, setAdd] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [eMessage, setEMessage] = useState(initialEMessage);

  useEffect(() => {
    if (
      isFormValid({ errors }) &&
      isFormEmpty({ formData, errors, setErrors }) &&
      !checkDate({ year: formData.expiryYear, month: formData.expiryDate, setEMessage, eMessage })
    ) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }, [errors]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'cardNo') {
      value = value.replace(/[^0-9]/g, '');
      value = value.slice(0, 16);
      value = value.replace(/(\d{4})(?=\d)/g, '$1 ');
    }
    if (name === 'cvv') {
      value = value.replace(/[^0-9]/g, '');
      value = value.slice(0, 4);
    }
    handleInputChange({ name, value, formData, setFormData, errors, setErrors, eMessage, setEMessage });
  };

  const handleExpiryDateChange = (selectedOption) => {
    setFormData({
      ...formData,
      ['expiryDate']: selectedOption
    });
    setErrors({
      ...errors,
      ['expiryDate']: false
    });
  };

  const handleExpiryYearChange = (selectedOption) => {
    setFormData({
      ...formData,
      ['expiryYear']: selectedOption
    });
    setErrors({
      ...errors,
      ['expiryYear']: false
    });
  };
  const handleCross = () => {
    if (
      !add &&
      (formData.fullName.length !== 0 ||
        formData.cardNo.length !== 0 ||
        formData.expiryDate.length !== 0 ||
        formData.expiryYear.length !== 0 ||
        formData.cvv.length !== 0)
    ) {
      const result = window.confirm('Your data is not saved.\n\nDo you still want to exit?');
      if (!result) {
        setIsOpenPayment(true);
      } else {
        setIsOpenPayment(false);
        setFormData(initialFormData);
        setErrors(initialErrors);
        setEMessage(initialEMessage);
        setHasError(true);
        setCheck(false);
        setAdd(false);
      }
    } else {
      setIsOpenPayment(false);
      setFormData(initialFormData);
      setErrors(initialErrors);
      setEMessage(initialEMessage);
      setHasError(true);
      setCheck(false);
      setAdd(false);
    }
  };
  const handleAddNewCard = () => {
    const newCardDetails = {
      cardNumber: formData.cardNo,
      month: formData.expiryDate.value,
      year: formData.expiryYear.value,
      cvv: formData.cvv,
      name: formData.fullName,
      setAsDefault: check
    };
    addNewCard(newCardDetails);
    setIsOpenPayment(false);
  };

  const color = theme.black;
  const colourStyles = getColorStyles({ theme, color });
  return (
    <PopUpCard>
      <PopUpHeader>
        <Title>
          <Translation id="YOUR_SAVED_CARDS" />
        </Title>
        <CrossWrapper onClick={handleCross}>
          <AiOutlineClose />
        </CrossWrapper>
      </PopUpHeader>
      <CardWrapper>
        <InputTitle>
          <Translation id="card_hold" />
        </InputTitle>
        <InputBox
          placeholder={'Full Name'}
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          hasError={errors.fullName}
          width="31rem"
          height="3.125rem"
        />
        {errors.fullName && (
          <ErrorMessage>
            <Translation text={eMessage.fullName} />
          </ErrorMessage>
        )}
      </CardWrapper>
      <CardWrapper>
        <InputTitle>
          <Translation id="card_no" />
        </InputTitle>
        <InputBox
          placeholder={'XXXX XXXX XXXX XXXX'}
          name="cardNo"
          onChange={handleChange}
          value={formData.cardNo}
          hasError={errors.cardNo}
          width="31rem"
          height="3.125rem"
        />
        {errors.cardNo && (
          <ErrorMessage>
            <Translation text={eMessage.cardNo} />
          </ErrorMessage>
        )}
      </CardWrapper>
      <Row>
        <div>
          <InputTitle>
            <Translation id="EXPIRY_DATE" />
          </InputTitle>
          <InputStyle>
            <ReactSelect
              classNamePrefix="Select"
              placeholder="XX"
              value={formData.expiryDate}
              name="expiryDate"
              onChange={handleExpiryDateChange}
              options={monthOptions}
              styles={colourStyles}
            />
            {
              // (formData.expiryDate.value === '' && (
              //   <ErrorMessage>
              //     <Translation id="EMPTY_ERROR" />
              //   </ErrorMessage>
              // )) ||
              checkDate({ year: formData.expiryYear, month: formData.expiryDate, setEMessage, eMessage }) && (
                <ErrorMessage>
                  <Translation id="EMPTY_DATE" />
                </ErrorMessage>
              )
            }
          </InputStyle>
        </div>
        <div>
          <InputTitle>
            <Translation id="exp_yr" />
          </InputTitle>
          <InputStyle>
            <ReactSelect
              classNamePrefix="Select"
              placeholder="XX"
              value={formData.expiryYear}
              name="expiryYear"
              onChange={handleExpiryYearChange}
              options={yearOptions}
              styles={colourStyles}
            />
            {/* {formData.expiryYear.value === '' && (
              <ErrorMessage>
                <Translation id="EMPTY_ERROR" />
              </ErrorMessage>
            )} */}
          </InputStyle>
        </div>
      </Row>
      <Row>
        <div>
          <InputTitle>
            <Translation id="cvv" />
          </InputTitle>
          <InputBox
            placeholder={'XXX'}
            width={'13.75rem'}
            value={formData.cvv}
            onChange={handleChange}
            hasError={errors.cvv}
            name="cvv"
          />
          {errors.cvv && (
            <ErrorMessage>
              <Translation text={eMessage.cvv} />
            </ErrorMessage>
          )}
        </div>
      </Row>
      <ButtonRow>
        <div className="checkbox" onClick={() => setCheck(!check)}>
          <div className="button">{!check ? <CheckBoxUnChecked /> : <CheckBoxChecked />}</div>
          <div className="label">
            <Translation id="DEFAULT_CARD_MESSAGE" />
          </div>
        </div>
        <div className="button">
          <DownloadReceiptBtn textId="cancel" onClick={handleCross} />
          <AddPaymentBtn
            textId="add"
            hasError={hasError}
            onClick={() => {
              if (!hasError) {
                setAdd(true);
                handleAddNewCard();
              }
            }}
          />
        </div>
      </ButtonRow>
    </PopUpCard>
  );
}
