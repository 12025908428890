import styled from 'styled-components';
import { media } from '@app/themes/index';

export const PaymentSummaryContainer = styled.div`
  background-color: ${(props) => props.theme.transparent13};
  padding-right: 2.75rem;
  padding-left: 2.75rem;
  padding-top: 1.37rem;
  /* padding-bottom: 4.25rem; */
  display: flex;
  border-radius: 0.75rem;
  border: 0.063rem solid ${(props) => props.theme.white};
  /* align-items: flex-start; */
  flex-direction: column;
  width: 30.625rem;
  max-height: 20.438rem;
  order: 2;
  ${media.lessThan('tablet')`
  width: 25rem;
  order: 1;
  border: 0.063rem solid ${(props) => props.theme.white};
  border-radius: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-right: auto;
  margin-left: auto;
  `}
`;

export const Title = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-weight: 700;
  font-size: 1.93rem;
  margin-bottom: 2.063rem;
`;

export const PlanName = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

export const OtherDesc = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-weight: 400;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.063rem;
  background-color: ${(props) => props.theme.white};
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
