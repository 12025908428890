import produce from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: dashboardTypes, Creators: dashboardCreators } = createActions({
  requestHomeScreenData: null,
  dataSuccess: ['categoriesWithClasses', 'currentUser'],
  dataFail: ['error'],
  registerZoom: ['session'],
  registerZoomEnd: null,
  deleteDocToken: ['docId']
});
export const initialState = {
  categoriesWithClasses: [],
  loading: null,
  error: null,
  cacheKey: null,
  userData: null,
  userAlert: null
};

export const dashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case dashboardTypes.REQUEST_HOME_SCREEN_DATA:
        draft.loading = true;
        draft.error = null;
        break;
      case dashboardTypes.DATA_SUCCESS:
        draft.categoriesWithClasses = action.categoriesWithClasses;
        draft.userData = action.currentUser;
        draft.loading = false;
        draft.error = null;
        break;
      case dashboardTypes.DATA_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case dashboardTypes.REGISTER_ZOOM:
        draft.loading = true;
        break;
      case dashboardTypes.REGISTER_ZOOM_END:
        draft.loading = false;
        break;
      case dashboardTypes.DELETE_DOC_TOKEN:
        break;
      default:
        return state;
    }
  });

export default dashboardReducer;
