import React from 'react';
import { DescriptionWrapper } from './Description.styles';

export default function Description({ description }) {
  return (
    <DescriptionWrapper>
      {description &&
        description.map((element, index) => (
          <li key={index}>
            <span>{element}</span>
          </li>
        ))}
    </DescriptionWrapper>
  );
}
