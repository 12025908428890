import React, { memo, useRef } from 'react';
import { PrimaryButton } from '@app/modules/common/components/PrimaryButton/PrimaryButton';
import Translation from '@app/modules/common/components/Translation/Translation';
import styled from 'styled-components';
import { media } from '@app/themes/index';
import { subscriptionCreators } from '@app/modules/Subscription/reducer';
import { connect } from 'react-redux';
import subscriptionSaga from '@app/modules/Subscription/saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';
import { selectCoupon, selectPlans, selectUserProfile, selectCouponError } from '@app/modules/Subscription/selectors';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

function CouponView(props) {
  const { coupon, couponError, dispatchRemoveCouponFromPlan, planData, userProfile, dispatchApplyCoupon } = props;
  const couponInputRef = useRef();
  const handleCoupon = () => {
    if (coupon) {
      dispatchRemoveCouponFromPlan(planData);
      couponInputRef.current.value = '';
      return;
    }
    if (!couponInputRef.current.value) {
      return;
    }
    dispatchApplyCoupon(couponInputRef.current.value, planData, userProfile);
  };
  return (
    <CouponDiv>
      <p>
        <Translation id="APPLY_PROMOTION_CODE" />
      </p>
      <CouponInputDiv>
        {coupon ? (
          <input type="text" ref={couponInputRef} value={coupon.code} disabled={coupon ? true : false} />
        ) : (
          <input type="text" ref={couponInputRef} disabled={coupon ? true : false} />
        )}
        <BtnDiv onClick={handleCoupon}>
          <PrimaryButton textId={coupon ? 'remove' : 'apply'} />
        </BtnDiv>
      </CouponInputDiv>
      <ErrorDiv>{couponError ? <Translation text="Invalid Coupon Code" /> : ''}</ErrorDiv>
    </CouponDiv>
  );
}

const mapStateToProps = createStructuredSelector({
  planData: selectPlans(),
  coupon: selectCoupon(),
  couponError: selectCouponError(),
  userProfile: selectUserProfile()
});

export function mapDispatchToProps(dispatch) {
  const { applyCoupon, resetCoupon } = subscriptionCreators;

  return {
    dispatchApplyCoupon: (couponCode, plans, userProfile) => dispatch(applyCoupon(couponCode, plans, userProfile)),
    dispatchRemoveCouponFromPlan: (planData) => dispatch(resetCoupon(planData))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
  memo,
  injectSaga({ key: 'subscription', saga: subscriptionSaga })
)(CouponView);

const CouponDiv = styled.div`
  width: 50%;
  margin: auto;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  font-size: 1.125rem;
  font-weight: 700;
  ${media.lessThan('tablet')`
    width: 95%;
  `}
`;

const BtnDiv = styled.div`
  position: absolute;
  top: 0.52rem;
  right: 0.3rem;
`;

const CouponInputDiv = styled.div`
  position: relative;
  margin: 1rem 0;
  input {
    width: 100%;
    height: 3.5rem;
    padding: 0 2rem;
    background: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.white};
    color: ${(props) => props.theme.white};
    font-size: 0.9375rem;
    font-weight: 400;
  }
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.error};
  font-size: 1rem;
`;
