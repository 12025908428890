import React from 'react';
import { BackButtonContainer, ButtonText } from './BackButton.styles';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { themeColors } from '@app/themes/index';
import { Translation } from '@app/modules/common/index';
export { ButtonText };
export const BackButton = ({ id, onClick }) => {
  return (
    <BackButtonContainer onClick={onClick}>
      <AiOutlineArrowLeft color={themeColors.dark.highlight} />
      <ButtonText>
        <Translation id={id} />
      </ButtonText>
    </BackButtonContainer>
  );
};
