/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { LoadingContainer, StyledLoading } from './Loading.styles';
import { ThemeContext } from 'styled-components';

export const Loading = ({ scale = 0.77, stroke }) => {
  const theme = useContext(ThemeContext);
  return (
    <LoadingContainer>
      <StyledLoading className="default-spinner" scale={scale} stroke={stroke || theme.highlight} />
    </LoadingContainer>
  );
};
