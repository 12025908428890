import styled from 'styled-components';
import Translation from '../Translation/Translation';

export const BtnContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`;

export const BtnText = styled(Translation)`
  color: ${(props) => props.theme.white};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const Icon = styled.img`
  height: 1.2rem;
  width: auto;
  object-fit: cover;
  margin-right: 0.5rem;
`;
