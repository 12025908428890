const ELLIGIBLE_CLASSES_FOR_PLANS = {
  PLATINUM: 999,
  GOLD: 999,
  SILVER: 4,
  BRONZE: 0
};

const appleAppStoreURL = 'https://apps.apple.com/in/app/tatva/id1611509432';

const googlePlayStoreURL = 'https://play.google.com/store/apps/details?id=app.tatva';

export { ELLIGIBLE_CLASSES_FOR_PLANS, appleAppStoreURL, googlePlayStoreURL };
