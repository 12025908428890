import styled from 'styled-components';
import { Translation } from '@app/modules/common/index';

export const TeacherSection = styled.div`
  flex-grow: 1;
  display: flex;
  width: 90%;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

export const Title = styled(Translation)`
  color: ${(props) => props.theme.white};
  font-size: 1.4rem;
  font-weight: 400;
  align-self: flex-start;
`;

export const TeacherContainer = styled.div`
  border-radius: 8px;
  align-self: center;
  background-color: ${(props) => props.theme.white};
  width: 100%;
  padding: 0.8rem 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1rem;
`;

export const TeacherImage = styled.img`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
`;

export const TeacherName = styled(Translation)`
  color: ${(props) => props.theme.black};
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Experience = styled(Translation)`
  color: ${(props) => props.theme.black};
  font-size: 1rem;
  font-weight: 200;
`;
