import React from 'react';
import { paymentSuccessSticker } from '@app/themes/images';

export const Illustrations = ({ height, width }) => {
  return (
    <div>
      <img src={paymentSuccessSticker} alt="Success Image" height={height} width={width} />
    </div>
  );
};
