import React from 'react';
import { StyledBaseSubscriptionCard, StyledBaseSubscriptionCardTitle } from './BaseSubscriptionCard.styles';

function BaseSubscriptionCard({ color, background, title, topLeft, topRight, bottomLeft, bottomRight }) {
  return (
    <StyledBaseSubscriptionCard color={color} background={background}>
      <StyledBaseSubscriptionCardTitle>{title}</StyledBaseSubscriptionCardTitle>
      <div className="grid">
        <div>
          <p className={`label ${topLeft.title.bold ? 'bold' : ''}`}>{topLeft.title.text}</p>
          <p className={`value ${topLeft.value.bold ? 'bold' : ''}`}>{topLeft.value.text}</p>
        </div>
        <div className="textRight">
          <p className={`label ${topRight.title.bold ? 'bold' : ''}`}>{topRight.title.text}</p>
          <p className={`label ${topRight.value.bold ? 'bold' : ''}`}>{topRight.value.text}</p>
        </div>
        <div>
          <p className={`label ${bottomLeft.title.bold ? 'bold' : ''}`}>{bottomLeft.title.text}</p>
          <p className={`label ${bottomLeft.value.bold ? 'bold' : ''}`}>{bottomLeft.value.text}</p>
        </div>
        <div className="textRight">
          <p className={`label ${bottomRight.title.bold ? 'bold' : ''}`}>{bottomRight.title.text}</p>
          <p className={`label ${bottomLeft.value.bold ? 'bold' : ''}`}>{bottomRight.value.text}</p>
        </div>
      </div>
    </StyledBaseSubscriptionCard>
  );
}

export default BaseSubscriptionCard;
