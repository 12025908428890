import React from 'react';
import { textStyle, titleStyle } from '../../PrivacyPolicy.styles';

export default function Security() {
  return (
    <div>
      <p style={textStyle}>
        <strong style={titleStyle}>Security</strong>
      </p>
      <p style={textStyle}>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. But remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
      </p>
    </div>
  );
}
