import styled from 'styled-components';

export const Heading = styled.p`
  color: ${(props) => props.theme.textPrimary};
  text-decoration: underline;
  font-size: 0.9375rem;
`;

export const Description = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.9375rem;
`;
