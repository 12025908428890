import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  color: ${(props) => props.theme.white};
  font-size: 1.5rem;
  font-weight: 400;
  /* padding-top: 1.06rem; */
  align-self: center;
`;
