import { triggerCloudFunction, remoteConfig } from '@app/firebase';
import { getAuth } from 'firebase/auth';
import { getValue, fetchAndActivate } from 'firebase/remote-config';

export const logMessage = async (methodName) => {
  console.log(`Method Called: ${methodName}`);
  const auth = getAuth();
  if (!auth || !auth.currentUser) {
    return;
  }
};

export const recordError = async (methodName, error) => {
  console.log(`Error in ${methodName}`);
  console.log(`${error}`);
};

export const callGoogleCloudFunction = async (functionName, data) => {
  return await triggerCloudFunction(functionName, data);
};

export const fetchRemoteConfig = async (key, defaultValue) => {
  logMessage('fetchFromRemoteConfig => RNSDK utils key: ' + key);
  try {
    let valueFromRemoteConfig = defaultValue;
    await fetchAndActivate(remoteConfig)
      .then(() => {
        valueFromRemoteConfig = getValue(remoteConfig, key)._value;
      })
      .then((secretKey) => secretKey)
      .catch((err) => {
        logMessage('fetchRemoteConfig', err);
      });
    return valueFromRemoteConfig;
  } catch (e) {
    recordError('fetchFromRemoteConfig -> RNSDK utils', e);
    return defaultValue;
  }
};
