import styled from 'styled-components';
import { media } from '@app/themes/index';

export const ExtendedMenu = styled.div`
  width: 12.5rem;
  min-height: 8.375rem;
  padding-bottom: 1rem;
  flex-shrink: 0;
  position: absolute;
  top: 2.75rem;
  right: 0.87rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};
  z-index: 1;
  display: ${(props) => (props.extendNavbar ? 'block' : 'none')};
  ${media.greaterThan('tablet')`
    display: none;
  `};
`;
export const CrossWrapper = styled.div`
  margin-left: 11rem;
  margin-top: 0.5rem;
  &:hover {
    color: ${(props) => props.theme.highlight};
    cursor: pointer;
  }
`;
export const Menu = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-size: 1rem;
  font-weight: 400;
  .subMenu {
  }
`;
export const Divider = styled.div`
  width: 9.87519rem;
  height: 0.0625rem;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.textMuted};
  margin-top: 0.79rem;
  margin-bottom: 0.79rem;
`;
export const List = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
