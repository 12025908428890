import { createSelector } from 'reselect';
import get from 'lodash/get';
import { initialState } from './reducer';

export const selectDashboardDomain = (state) => state.dashboard || initialState;

export const selectDashboard = () => createSelector(selectDashboardDomain, (substate) => substate);

export const selectCategoriesWithClasses = () =>
  createSelector(selectDashboardDomain, (substate) => get(substate, 'categoriesWithClasses', []));

export const selectZoomUrl = () => createSelector(selectDashboardDomain, (substate) => get(substate, 'zoomUrl', null));

export const selectCurrentUser = () =>
  createSelector(selectDashboardDomain, (substate) => get(substate, 'userData', null));

export const selectUserAlert = () =>
  createSelector(selectDashboardDomain, (substate) => get(substate, 'userAlert', null));

export const selectError = () => createSelector(selectDashboardDomain, (substate) => get(substate, 'error'));

export const selectLoading = () => createSelector(selectDashboardDomain, (substate) => get(substate, 'loading'));

export default selectDashboard;
