/* 
This component should always be inside a div which decides its width, root font-size
 */

import React from 'react';
import BaseSubscriptionCard from './components/BaseSubscriptionCard/BaseSubscriptionCard';
import { useTheme } from 'styled-components';
import { cardInfoToDisplay } from './utils/subscriptionWrapperUtils';
import PausedSubscriptionCard from './components/PausedSubscriptionCard/PausedSubscriptionCard';

export function SubscriptionCardWrapper({ plan, type }) {
  const theme = useTheme();
  const card = cardInfoToDisplay(plan, type, theme);
  return type === 'PAUSED_SUBSCRIPTION' ? (
    <PausedSubscriptionCard
      leftColor={card.color}
      leftBackground={card.background}
      title={card.title}
      topLeft={card.topLeft}
      topRight={card.topRight}
      bottomLeft={card.bottomLeft}
      bottomRight={card.bottomRight}
    />
  ) : (
    <BaseSubscriptionCard
      color={card.color}
      background={card.background}
      title={card.title}
      topLeft={card.topLeft}
      topRight={card.topRight}
      bottomLeft={card.bottomLeft}
      bottomRight={card.bottomRight}
    />
  );
}
