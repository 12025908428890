import styled from 'styled-components';
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton';

export const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.625rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'SFProDisplay';
  padding: 0 0.5rem;
`;
export const AlertIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.2rem;
`;
export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
`;
export const HeaderMessage = styled.div`
  font-family: 'SFProDisplay';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const BaseMessage = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const BtnContainer = styled.div`
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  align-items: flex-end;
`;
export const Button = styled(PrimaryButton)`
  border-radius: 0.3125rem;
`;
