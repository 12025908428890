import React, { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getNewQrId, NOTIFICATION_TYPE, recordErrorInCrashalytics } from 'tatvarnsdk/Web';
import { showToast } from '@modules/common/index';
import { firebaseApp } from '@app/firebase';
import { QrContainer, StyledQRCode } from './QRContainer.styles';

const db = getFirestore();

export default function QRContainer(props) {
  const { webQr, setQR, setLoading } = props;

  useEffect(async () => {
    if (!webQr) {
      let response = await getNewQrId();
      setQR(response?.data?.qr);
    }
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (webQr) {
      unsubscribe = onSnapshot(doc(db, 'web_qr_codes', webQr), async (doc) => {
        let docData = doc.data();
        if (docData && docData.token) {
          setLoading(true);
          signInWithCustomToken(getAuth(firebaseApp), docData.token)
            .then(() => {
              showToast({
                titleId: 'success',
                message: 'User logged in successfully',
                notificationType: NOTIFICATION_TYPE.SUCCESS
              });
            })
            .catch((error) => {
              showToast({
                titleId: 'LOGIN_FAILURE',
                message: 'Please try again!',
                notificationType: NOTIFICATION_TYPE.ERROR
              });
              recordErrorInCrashalytics('LoginSignUp saga checkQR', error);
              return false;
            })
            .finally(() => {
              unsubscribe();
            });
          setLoading(false);
        }
      });
    }
    return () => {
      typeof unsubscribe === 'function' ? unsubscribe() : '';
    };
  }, [webQr]);
  return (
    <QrContainer>
      <div className="title">Log in with QR Code</div>
      <div className="title_content">Scan this with the mobile app to login instantly</div>
      <div className="qr_code">{webQr && <StyledQRCode value={webQr} />}</div>
      <ol className="instructions">
        <li value="1">
          Open <b>‘Tatva app’</b> on your phone
        </li>
        <li>
          Tap <b>‘Scan icon’</b> available inside account
        </li>
        <li>
          Point <b>‘QR scanner’</b> to this code to link
        </li>
      </ol>
    </QrContainer>
  );
}
