const valid = require('card-validator');

export const initialFormData = {
  fullName: '',
  cardNo: '',
  expiryDate: { label: '', value: '' },
  expiryYear: { label: '', value: '' },
  cvv: ''
};

export const initialErrors = {
  fullName: false,
  cardNo: false,
  expiryDate: true,
  expiryYear: true,
  cvv: false
};

export const initialEMessage = {
  fullName: '',
  cardNo: '',
  expiryDate: '',
  expiryYear: '',
  cvv: ''
};

export const handleInputChange = ({ name, value, formData, setFormData, setErrors, setEMessage }) => {
  setFormData({
    ...formData,
    [name]: value
  });
  switch (name) {
    case 'fullName':
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'cardNo':
      const cardNo_without_space = value.replace(/\s/g, '');
      let numberValidation = valid.number(cardNo_without_space);
      if (cardNo_without_space === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else if (!/^[0-9]+$/.test(cardNo_without_space)) {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*Input must be a number'
        }));
      } else if (!numberValidation.isValid) {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*Card Number invalid'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'expiryDate':
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'expiryYear':
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
    case 'cvv':
      let maxLength = valid.number(formData.cardNo).card?.code.size;
      let cvvValidation = valid.cvv(value.trim(), maxLength);
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*This field cannot be empty'
        }));
      } else if (!cvvValidation.isValid) {
        setErrors((prev) => ({
          ...prev,
          [name]: true
        }));
        setEMessage((prev) => ({
          ...prev,
          [name]: '*CVV invalid'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: false
        }));
      }
      break;
  }
};

export const isFormValid = ({ errors }) => {
  return Object.values(errors).every((error) => error === false);
};

export const isFormEmpty = ({ formData, errors, setErrors }) => {
  return Object.values(formData).every((formData) => formData.length !== 0);
};

export const checkDate = ({ year, month }) => {
  if (year.value !== '' && month.value !== '') {
    var month_val = Number(month.value);
    var year_val = Number(year.value);
    const d = new Date();
    let cur_year = d.getFullYear();
    let cur_month = d.getMonth();
    if (year_val === cur_year && month_val < cur_month) {
      return true;
    }
  }
  return false;
};
