import React, { useEffect, useState } from 'react';
import { firebaseApp } from '../../firebase';
import { applyActionCode, getAuth } from 'firebase/auth';
import { HeaderContainer, MainContainer, TextStyle } from './EmailVerification.styles';
import { HeaderWithLogo, Translation } from '../common/index';
import { logToCrashalytics } from 'tatvarnsdk/Web';

function EmailVerification() {
  const [message, setMessage] = useState();
  const [isVerifying, setIsVerifying] = useState(true);
  const data = window.location;
  useEffect(() => {
    if (isVerifying) {
      setMessage(<Translation id="verifying" />);
    }
    handleVerifyEmail();
  }, []);
  useEffect(() => {
    logToCrashalytics('Email Container');
  }, []);
  async function handleVerifyEmail() {
    const auth = getAuth(firebaseApp);
    let queryMap = new Map();
    if (data.search) {
      data.search.split('&').forEach((element) => {
        if (element) {
          const key = element.split('=')[0];
          const value = element.split('=')[1];
          queryMap.set(key, value);
        }
      });
    }
    if (queryMap.get('?mode') === 'verifyEmail') {
      applyActionCode(auth, queryMap.get('oobCode'))
        .then((resp) => {
          setIsVerifying(false);
          setMessage(<Translation id="email_verify" />);
        })
        .catch((error) => {
          setIsVerifying(false);
          setMessage(<Translation id="code_invalid" />);
        });
    } else {
      setIsVerifying(false);
      setMessage(<Translation id="nothing_verify" />);
    }
  }

  return (
    <MainContainer>
      <div>
        <div>
          <HeaderContainer>
            <HeaderWithLogo />
          </HeaderContainer>
        </div>
      </div>
      <TextStyle>
        <h1>
          <Translation text={message} />
        </h1>
      </TextStyle>
    </MainContainer>
  );
}

export default EmailVerification;
