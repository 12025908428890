import React from 'react';
import { textStyle, titleStyle } from '@modules/PrivacyPolicy/PrivacyPolicy.styles';

export default function ServiceProviders() {
  return (
    <div>
      <p style={textStyle}>
        <strong style={titleStyle}>Service Providers</strong>
      </p>
      <p style={textStyle}>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li style={textStyle}>To facilitate our Service;</li>
        <li style={textStyle}>To provide the Service on our behalf;</li>
        <li style={textStyle}>To perform Service-related services; or</li>
        <li style={textStyle}>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p style={textStyle}>
        We want to inform users of this Service that these third parties have access to their Personal Information. The
        reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or
        use the information for any other purpose.
      </p>
    </div>
  );
}
