import styled from 'styled-components';
import { media } from '@app/themes/index';

export const StyledNoSubscriptionCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: ${(props) => props.theme.black};
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(123, 123, 123, 0.25) inset;
  //TODO: this should be based on aspect ratio probably
  height: 100%;
  border-radius: 1.2rem;
  font-size: 1.25rem;
  .link {
    color: ${(props) => props.theme.highlight};
    text-decoration: underline;
    cursor: pointer;
  }
`;
