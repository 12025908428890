import NotFound from '@modules/NotFoundPage/index';
import routeConstants from '@utils/routeConstants';
import LoginSignUpPageContainer from '@app/modules/LoginSignUpPageContainer/index';
import Subscription from '@modules/Subscription/index';
import Dashboard from '@modules/Dashboard/index';
import Payment from '@modules/Payment/index';
import paymentSuccess from '@modules/PaymentSuccess/index';
import PrivacyPolicy from '@modules/PrivacyPolicy';
import EmailVerification from '@modules/EmailVerification/index';
import DeleteAccountContainer from '@modules/DeleteAccount/index';
import Download from '@modules/Download/index';
import ClassDetails from '@modules/ClassDetails/index';

export const routeConfig = {
  deleteAccount: {
    component: DeleteAccountContainer,
    ...routeConstants.deleteAccount
  },
  emailVerification: {
    component: EmailVerification,
    ...routeConstants.emailVerification
  },
  privacyPolicy: {
    component: PrivacyPolicy,
    ...routeConstants.privacyPolicy
  },
  classDetails: {
    component: ClassDetails,
    ...routeConstants.classDetails
  },
  loginSignUp: {
    component: LoginSignUpPageContainer,
    ...routeConstants.loginSignUp
  },
  dashboard: {
    component: Dashboard,
    ...routeConstants.dashboard
  },
  subscription: {
    component: Subscription,
    ...routeConstants.subscription
  },
  download: {
    component: Download,
    ...routeConstants.download
  },
  payment: {
    component: Payment,
    ...routeConstants.payment
  },
  paymentSuccess: {
    component: paymentSuccess,
    ...routeConstants.paymentSuccess
  },
  notFoundPage: {
    component: NotFound,
    route: '*'
  }
};
