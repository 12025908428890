import React, { useEffect, useState } from 'react';
import { logToCrashalytics, getClassAndTeachersDetails } from 'tatvarnsdk/Web';
import { useHistory } from 'react-router-dom';
import {
  ClassDetailsContainer,
  NotFount,
  Button,
  NotFoundContainer,
  NotFoundImage,
  Loader,
  Border,
  ClassImage,
  ClassVideo
} from './ClassDetails.styles';
import Details from './components/Details/Details';
import Header from './components/Header/Header';
import ShareBar from './components/ShareBar/ShareBar';
import TeacherFooter from './components/TeacherFooter/TeacherFooter';
import notfound from '../../images/notfound.svg';
import { routeConfig } from '../../routeConfig';
import { appleAppStoreURL, googlePlayStoreURL } from '../common/utils/constants';

function ClassDetails(props) {
  const { isLoggedIn } = props;
  const [noClass, setNoClass] = useState(false);
  const [classDetails, setClassDetails] = useState(null);
  const history = useHistory();
  const [os, setOs] = useState('Unknown Device');

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || window.opera;
    if (/android/i.test(userAgent)) {
      setOs('ANDROID');
      return;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('IOS');
      return;
    }
    return;
  };

  useEffect(async () => {
    logToCrashalytics('ClassDetails');
    getMobileOperatingSystem();
    const { location } = props;
    let classId = null;
    if (location && location.search.split('=').length > 0) {
      classId = location.search.split('=')[1];
    }
    const response = await getClassAndTeachersDetails(classId);
    if (response.success) {
      setClassDetails(response.data.classDetails);
    } else {
      setNoClass(true);
    }
  }, []);

  useEffect(() => {
    if (os != 'Unknown Device') {
      let appBaseLink = 'tatva.app://'; // same for IOS prd and android
      if (os === 'IOS') {
        if (
          window.location.host.includes('dev') ||
          window.location.host.includes('localhost') ||
          window.location.host.includes('192') //LAN
        ) {
          appBaseLink = 'tatva.app.dev://';
        } else if (window.location.host.includes('uat')) {
          appBaseLink = 'tatva.app.uat://';
        }
      } else if (os === 'ANDROID') {
        appBaseLink = 'tatva.app://';
      }
      if (location && location.search.split('=').length > 0) {
        const classId = location.search.split('=')[1];
        if (classId) {
          const endPoint = `classDetails?id=${classId}`;
          window.location.replace(`${appBaseLink}${endPoint}`);
        }
      }
    }
  }, [os]);

  const redirectToDownload = async () => {
    if (os === 'Unknown Device') {
      history.push({
        pathname: routeConfig.dashboard.route,
        state: { classDetails }
      });
    } else {
      history.push(routeConfig.download.route);
    }
  };

  const DisplyCover = () => {
    let displayCoverPic = classDetails?.coverPicUrl;
    if (classDetails?.classMedia.length > 0) {
      for (let i = 0; i < classDetails?.classMedia.length; i++) {
        if (!classDetails?.classMedia[i].downloadUrl.includes('.mp4')) {
          displayCoverPic = classDetails?.classMedia[i].downloadUrl;
          break;
        }
      }
    }

    if (!displayCoverPic.includes('.mp4')) {
      return <ClassImage src={displayCoverPic} />;
    }
    return (
      <ClassVideo autoplay={true} muted={true} controls>
        <source src={displayCoverPic} type="video/mp4" />
        Unable to launch video
      </ClassVideo>
    );
  };

  return (
    <ClassDetailsContainer>
      {noClass ? (
        <NotFoundContainer>
          <NotFoundImage src={notfound} />
          <NotFount id="NO_CLASS" />
        </NotFoundContainer>
      ) : classDetails ? (
        <Border>
          <Header text={classDetails.title} />
          <DisplyCover />
          <ShareBar onClick={redirectToDownload} />
          <Details classDetails={classDetails} />
          <Button textId="JOIN_CAP" onClick={redirectToDownload} />
          <TeacherFooter teachers={classDetails.teachers} onClick={redirectToDownload} />
        </Border>
      ) : (
        <Loader />
      )}
    </ClassDetailsContainer>
  );
}

export default ClassDetails;
