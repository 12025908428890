import React, { useState } from 'react';
import { Loading, Translation, parseSavedCards } from '@modules/common/index';
import {
  AddPaymentBtn,
  StyledSavedCard,
  CardDiv,
  DownloadReceiptBtn,
  SeeAllWrapper,
  AddPaymentWrapper,
  AddPayment,
  PaymentModal,
  PopUpCard,
  Divider,
  PopUpHeader,
  CrossWrapper
} from './SavedCard.styles';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import CardPopUp from '../CardPopUp/CardPopUp';
import PaymentPopUp from '../PaymentPopUp/PaymentPopUp';

function SavedCard({ isLoading, savedCards, changeDefaultCard, deleteCard, addNewCard }) {
  //TODO: to be used later for see more/less option
  const { defaultCard, nonDefaultCards } = parseSavedCards(savedCards);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  return (
    <StyledSavedCard>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="highlight">
            <Translation id="YOUR_SAVED_CARDS" />
          </div>
          <CardDiv>
            {defaultCard ? (
              <>
                <p className="cardName">
                  <Translation text={defaultCard.name} />
                </p>
                <p className="cardNo">
                  <Translation text={defaultCard.cardNo} />
                </p>
                <p className="expiryDate">
                  <Translation id="card_exp" /> <Translation text={defaultCard.expiryDate} />
                </p>
                <p className="default">
                  <Translation id="dflt_card" />
                </p>
              </>
            ) : (
              ''
            )}
          </CardDiv>
          {savedCards.length > 1 ? (
            <SeeAllWrapper onClick={() => setIsOpenCard(true)}>
              <Translation id="SEE_ALL" />
            </SeeAllWrapper>
          ) : (
            ''
          )}

          <AddPaymentWrapper onClick={() => setIsOpenPayment(true)}>
            <AiOutlinePlus />
            <AddPayment>
              <Translation id="add_payment_method" />
            </AddPayment>
          </AddPaymentWrapper>

          <PaymentModal isOpen={isOpenPayment} setIsOpen={setIsOpenPayment}>
            <PaymentPopUp setIsOpenPayment={setIsOpenPayment} addNewCard={addNewCard} />
          </PaymentModal>
        </>
      )}
      <PaymentModal isOpen={isOpenCard} setIsOpen={setIsOpenCard}>
        <CardPopUp
          isLoading={isLoading}
          setIsOpen={setIsOpenCard}
          defaultCard={defaultCard}
          nonDefaultCards={nonDefaultCards}
          changeDefaultCard={changeDefaultCard}
          deleteCard={deleteCard}
          header_id={'your_saved_cards'}
        />
      </PaymentModal>
      {/* <DownloadReceiptBtn textId="download_receipt" /> */}
    </StyledSavedCard>
  );
}

export default SavedCard;
