import styled from 'styled-components';

export const GlobalContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    // multiplied by 1px to get correct unit
    width: calc(var(--multiplier) * var(--base-w) * 1px);
    min-height: 100vh;
    margin: auto;
    background-color: ${(props) => props.theme.background};
  }
`;
