import React from 'react';
import { textStyle, titleStyle } from '../../PrivacyPolicy.styles';

export default function InformationCollection() {
  return (
    <div>
      <p style={textStyle}>
        <strong style={titleStyle}>Information Collection and Use</strong>
      </p>
      <p style={textStyle}>
        For a better experience, while using our Service, we may require you to provide us with certain personally
        identifiable information. The information that we request will be retained by us and used as described in this
        privacy policy.
      </p>
      <div>
        <p style={textStyle}>
          The app does use third-party services that may collect information used to identify you.
        </p>
        <p style={textStyle}>Link to the privacy policy of third-party service providers used by the app</p>
        <ul>
          <li style={textStyle}>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Google Play Services
            </a>
          </li>
          <li style={textStyle}>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
