/* eslint-disable react/jsx-key */
import React from 'react';
import {
  TeacherSection,
  Title,
  TeacherContainer,
  TeacherImage,
  TeacherName,
  Experience,
  TextContainer
} from './TeacherFooter.styles';

function TeacherFooter({ teachers, onClick }) {
  if (!teachers || teachers.length < 1) {
    return null;
  }
  return (
    <TeacherSection>
      <Title id="TEACHER" />
      {teachers.map((teacher, index) => {
        const { profilePicUrl, name, experience } = teacher;
        const currentYear = new Date().getFullYear();
        const experirnceInYears = currentYear - experience;
        const experienceText = `Experience ${experirnceInYears} Years`;
        return (
          <TeacherContainer onClick={onClick} key={index}>
            <TeacherImage src={profilePicUrl} />
            <TextContainer>
              <TeacherName text={name} />
              <Experience text={experienceText} />
            </TextContainer>
          </TeacherContainer>
        );
      })}
    </TeacherSection>
  );
}

export default TeacherFooter;
