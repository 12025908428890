import React, { useState } from 'react';
import { Container, Title, Row } from './CardDetailsAndSavedCards.styles';
import { PrimaryButton, Translation } from '@modules/common/index';
import { CardDetails } from '../CardDetails/CardDetails';
import { SavedCards } from '../SavedCards/SavedCards';

export const CardDetailsAndSavedCards = ({
  loading,
  userCardDetails,
  onPayUsingSavedCard,
  onPayUsingNewCard,
  showSavedCards,
  setShowSavedCard
}) => {
  const [activeCard, setActiveCard] = useState(null);
  const [newCard, setNewCard] = useState({
    month: '',
    year: '',
    name: '',
    cardNumber: '',
    cvv: ''
  });
  const onSubmit = (e) => {
    e.preventDefault();
    if (showSavedCards) {
      onPayUsingSavedCard(activeCard?.id);
    } else {
      onPayUsingNewCard({ ...newCard });
    }
  };

  return (
    <Container>
      <Title>
        <Translation id="payment_mtd" />
      </Title>
      {showSavedCards ? (
        <SavedCards
          userCardDetails={userCardDetails}
          setActiveCard={setActiveCard}
          setShowSavedCard={setShowSavedCard}
          loading={loading}
          onSubmit={onSubmit}
        />
      ) : (
        <CardDetails setNewCard={setNewCard} newCard={newCard} loading={loading} onSubmit={onSubmit} />
      )}
    </Container>
  );
};
