/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import {
  SavedCard,
  CardDetail,
  RadioContainer,
  OuterCard,
  Text,
  ChangePaymentWrapper,
  Arrow,
  AddPaymentWrapper,
  AddPayment,
  Row,
  PayButton,
  ExpiryText,
  CardNameText,
  PlusArrow
} from './SavedCards.styles';
import { PrimaryButton, Radio, Translation, parseSavedCards } from '@modules/common/index';
import { useTheme } from 'styled-components';
let allCards = [];
let defaultCard = null;

export const SavedCards = ({ userCardDetails, setActiveCard, setShowSavedCard, loading, onSubmit }) => {
  //TODO: see if we can have only one state as activeCard and selectedCard are almost same
  const [selectedCard, setSelectedCard] = useState(null);
  const [expandCard, setExpandCard] = useState(false);
  const theme = useTheme();
  let parsedCards = parseSavedCards(userCardDetails);
  allCards = parsedCards.allCards;
  defaultCard = parsedCards.defaultCard;

  useEffect(() => {
    parsedCards = parseSavedCards(userCardDetails);
    allCards = parsedCards.allCards;
    defaultCard = parsedCards.defaultCard;
    setSelectedCard(defaultCard);
    setActiveCard(defaultCard);
  }, [userCardDetails]);

  return (
    <>
      {expandCard ? (
        <>
          <OuterCard
            onClick={() => {
              setSelectedCard(defaultCard);
              setActiveCard(defaultCard);
            }}
            key={defaultCard.id}
          >
            <RadioContainer>
              <Radio selected={defaultCard.id === selectedCard?.id} backgroundColor={theme.white} />
            </RadioContainer>
            <div>
              <SavedCard>
                <CardDetail>
                  <Text>
                    <Translation id="card_name" />
                  </Text>
                  <Text>
                    <Translation text={defaultCard.name} />
                  </Text>
                </CardDetail>
                <CardDetail>
                  <Translation text={defaultCard.cardNo} />
                </CardDetail>
                <CardDetail>
                  <Text>
                    <Translation id="card_exp" />
                  </Text>
                  <Text>
                    <Translation text={defaultCard.expiryDate} />
                  </Text>
                </CardDetail>
                <CardDetail>{defaultCard.isDefault ? <Translation id="dflt_card" /> : ''}</CardDetail>
              </SavedCard>
            </div>
          </OuterCard>

          {allCards.map((card) => {
            if (card.id !== defaultCard.id) {
              return (
                <OuterCard
                  onClick={() => {
                    setSelectedCard(card);
                    setActiveCard(card);
                  }}
                  key={card.id}
                >
                  <div>
                    <RadioContainer>
                      <Radio selected={card.id === selectedCard?.id} backgroundColor={theme.white} />
                    </RadioContainer>
                  </div>
                  <div>
                    <SavedCard>
                      <CardDetail>
                        <Text>
                          <Translation id="card_name" />
                        </Text>
                        <Text>
                          <Translation text={card.name} />
                        </Text>
                      </CardDetail>
                      <CardDetail>
                        <Translation text={card.cardNo} />
                      </CardDetail>
                      <CardDetail>
                        <Text>
                          <Translation id="card_exp" />
                        </Text>
                        <Text>
                          <Translation text={card.expiryDate} />
                        </Text>
                      </CardDetail>
                      <CardDetail>{card.isDefault ? <Translation id="dflt_card" /> : ''}</CardDetail>
                    </SavedCard>
                  </div>
                </OuterCard>
              );
            }
          })}
        </>
      ) : (
        <OuterCard
          onClick={() => {
            setSelectedCard(defaultCard);
            setActiveCard(defaultCard);
          }}
          key={defaultCard.id}
        >
          <div>
            <RadioContainer>
              <Radio selected={defaultCard.id === selectedCard?.id} backgroundColor={theme.white} />
            </RadioContainer>
          </div>
          <div>
            <SavedCard>
              <CardDetail>
                <Text>
                  <Translation id="card_name" />
                </Text>
                <Text>
                  <Translation text={defaultCard.name} />
                </Text>
              </CardDetail>
              <CardDetail>
                <Translation text={defaultCard.cardNo} />
              </CardDetail>
              <CardDetail>
                <Text>
                  <Translation id="card_exp" />
                </Text>
                <Text>
                  <Translation text={defaultCard.expiryDate} />
                </Text>
              </CardDetail>
              <CardDetail>{defaultCard.isDefault ? <Translation id="dflt_card" /> : ''}</CardDetail>
            </SavedCard>
          </div>
        </OuterCard>
      )}

      {expandCard ? (
        <AddPaymentWrapper onClick={() => setShowSavedCard((prevShowSavedCards) => !prevShowSavedCards)}>
          <PlusArrow />
          <AddPayment>
            <Translation id="ADD_PAYMENT" />
          </AddPayment>
        </AddPaymentWrapper>
      ) : (
        ''
      )}
      <ChangePaymentWrapper
        onClick={() => {
          setExpandCard(!expandCard);
        }}
      >
        <Translation id="chng_payment_mtd" />
        <Arrow expandCard={expandCard} />
      </ChangePaymentWrapper>
      <Row>
        <div />
        {loading ? <PayButton textId="pay" disabled /> : <PayButton textId="pay" onClick={(e) => onSubmit(e)} />}
      </Row>
    </>
  );
};
