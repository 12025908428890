import React from 'react';
import { StyledNoSubscriptionCard } from './NoSubscriptionCard.styles';
import { Translation } from '@app/modules/common/index';

function NoSubscriptionCard() {
  return (
    <StyledNoSubscriptionCard>
      <div className="link">
        <Translation id="PROVIDE_CARD_DETAILS" />
      </div>
    </StyledNoSubscriptionCard>
  );
}

export default NoSubscriptionCard;
