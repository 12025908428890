/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */
const White = '#ffffff';
const Black = '#000000';
const AntiqueWhite = '#fcedda';
const WaxFlowerWhite = '#f8c49c';
const BattleshipGrey = '#818181';
const Dark = '#212529';
const JungleGreenDark = '#1d2327';
const MediumGreen = '#28a745';
const JasperRed = '#dc3545';
const AzureBlue = '#1890ff';
const BoulderGrey = '#777777';
const LightPink = '#ed77a2';
const MediumPink = '#ec6798';
const Transparent = 'rgba(0, 0, 0, 0)';
const Transparent13 = 'rgba(255, 255, 255, 0.13)';
const Orange = '#ff7a00';
const CloudyGrey = '#6a6a6a';
const SilverChalice = '#ababab';
const VampireGrey = '#535352';
const Cloud = '#c9c5b8';
const SatinLinen = '#e8e4dA';
const WarmGrey = '#959082';
const RiverBed = '#434B50';
const Hazel = '#977721';
const Turmeric = '#dab745';
const YellowyBrown = '#b38c08';
const BrownBear = '#7B5531';
const ClayBrown = '#B47640';
const MilkChocolate = '#7B4F27';
const RedError = '#FF9494';
const InputBorder = '#e0e2e9';
const GreyText = '#969ab8';
const OceanBlue = '#023E9D';
const SkyBlue = '#68BBE5';
const Bblue = '#1575C1';

module.exports = {
  OceanBlue,
  SkyBlue,
  Bblue,
  RedError,
  MilkChocolate,
  ClayBrown,
  BrownBear,
  Hazel,
  Turmeric,
  YellowyBrown,
  RiverBed,
  SatinLinen,
  WarmGrey,
  CloudyGrey,
  SilverChalice,
  VampireGrey,
  Orange,
  White,
  Black,
  AntiqueWhite,
  WaxFlowerWhite,
  BattleshipGrey,
  Dark,
  JungleGreenDark,
  Cloud,
  MediumGreen,
  JasperRed,
  AzureBlue,
  BoulderGrey,
  LightPink,
  MediumPink,
  Transparent,
  Transparent13,
  InputBorder,
  GreyText
};
