import React, { useState } from 'react';
import { HTMLInput, InputContainer, Adornment, Error } from './AdornmentInput.styles';

export const AdornmentInput = (props) => {
  const { value, onChange, adornment, placeholder, className, ref, error, ...rest } = props;
  const [valueState, setValueState] = useState('');
  return (
    <>
      <InputContainer className={className} {...props}>
        <Adornment className="adornment">{adornment}</Adornment>
        <HTMLInput
          ref={ref}
          placeholder={placeholder}
          className="input"
          value={value ? value : valueState}
          onChange={onChange ? onChange : (e) => setValueState(e.target.value)}
          {...rest}
        />
      </InputContainer>
      <Error>{error}</Error>
    </>
  );
};
