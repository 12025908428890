import styled from 'styled-components';
import { media } from '@app/themes/index';

export const Adornment = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
export const InputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 0.92rem;
  width: 100%;
  min-width: 20rem;
  height: 3rem;
  padding-left: 0.81rem;
  margin-top: 0.38rem;
  background-color: ${(props) => props.theme.transparent13};
  border: 0.0625rem solid ${(props) => props.theme.inputBorder};
  border-radius: 0.625rem;
  ${media.lessThan('tablet')`
    min-width: 19rem;
  `}
  &:focus-within {
    border: 0.0625rem solid ${(props) => props.theme.highlight};
  }
  &:focus-within ${Adornment} {
    color: ${(props) => props.theme.highlight};
  }
`;
export const HTMLInput = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.62rem 0.81rem 0.62rem 0;
  /* color: ${(props) => props.theme.textPrimary}; */
  background-color: ${(props) => props.theme.transparent13};
  font-size: 1.125rem;
  border: 0;
  &:focus {
    outline: none;
  }
`;
export const Error = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.error};
  padding: 0.2rem 0.81rem 0.2rem 0.81rem;
`;
