import styled from 'styled-components';
import { Translation } from '@modules/common/index';

export const StyledWhiteT = styled(Translation)`
  color: white;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 5%;
`;

export const ClassCard = styled.div`
  min-width: 25.0625rem;
  height: 18.375rem;
  background-color: white;
  margin-right: 2rem;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    transition: background-color 0.3s, box-shadow 0.3s;
    pointer-events: none;
  }

  :hover {
    ::before {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.2);
    }
    transform: scale(1.05);
  }
`;

export const BelowImageContainer = styled.div`
  padding: 0.3125rem 0.625rem;
  display: flex;
  flex-direction: column;
`;

export const ClassTitle = styled.p`
  text-align: left;
  color: ${(props) => props.theme.black};
  text-transform: capitalize;
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
`;

export const CommunityClassContainer = styled.div`
  display: flex;
  padding: 0.25rem;
  position: absolute;
  justify-content: center;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: ${(props) => props.theme.orange};
`;

export const CommunityClassTag = styled.p`
  color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  font-weight: 700;
`;

export const Teacher = styled.p`
  text-align: left;
  color: ${(props) => props.theme.textMuted};
  text-transform: capitalize;
  flex: 2;
  font-size: 0.9375rem;
  font-weight: 300;
  max-lines: 1;
`;

export const LanguageAndTimeContainer = styled.p`
  text-align: right;
  color: ${(props) => props.theme.textMuted};
  flex: 1;
  font-size: 0.8125rem;
  font-weight: 400;
`;

export const StyledClassAverageDuration = styled.p`
  text-align: right;
  color: ${(props) => props.theme.highlight};
  opacity: 1;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: normal;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.375rem;
`;

export const ClassTiming = styled.p`
  text-align: left;
  color: ${(props) => props.theme.textMuted};
  flex: 1;
  font-weight: 700;
  font-size: 0.8125rem;
`;

export const ClassImage = styled.img`
  height: 12.25rem;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;

export const ClassVideo = styled.video`
  height: 12.25rem;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;

export const FirstCard = styled(ClassCard)`
  margin-left: 5%;
`;

export const LastCard = styled(ClassCard)`
  margin-right: 5%;
`;

export const ClassListContainer = styled.div`
  flex: 1;
  display: flex;
  overflow-x: auto;
  padding: 1rem 0;
  ::-webkit-scrollbar-track {
    margin-left: 5rem;
    margin-right: 5rem;
  }
`;

export const OuterContainer = styled.div``;
