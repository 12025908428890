import { logToCrashalytics, recordErrorInCrashalytics } from 'tatvarnsdk/Web';
export const getCardNumber = (lastFourDigits) => '*'.repeat(12) + lastFourDigits;

export const parseSavedCards = (savedCards) => {
  logToCrashalytics('parseSavedCards => modules/common/utils');
  try {
    if (savedCards) {
      let defaultCard = null;
      let allCards = savedCards.map((paymentObj, index) => {
        const cardNo = getCardNumber(paymentObj.last4);
        const expiryDate = `${paymentObj.expiry_month}/${paymentObj.expiry_year}`;
        const name = paymentObj.name;
        if (
          paymentObj.isDefault == true ||
          savedCards.length === 1 ||
          (index === savedCards.length - 1 && defaultCard === null)
        ) {
          defaultCard = {
            cardNo,
            expiryDate,
            name,
            ...paymentObj
          };
        }
        return { cardNo, expiryDate, name, ...paymentObj };
      });
      const nonDefaultCards = allCards.filter((paymentObj) => {
        if (paymentObj.isDefault == true) {
          return false;
        }
        return true;
      });
      return { defaultCard, nonDefaultCards, allCards };
    }
  } catch (e) {
    recordErrorInCrashalytics('parseSavedCards => modules/common/utils', e);
    return {
      defaultCard: null,
      nonDefaultCards: [],
      allCards: []
    };
  }
};
