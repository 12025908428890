import React from 'react';
import { textStyle, titleStyle } from '../../PrivacyPolicy.styles';

export default function Others() {
  return (
    <div>
      <p style={textStyle}>
        <strong style={titleStyle}>Links to Other Sites</strong>
      </p>
      <p style={textStyle}>
        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that
        site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the
        Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy
        policies, or practices of any third-party sites or services.
      </p>
      <p style={textStyle}>
        <strong style={titleStyle}>Children Privacy</strong>
      </p>
      <div>
        <p style={textStyle}>
          These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from children under 13 years of age. In the case we discover that a child under 13 has provided us
          with personal information, we immediately delete this from our servers. If you are a parent or guardian and
          you are aware that your child has provided us with personal information, please contact us so that we will be
          able to do the necessary actions.
        </p>
      </div>
      <p style={textStyle}>
        <strong style={titleStyle}>Changes to This Privacy Policy</strong>
      </p>
      <p style={textStyle}>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for
        any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      <p style={textStyle}>This policy is effective as of 2023-06-21</p>
      <p style={textStyle}>
        <strong style={titleStyle}>Contact Us</strong>
      </p>
      <p style={{ ...textStyle, marginBottom: 20 }}>
        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contsact us at
        info@tatva.app.
      </p>
    </div>
  );
}
