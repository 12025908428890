import styled from 'styled-components';
import { Modal, StyledContainer } from '../common/index';

export const DashboardContainer = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  gap: 4.81rem;
  width: 100%;
  margin: 0;
  margin-top: 3.63rem;
  overflow: hidden;
  /* flex-grow: 1; */
`;
export const StyledDashboard = styled.div`
  flex-grow: 1;
  display: flex;
`;
export const FailedModal = styled(Modal)`
  /* background-color: none; */
`;
