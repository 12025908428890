import React from 'react';
import {
  AlertContainer,
  AlertIcon,
  AlertText,
  BaseMessage,
  BtnContainer,
  Button,
  HeaderMessage
} from './CustomAlert.styles';
import AlertCross from '../images/AlertCross.svg';
import { Illustrations } from '../Illustrations/Illustrations';
import { Translation } from '@modules/common/index';

export const CustomAlert = (props) => {
  const { iconSrc, titleId, messageId, type, title, message, buttonTextId, buttonText = 'Click', action } = props;
  return (
    <AlertContainer>
      {iconSrc ? <AlertIcon src={iconSrc} /> : ''}
      <AlertText>
        <HeaderMessage>
          <Translation id={titleId} text={title} />
        </HeaderMessage>
        <BaseMessage>
          <Translation id={messageId} text={message} />
        </BaseMessage>
      </AlertText>
      <BtnContainer>
        {action ? (
          <Button id={buttonTextId} text={buttonText} onClick={action} />
        ) : (
          <Illustrations image={AlertCross} />
        )}
      </BtnContainer>
    </AlertContainer>
  );
};
