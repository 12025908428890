import { put, takeLatest, select } from 'redux-saga/effects';
import { subscriptionTypes, subscriptionCreators } from './reducer';
import {
  recordErrorInCrashalytics,
  logToCrashalytics,
  setDefaultPayment,
  listAllCards,
  addACard,
  deleteACard,
  getPlansUserProfileAndUserCardDetails,
  applyStripeCoupon,
  NOTIFICATION_TYPE,
  retryFailedPaymentForUser,
  deleteQrDocWithToken
} from 'tatvarnsdk/Web';
import { selectUserProfile } from './selectors';
import { showToast } from '../common/index';
const {
  REQUEST_DETAILS,
  CHANGE_DEFAULT_CARD,
  ADD_CARD_REQUEST,
  DELETE_CARD_REQUEST,
  APPLY_COUPON,
  RESET_COUPON,
  RETRY_CARD_REQUEST,
  DELETE_DOC_TOKEN_SUB
} = subscriptionTypes;
const {
  plansSuccess,
  plansFails,
  changeDefaultCardSuccess,
  changeDefaultCardError,
  deleteCardSuccess,
  deleteCardError,
  retryCardSuccess,
  retryCardError,
  addCardSuccess,
  addCardError,
  couponSuccess,
  couponFail,
  resetCouponSuccess
} = subscriptionCreators;
export function* getPlansSubscriptionsAndCardDetails() {
  logToCrashalytics('getPlansSubscriptionsAndCardDetails => Subscription/Saga');
  try {
    const response = yield getPlansUserProfileAndUserCardDetails();
    if (!response.success) {
      throw response.error;
    }
    const { disciplineList, purchasePlans, userDetails, userPaymentMethods, userPlanDescription } = response.data;
    // user subscription
    const userSubscription =
      userDetails && userDetails.subscription
        ? {
            ...userDetails.subscription,
            classPassCount: userDetails.classPassCount,
            joinedClasses: userDetails.joinedClasses
          }
        : null;
    yield put(
      plansSuccess(
        purchasePlans,
        userSubscription,
        userPlanDescription,
        userPaymentMethods,
        userDetails,
        disciplineList
      )
    );
  } catch (e) {
    recordErrorInCrashalytics(e);
    showToast({
      title: 'OOPS!',
      message: 'something went wrong',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(plansFails('Subscription saga getPlansSubscriptionsAndCardDetails', e));
  }
}

export function* onChangeDefaultCard({ cardId, customerId }) {
  logToCrashalytics('onChangeDefaultCard => Subscription/Saga.js');
  try {
    const response = yield setDefaultPayment(cardId, customerId);
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error Adding Card';
    }
    const { payments } = response.data;
    yield put(changeDefaultCardSuccess(payments));
    showToast({
      title: 'Awesome!',
      message: 'Default card has been changed',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
  } catch (err) {
    recordErrorInCrashalytics('my subscription saga onChangeDefaultCard', err);
    showToast({
      title: 'OOPS!',
      message: 'Default card not changed',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(changeDefaultCardError(err));
  }
}

export function* onAddCard({ newCardDetails, customerId }) {
  logToCrashalytics('onAddCard => Subscription/Saga.js');
  const { cardNumber, month, year, cvv, name, setAsDefault } = newCardDetails;
  try {
    const response = yield addACard(cardNumber, month, year, cvv, name, setAsDefault);
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error Adding Card';
    }
    const { payments } = response.data;
    yield put(addCardSuccess(payments));
    showToast({
      title: 'Success!',
      message: 'New card has been added',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
  } catch (err) {
    recordErrorInCrashalytics('my subscription saga onAddCard', err);
    showToast({
      title: 'OOPS!',
      message: err.message,
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(addCardError(err));
  }
}

export function* onDeleteCard({ cardId, customerId }) {
  logToCrashalytics('onDeleteCard => Subscription/Saga.js');
  try {
    const response = yield deleteACard(cardId, customerId);
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error Deleting Card';
    }
    const { payments } = response.data;
    yield put(deleteCardSuccess(payments));
    showToast({
      title: 'Done!',
      message: 'Card has been deleted',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
  } catch (err) {
    recordErrorInCrashalytics('my subscription saga onDeleteCard', err);
    showToast({
      title: 'OOPS!',
      message: 'something went wrong while deleting card',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(deleteCardError(err));
  }
}

export function* onApplyCoupon(props) {
  logToCrashalytics('onApplyCoupon => Subscription/Saga.js');
  const { couponCode, plans, userProfile } = props;
  try {
    logToCrashalytics('Subscription Saga onApplyCoupon');
    const response = yield applyStripeCoupon(couponCode, plans, userProfile);
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error applying coupon';
    }
    yield put(couponSuccess(response.data.coupon, response.data.plans));
    showToast({
      title: 'Great!',
      message: 'Coupon has been applied',
      notificationType: NOTIFICATION_TYPE.SUCCESS
    });
  } catch (e) {
    recordErrorInCrashalytics('Subscription Saga onApplyCoupon', e);
    showToast({
      title: 'OOPS!',
      message: 'Invalid coupon code',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(couponFail(e));
  }
}

export function* onRemoveCoupon(props) {
  logToCrashalytics('onRemoveCoupon => Subscription/Saga.js');
  const { planData } = props;
  try {
    const newPlanData = planData.map((plan) => {
      let newPlanPrices = { ...plan };
      newPlanPrices.discount = null;
      newPlanPrices.discountedPrice = null;
      newPlanPrices.couponMessage = null;
      return newPlanPrices;
    });
    yield put(resetCouponSuccess(newPlanData));
    showToast({
      title: 'Done!',
      message: 'Coupon has been removed',
      notificationType: NOTIFICATION_TYPE.DEFAULT
    });
  } catch (e) {
    recordErrorInCrashalytics('Subscription Saga onRemoveCoupon', e);
  }
}

export function* retryFailedPayment(action) {
  logToCrashalytics('retryFailedPayment => Subscription/Saga.js');
  const { alert, cardId, customerId } = action;
  let alertId = null;
  if (alert) {
    alertId = alert.id;
  }
  try {
    if (!cardId) {
      showToast({
        title: 'No Card Selected',
        message: 'Make sure your card is valid, having sufficient balance.',
        notificationType: NOTIFICATION_TYPE.ERROR
      });
    } else {
      const response = yield retryFailedPaymentForUser(cardId, customerId, alertId);
      if (!response || !response.success || !response.data) {
        throw response?.error ?? 'Error Retrying Failed Payment';
      }
      const { paid } = response.data;
      if (paid) {
        let allCardDetails = [];
        const allCardResponse = yield listAllCards();
        if (allCardResponse?.success && allCardResponse?.data) {
          const { payments } = allCardResponse.data;
          allCardDetails = [...payments];
        }
        const userDetails = yield select(selectUserProfile());
        const userSubscription = userDetails?.subscription
          ? {
              ...userDetails?.subscription,
              classPassCount: userDetails?.classPassCount,
              status: 'active'
            }
          : null;
        showToast({
          title: 'Awesome!',
          message: 'Your payment was successful.',
          notificationType: NOTIFICATION_TYPE.SUCCESS
        });
        yield put(retryCardSuccess(allCardDetails, userSubscription));
      } else {
        showToast({
          title: 'Success',
          message: 'Payment Initiated Successfully, please check after 5 min.',
          notificationType: NOTIFICATION_TYPE.DEFAULT
        });
      }
    }
  } catch (err) {
    recordErrorInCrashalytics('retryFailedPayment', err);
    showToast({
      title: 'Error In Retrying Payment',
      message: 'Make sure your card is valid, having sufficient balance.',
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(retryCardError(err));
  }
}

export function* deleteDocTokenSub(props) {
  const { docId } = props;
  try {
    logToCrashalytics('deleteDocTokenSub => Subscription/Saga.js');
    yield deleteQrDocWithToken(docId);
  } catch (e) {
    recordErrorInCrashalytics('deleteDocTokenSub => Subscription/Saga.js', e);
  }
}

export default function* subscriptionSaga() {
  yield takeLatest(REQUEST_DETAILS, getPlansSubscriptionsAndCardDetails);
  yield takeLatest(CHANGE_DEFAULT_CARD, onChangeDefaultCard);
  yield takeLatest(APPLY_COUPON, onApplyCoupon);
  yield takeLatest(RESET_COUPON, onRemoveCoupon);
  yield takeLatest(ADD_CARD_REQUEST, onAddCard);
  yield takeLatest(DELETE_CARD_REQUEST, onDeleteCard);
  yield takeLatest(RETRY_CARD_REQUEST, retryFailedPayment);
  yield takeLatest(DELETE_DOC_TOKEN_SUB, deleteDocTokenSub);
}
