import styled from 'styled-components';

export const StyledProfileImage = styled.img`
  border-radius: 1.875rem;
  /* width: 3.75rem; */
  height: 3.75rem;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.white};
  cursor: pointer;
`;
