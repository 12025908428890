import React from 'react';
import {
  StyledPausedSubscriptionCard,
  PausedSubscriptionCardTitle,
  StyledWhiteBackground,
  StyledRightBackground,
  SubscriptionInfoGrid
} from './PausedSubscriptionCard.styled';
import { Translation } from '../../../Translation/Translation';

function PausedSubscriptionCard({ leftColor, leftBackground, title, topLeft, topRight, bottomLeft, bottomRight }) {
  return (
    <StyledPausedSubscriptionCard leftColor={leftColor} leftBackground={leftBackground}>
      <StyledWhiteBackground />
      <StyledRightBackground />
      <PausedSubscriptionCardTitle>
        <div>{title}</div>
        <div className="colorRight">
          <Translation id="BRONZE" />
        </div>
      </PausedSubscriptionCardTitle>
      <SubscriptionInfoGrid>
        <div>
          <p className={`label ${topLeft.title.bold ? 'bold' : ''}`}>{topLeft.title.text}</p>
          <p className={`value ${topLeft.value.bold ? 'bold' : ''}`}>{topLeft.value.text}</p>
        </div>
        <div className="textRight colorRight">
          <p className={`label ${topRight.title.bold ? 'bold' : ''}`}>{topRight.title.text}</p>
          <p className={`label ${topRight.value.bold ? 'bold' : ''}`}>{topRight.value.text}</p>
        </div>
        <div>
          <p className={`label ${bottomLeft.title.bold ? 'bold' : ''}`}>{bottomLeft.title.text}</p>
          <p className={`label ${bottomLeft.value.bold ? 'bold' : ''}`}>{bottomLeft.value.text}</p>
        </div>
        <div className="textRight colorRight">
          <p className={`label ${bottomRight.title.bold ? 'bold' : ''}`}>{bottomRight.title.text}</p>
          <p className={`label ${bottomLeft.value.bold ? 'bold' : ''}`}>{bottomRight.value.text}</p>
        </div>
      </SubscriptionInfoGrid>
    </StyledPausedSubscriptionCard>
  );
}

export default PausedSubscriptionCard;
