/* eslint-disable curly */
/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
//TODO: Do we need to change the poisiton of getTitleAndInfo to common/utils? as its being used by others too
import { getTitleAndInfo } from '../index';
import { logToCrashalytics, recordErrorInCrashalytics, isClassPass, isSilver, isBronze, isGold, isPlatinum } from 'tatvarnsdk/Web';
import { ELLIGIBLE_CLASSES_FOR_PLANS } from './constants';

const getPlanName = (planDetails) => {
  logToCrashalytics('getPlanName => modules/common/utils');
  if (isSilver(planDetails)) {
    return 'Silver';
  } else if (isBronze(planDetails)) {
    return 'Bronze';
  } else if (isGold(planDetails)) {
    return 'Gold';
  } else if (isPlatinum(planDetails)) {
    return 'Platinum';
  }
  return 'Default';
};

const getDetailedClassesCount = (totalScheduledClasses, mySubData) => {
  try {
    logToCrashalytics('getDetailedClassesCount => modules/common/utils/planutils');
    if (!totalScheduledClasses || totalScheduledClasses.length === 0) {
      throw 'No Sessions';
    }
    const startDate = new Date(mySubData.startDate._seconds * 1000);
    const endDate = new Date(mySubData.endDate._seconds * 1000);
    let joinedClasses = 0;
    let futureScheduledClasses = 0;
    totalScheduledClasses.forEach((session) => {
      const classDate = new Date(session.timeStamp._seconds * 1000);
      if (classDate < endDate && classDate > startDate && !session.isCommunityClass) {
        if (session.status === 'joined') {
          if (session.classPass) {} else {
            joinedClasses++;
          }
        } else if (classDate > new Date() && session.status === 'scheduled') {
          futureScheduledClasses++;
        }
      }
    });
    return {
      joinedClasses: joinedClasses,
      futureScheduledClasses: futureScheduledClasses
    };
  } catch (e) {
    recordErrorInCrashalytics('getDetailedClassesCount => modules/common/utils/planutils', e);
    return {
      joinedClasses: 0,
      futureScheduledClasses: 0
    };
  }
};

const getPlanPriceSubtitle = (planDetails) => {
  logToCrashalytics('getPlanPriceSubtitle => modules/common/utils');
  let planPriceSubtitle = planDetails.metadata?.Sub_Title;
  return planPriceSubtitle ? ' / ' + planPriceSubtitle : '';
};
const getDescriptionFromPlan = (planDetails) => {
  logToCrashalytics('getDescriptionFromPlan => modules/common/utils');
  return planDetails.description.split('#').map((elem) => elem.trim());
};

const getDescriptionFromSubscribedPlan = (plan, allPlan) => {
  logToCrashalytics('getDescriptionFromSubscribedPlan => modules/common/utils');
  const title = getTitleAndInfo(plan);
  let description;
  allPlan.forEach((value, key) => {
    const up_key = key.toUpperCase().split(' ').join('');
    if (up_key === title.title) {
      description = value.split('#').map((elem) => elem.trim());
    }
  });
  return description;
};

const getDisplayedCouponPrice = (actualCreditAmount, coupon, subscription_amt) => {
  logToCrashalytics('getDisplayedCouponPrice => modules/common/utils');
  try {
    if(!coupon) return actualCreditAmount;
    const discountedPrice = coupon.discount_amt;
    if (subscription_amt - discountedPrice - actualCreditAmount < 0) {
      return 0;
    }
    return actualCreditAmount;
  } catch (e) {
    recordErrorInCrashalytics('getDisplayedCouponPrice => modules/common/utils/planutils',e);
    return actualCreditAmount;
  }
}

export {
  getPlanName,
  getDetailedClassesCount,
  getDescriptionFromPlan,
  getPlanPriceSubtitle,
  getDisplayedCouponPrice,
  getDescriptionFromSubscribedPlan
};
