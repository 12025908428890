import React, { useState, useEffect } from 'react';
import {
  CardBody,
  CardContainer,
  CardDiv,
  Cross,
  CrossWrapper,
  Divider,
  LoaderContainer,
  PopUpCard,
  PopUpHeader,
  RadioContainer,
  RetryButton,
  TrashBin
} from './CardPopUp.styles';
import { Loading, Radio, Translation } from '@modules/common/index';
import { useTheme } from 'styled-components';

export default function CardPopUp({
  isLoading,
  isOpen,
  setIsOpen,
  defaultCard,
  nonDefaultCards,
  changeDefaultCard,
  deleteCard,
  header_id,
  retryCard,
  retryPaymentInitiated
}) {
  const theme = useTheme();
  const [selected, setSelected] = useState(null);
  const onCardDeleted = (cardId) => {
    deleteCard(cardId);
  };
  const deleteClick = (cardId) => {
    const result = window.confirm('Do you want to delete this card?');
    if (result) {
      onCardDeleted(cardId);
    }
  };
  const onDefaultCardChanged = (cardId) => {
    changeDefaultCard(cardId);
  };
  const onRetryCard = () => {
    const result = window.confirm('Do you want to retry this card?');
    if (result) {
      retryCard(selected);
    }
  };
  useEffect(() => {
    setSelected(defaultCard?.id);
  }, [defaultCard?.id]);
  useEffect(() => {
    setIsOpen(false);
  }, [retryPaymentInitiated]);

  return (
    <>
      {isLoading ? (
        <LoaderContainer>
          <Loading />
        </LoaderContainer>
      ) : (
        <PopUpCard>
          <PopUpHeader>
            <CrossWrapper
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Cross />
            </CrossWrapper>
            <div className="highlight">
              <Translation id={header_id} />
            </div>
          </PopUpHeader>
          <CardBody>
            {defaultCard && (
              <CardContainer>
                <RadioContainer
                  onClick={() => {
                    setSelected(defaultCard.id);
                  }}
                >
                  <Radio selected={selected === defaultCard.id} backgroundColor={theme.background} />
                </RadioContainer>
                <CardDiv>
                  <p className="cardName">
                    <Translation text={defaultCard.name} />
                  </p>
                  <p className="cardNo">
                    <Translation text={defaultCard.cardNo} />
                  </p>
                  <p className="expiryDate">
                    <Translation id={'expires_on'} />
                    <Translation text={defaultCard.expiryDate} />
                  </p>
                  <p>
                    <Translation id={'default_card'} />
                  </p>
                </CardDiv>
              </CardContainer>
            )}
            {nonDefaultCards.map(
              (eachCard) =>
                eachCard.cardNo && (
                  <div key={eachCard.id}>
                    <Divider />
                    <CardContainer>
                      <RadioContainer
                        onClick={() => {
                          setSelected(eachCard.id);
                          !isOpen ? onDefaultCardChanged(eachCard.id) : '';
                        }}
                      >
                        <Radio selected={eachCard.id === selected} backgroundColor={theme.background} />
                      </RadioContainer>
                      <CardDiv>
                        <p className="cardName">
                          <Translation text={eachCard.name} />
                        </p>
                        <p className="cardNo">
                          <Translation text={eachCard.cardNo} />
                        </p>
                        <p className="expiryDate">
                          <Translation id={'expires_on'} /> <Translation text={eachCard.expiryDate} />
                        </p>
                      </CardDiv>
                      {!isOpen && (
                        <TrashBin
                          onClick={() => {
                            deleteClick(eachCard.id);
                          }}
                        />
                      )}
                    </CardContainer>
                  </div>
                )
            )}
          </CardBody>
          {!isLoading && isOpen && !retryPaymentInitiated && <RetryButton textId="retry_now" onClick={onRetryCard} />}
        </PopUpCard>
      )}
    </>
  );
}
