import React, { memo, useEffect, useState } from 'react';
import { StyledHeaderContainer } from './Header.styles';
import { Translation, Row } from '@app/modules/common/index';

function TitleDiv({ text }) {
  return (
    <StyledHeaderContainer>
      <Translation text={text} />
    </StyledHeaderContainer>
  );
}

export default TitleDiv;
