import { PrimaryButton, StyledContainer } from '@app/modules/common/index';
import styled from 'styled-components';
import { media } from '@app/themes/index';

export const HeaderWrapper = styled.div`
  /* width: 12.5rem; */
  height: 4rem;
  flex-shrink: 0;
  cursor: pointer;
  ${media.lessThan('tablet')`
  /* width: 6.5rem; */
  /* height: 2.5rem; */
  `}
`;
