import moment from 'moment';
import { isClassPass } from 'tatvarnsdk/Web';

//TODO: is this the correct place for this function?
const calculatePurchaseAndRenewalDates = (months) => {
  let today = moment().format('DD-MM-YYYY');
  let renewalDate = moment().add(months, 'month').format('DD-MM-YYYY');
  return { subscribedDate: today, renewalDate };
};
const getPlanDates = (plan) => {
  const subscribedDate = moment.unix(plan?.startDate).format('DD.MM.YYYY');
  const endDate = moment.unix(plan?.endDate).format('DD.MM.YYYY');
  const nextRenewDate = moment.unix(plan?.endDate).format('DD.MM.YYYY');
  // const lastRenewDate = moment.unix(plan?.endDate).format('DD.MM.YYYY');
  const pausedOnDate = moment.unix(plan?.pauseOn).format('DD.MM.YYYY');
  const resumeOnDate = moment.unix(plan?.resumeOn).format('DD.MM.YYYY');
  return {
    subscribedDate,
    endDate,
    nextRenewDate,
    pausedOnDate,
    resumeOnDate
  };
};

export const getTitleAndInfo = (plan) => {
  const [title, info] =
    plan.name
      ?.toUpperCase()
      .split('-')
      .map((element) => element.trim()) ||
    plan.plan
      ?.toUpperCase()
      .split('-')
      .map((element) => element.trim());
  return {
    title,
    info
  };
};

export const cardInfoToDisplay = (plan, type, theme) => {
  const backgroundColors = {
    blue: theme.blueCardBackground,
    bronze: theme.bronzeCardBackground,
    silver: theme.silverCardBackground,
    gold: theme.goldCardBackground,
    platinum: theme.platinumCardBackground
  };
  const card = {
    title: '',
    topLeft: {
      title: { text: '', bold: false },
      value: { text: '', bold: true }
    },
    topRight: {
      title: { text: '', bold: false },
      value: { text: '', bold: true }
    },
    bottomLeft: {
      title: { text: '', bold: false },
      value: { text: '', bold: true }
    },
    bottomRight: {
      title: { text: '', bold: true },
      value: { text: '', bold: true }
    },
    color: 'white',
    background: 'black'
  };
  if (type === 'UPGRADE_SUBSCRIPTION') {
    const { title, info } = getTitleAndInfo(plan);
    let { subscribedDate, renewalDate } =
      plan.type === 'one_time' ? calculatePurchaseAndRenewalDates(6) : calculatePurchaseAndRenewalDates(1);
    card.title = title;
    switch (title.toLowerCase()) {
      case 'blue':
        card.background = backgroundColors.bronze;
        card.bottomLeft.title.text = 'Subscribe on';
        card.bottomLeft.value.text = subscribedDate;
        card.bottomRight.title.text = info.split(' ')[0];
        card.bottomRight.value.text = info.split(' ')[1];
        break;
      case 'bronze':
        card.background = backgroundColors.bronze;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        card.bottomLeft.title.text = 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        break;
      case 'silver':
        card.background = backgroundColors.silver;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        card.bottomLeft.title.text = 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        break;
      case 'gold':
        card.background = backgroundColors.gold;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        card.bottomLeft.title.text = 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.bottomRight.title.text = info.split(' ')[0] + ' ' + info.split(' ')[1];
        card.bottomRight.value.text = info.split(' ')[2];
        break;
      case 'platinum':
        card.background = backgroundColors.platinum;
        card.color = theme.platinumCardText;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        card.bottomLeft.title.text = 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.bottomRight.title.text = info.split(' ')[0];
        card.bottomRight.value.text = info.split(' ')[1];
        break;
      case 'class pass':
        if (info === 'BLUE') {
          card.background = backgroundColors.blue;
        } else if (info === 'SILVER') {
          card.background = backgroundColors.silver;
        } else if (info === 'GOLD') {
          card.background = backgroundColors.gold;
        } else if (info === 'PLATINUM') {
          card.background = backgroundColors.platinum;
        } else {
          card.background = backgroundColors.bronze;
        }
        card.bottomLeft.title.text = 'Valid till';
        card.bottomLeft.value.text = renewalDate;
        //TODO: can plan to get these from backend instead of hardcoding
        card.bottomRight.title.text = '01';
        card.bottomRight.value.text = 'Class';
        break;
    }
    return card;
  } else if (type === 'CURRENT_SUBSCRIPTION') {
    const { title, info } = getTitleAndInfo(plan);
    const { subscribedDate, endDate, nextRenewDate } = getPlanDates(plan);
    //TODO: this to be a calculated field
    card.title = title;
    const noOfClasses = plan.joinedClasses || plan.joinedClasses === 0 ? 4 - plan.joinedClasses : null;
    switch (title.toLowerCase()) {
      case 'blue':
        card.background = theme.blueCardBackground;
        card.bottomLeft.title.text = info.split(' ')[0];
        card.bottomLeft.title.bold = true;
        card.bottomLeft.value.text = info.split(' ')[1];
        card.bottomLeft.value.bold = true;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = plan.classPassCount;
        break;
      case 'bronze':
        card.background = theme.bronzeCardBackground;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        if (plan.status === 'canceled') {
          card.bottomLeft.title.text = 'Ending on';
          card.bottomLeft.value.text = endDate;
        } else {
          card.bottomLeft.title.text = 'Next Renewal';
          card.bottomLeft.value.text = nextRenewDate;
        }
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = plan.classPassCount;
        break;
      case 'silver':
        card.background = theme.silverCardBackground;
        card.topLeft.title.text = 'Subscribe on';
        //TODO: these dates should come from the backend not be calculated!!!
        card.topLeft.value.text = subscribedDate;
        // card.topLeft.value.text = lastRenewDate === 'Invalid date' ? subscribedDate : lastRenewDate;
        if (plan.status === 'canceled') {
          card.bottomLeft.title.text = 'Ending on';
          card.bottomLeft.value.text = endDate;
        } else {
          card.bottomLeft.title.text = 'Next Renewal';
          card.bottomLeft.value.text = nextRenewDate;
        }
        card.topRight.title.text = 'Classes Left';
        card.topRight.value.text = noOfClasses;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = plan.classPassCount;
        break;
      case 'gold':
        card.background = theme.goldCardBackground;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        if (plan.status === 'canceled') {
          card.bottomLeft.title.text = 'Ending on';
          card.bottomLeft.value.text = endDate;
        } else {
          card.bottomLeft.title.text = 'Next Renewal';
          card.bottomLeft.value.text = nextRenewDate;
        }
        card.topRight.title.text = plan.discipline;
        card.topRight.value.text = 'Unlimited';
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = plan.classPassCount;
        break;
      case 'platinum':
        card.background = theme.platinumCardBackground;
        card.color = theme.platinumCardText;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        if (plan.status === 'canceled') {
          card.bottomLeft.title.text = 'Ending on';
          card.bottomLeft.value.text = endDate;
        } else {
          card.bottomLeft.title.text = 'Next Renewal';
          card.bottomLeft.value.text = nextRenewDate;
        }
        card.bottomRight.title.text = info.split(' ')[0];
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = info.split(' ')[1];
        break;
    }
    return card;
  } else if (type === 'PAYMENT_SUCCESS') {
    let { title, info } = getTitleAndInfo(plan.plan);
    let { subscribedDate, renewalDate } =
      plan.plan.type === 'one_time' ? calculatePurchaseAndRenewalDates(6) : calculatePurchaseAndRenewalDates(1);
    const noOfClasses = plan.plan.metadata.Eligible_Classes === 'U' ? 'Unlimited' : plan.plan.metadata.Eligible_Classes;
    card.title = title;
    title = isClassPass(plan.plan) ? info : title;
    const usersClassPassCount = plan.userProfile.classPassCount ? plan.userProfile.classPassCount : 0;
    switch (title.toLowerCase()) {
      case 'blue':
        card.background = theme.blueCardBackground;
        card.bottomLeft.title.text = info.split(' ')[0];
        card.bottomLeft.title.bold = true;
        card.bottomLeft.value.text = info.split(' ')[1];
        card.bottomLeft.value.bold = true;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = isClassPass(plan.plan) ? '01' : usersClassPassCount;
        break;
      case 'bronze':
        card.background = theme.bronzeCardBackground;
        card.topLeft.title.text = isClassPass(plan.plan) ? '' : 'Subscribe on';
        card.topLeft.value.text = isClassPass(plan.plan) ? '' : subscribedDate;
        card.bottomLeft.title.text = isClassPass(plan.plan) ? 'Valid Till' : 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.topRight.title.text = '';
        card.topRight.value.text = isClassPass(plan.plan) ? '' : info;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = isClassPass(plan.plan) ? true : false;
        card.bottomRight.value.text = isClassPass(plan.plan) ? '01' : usersClassPassCount;
        break;
      case 'silver':
        card.background = theme.silverCardBackground;
        card.topLeft.title.text = isClassPass(plan.plan) ? '' : 'Subscribe on';
        card.topLeft.value.text = isClassPass(plan.plan) ? '' : subscribedDate;
        card.bottomLeft.title.text = isClassPass(plan.plan) ? 'Valid Till' : 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.topRight.title.text = isClassPass(plan.plan) ? '' : 'Classes Left';
        card.topRight.value.text = noOfClasses;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = isClassPass(plan.plan) ? true : false;
        card.bottomRight.value.text = isClassPass(plan.plan) ? '01' : usersClassPassCount;
        break;
      case 'gold':
        card.background = theme.goldCardBackground;
        card.topLeft.title.text = isClassPass(plan.plan) ? '' : 'Subscribe on';
        card.topLeft.value.text = isClassPass(plan.plan) ? '' : subscribedDate;
        card.bottomLeft.title.text = isClassPass(plan.plan) ? 'Valid Till' : 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.topRight.title.text = isClassPass(plan.plan) ? '' : plan.disciplineObj.name;
        card.topRight.value.text = noOfClasses;
        card.bottomRight.title.text = 'Class Pass';
        card.bottomRight.title.bold = isClassPass(plan.plan) ? true : false;
        card.bottomRight.value.text = isClassPass(plan.plan) ? '01' : usersClassPassCount;
        break;
      case 'platinum':
        card.background = theme.platinumCardBackground;
        card.color = theme.platinumCardText;
        card.topLeft.title.text = 'Subscribe on';
        card.topLeft.value.text = subscribedDate;
        card.bottomLeft.title.text = 'Next Renewal';
        card.bottomLeft.value.text = renewalDate;
        card.bottomRight.title.text = info.split(' ')[0];
        card.bottomRight.title.bold = false;
        card.bottomRight.value.text = info.split(' ')[1];
        break;
    }
    return card;
  } else if (type === 'PAUSED_SUBSCRIPTION') {
    const { title } = getTitleAndInfo(plan);
    const { pausedOnDate, resumeOnDate } = getPlanDates(plan);
    card.title = title;
    card.topLeft.title.text = 'Paused on';
    card.topLeft.value.text = pausedOnDate;
    card.bottomLeft.title.text = 'Resuming on';
    card.bottomLeft.value.text = resumeOnDate;
    card.bottomRight.title.text = 'Class Pass';
    card.bottomRight.title.bold = false;
    card.bottomRight.value.text = plan.classPassCount;
    switch (title.toLowerCase()) {
      case 'silver':
        card.background = theme.silverCardBackground;
        break;
      case 'gold':
        card.background = theme.goldCardBackground;
        break;
      case 'platinum':
        card.background = theme.platinumCardBackground;
        card.color = theme.black;
        break;
    }
    return card;
  }
  return null;
};
