import { Modal, PrimaryButton } from '@app/modules/common/index';
import styled from 'styled-components';
import { media } from '@app/themes/index';

export const StyledSavedCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32.0625rem;
  height: 17.25rem;
  flex-shrink: 0;
  aspect-ratio: 1.79;
  border-radius: 1.25rem;
  padding: 1.31rem 2rem;
  background-color: ${(props) => props.theme.black};
  box-shadow: 4px 4px 20px 1px rgba(68, 68, 68, 0.25);
  color: ${(props) => props.theme.white};
  font-size: 1.375em;
  ${media.lessThan('tablet')`
  flex-direction: column;
  height: 22rem;
  width: 26.4rem;
  `}
  .highlight {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.highlight};
  }
`;

export const CardDiv = styled.div`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-top: 1.25rem; */
`;
export const AddPaymentBtn = styled(PrimaryButton)`
  margin-top: 3.125rem;
  align-self: flex-end;
`;

export const AddPayment = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-left: 0.25rem;
`;
export const AddPaymentWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  width: 11.5rem;
  cursor: pointer;
  color: ${(props) => props.theme.highlight};
  &:hover {
    color: ${(props) => props.theme.white};
  }
`;
export const SeeAllWrapper = styled.div`
  color: ${(props) => props.theme.highlight};
  font-size: 1.375rem;
  font-weight: 700;
  text-decoration: underline;
  width: 4.313rem;
  &:hover {
    color: ${(props) => props.theme.white};
    cursor: pointer;
  }
`;

export const DownloadReceiptBtn = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.highlight};
  color: ${(props) => props.theme.highlight};
  align-self: flex-end;
`;

export const PaymentModal = styled(Modal)`
  .modal {
    width: 32.063rem;
    min-height: 17.25rem;
  }
`;

export const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 17.25rem; */
  width: 32.063rem;
  border-radius: 1.25em;
  padding: 1em 1.2em;
  background-color: ${(props) => props.theme.black};
  box-shadow: 4px 4px 20px 1px rgba(68, 68, 68, 0.25);
  color: ${(props) => props.theme.white};
  font-size: 1.375rem;
  .highlight {
    font-weight: 500;
    color: ${(props) => props.theme.highlight};
  }
`;

export const Divider = styled.div`
  width: 17.75rem;
  height: 0.063rem;
  background-color: ${(props) => props.theme.white};
  margin-top: 2.188rem;
  /* margin-bottom: 1.25rem; */
`;

export const PopUpHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CrossWrapper = styled.div`
  &:hover {
    color: ${(props) => props.theme.highlight};
    cursor: pointer;
  }
`;
