import styled from 'styled-components';
import { media } from '@app/themes/index';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  background-color: #212529;
  .Loading {
    align-self: center;
    position: absolute;
    margin-top: 30%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const MyComponentWrapper = styled.div`
  padding: 0.625rem 1.875rem;
  color: white;
  text-align: justify;
  width: fit-content;
  margin-left: 3rem;
  margin-right: 3rem;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.15rem;
  `}
`;

export const ButtonWrapper = styled.div`
  border-radius: 1.25rem;
  background: #ec6798;
  align-self: center;
  align-items: center;
  width: fit-content;
  margin-top: 4.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  justify-content: center;
  margin-bottom: 2rem;
  cursor: pointer;
  ${media.lessThan('tablet')`
    margin-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
  `}
`;

export const ButtonStyle = styled.div`
  color: white;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  align-self: center;
  font-size: 0.7rem;
  ${media.lessThan('tablet')`
    font-size: 0.5rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
  `}
`;

export const SignatureWrapper = styled.div`
  color: white;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  align-self: flex-start;
  margin-left: 5rem;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
    margin-left: 2.9rem;
    font-size: 1rem;
  `}
`;

export const SalutationWrapper = styled.div`
  color: white;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 2rem;
  align-self: flex-start;
  margin-left: 5rem;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
    margin-left: 2.9rem;
    font-size: 1rem;
  `}
`;

export const HeaderWrapper = styled.div`
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  align-self: flex-start;
  margin-left: 5rem;
  font-size: 1.25rem;
  ${media.lessThan('tablet')`
  margin-left: 2.9rem;
  `}
`;
