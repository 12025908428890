import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import {
  Body,
  HeaderWrapper,
  ReportButton,
  NotFoundContainer,
  ScheduleButtons,
  HomeButton
} from './NotFoundPage.styles';
import { HeaderWithLogo, Translation } from '@modules/common/index';
import { error404_sticker } from '@app/themes/images';
import { logToCrashalytics } from 'tatvarnsdk/Web';

export default function NotFound() {
  const history = useHistory();
  const onHomeClicked = () => {
    history.push('/dashboard');
  };
  useEffect(() => {
    logToCrashalytics('NotFoundPage Container');
  }, []);
  return (
    <NotFoundContainer>
      <HeaderWrapper>
        <HeaderWithLogo />
      </HeaderWrapper>
      <Body>
        <div className="error404">
          <div className="row1_oops">
            <Translation id="oops" />
          </div>
          <div className="row1">
            <Translation id="error_404" />
          </div>
          <div className="row2">
            <Translation id="page_not_found" />
          </div>
        </div>
        <div className="sticker">
          <img src={error404_sticker} alt="Error Sticker" height="100%" width="100%" />
        </div>
        <div className="oops">
          <div className="row1">
            <Translation id="oops" />
          </div>
          <div className="row1_error_404">
            <Translation id="error_404" />
          </div>
          <ScheduleButtons>
            <HomeButton textId="GO_TO_HOME" onClick={onHomeClicked} />
            <ReportButton textId="report" />
          </ScheduleButtons>
        </div>
      </Body>
    </NotFoundContainer>
  );
}
