import { PrimaryButton, StyledContainer } from '@app/modules/common/index';
import styled from 'styled-components';
import { media } from '@app/themes/index';

export const PaymentScreenContainer = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  width: 100%;
  ${media.lessThan('tablet')`
  padding-left: 2.88rem;
  `}
  background-color: ${(props) => props.theme.background};
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.transparent13};
  margin-left: 2.25rem;
  padding: 1.75rem 2.625rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  width: 48rem;
  height: 27rem;
  flex-shrink: 0;
  ${media.lessThan('tablet')`
  margin-top: 2.5rem;
  border-radius: 1.5rem;
  width: 100%;
  height: 34rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-left: -1.21rem;
  flex-shrink: 0;
  `}
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan('tablet')`
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  `}
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 0.75rem;
`;
export const CancelWrapper = styled.div`
  margin-top: 12.15rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${media.lessThan('tablet')`
  margin-right: 3.25rem;
  `}
`;
export const HeaderWrapper = styled.div`
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  align-self: flex-start;
  font-size: 1.25rem;
  width: 12.5rem;
  height: 4rem;
  ${media.lessThan('tablet')`
  width: 5.80644rem;
  height: 1.875rem;
  flex-shrink: 0;
  `}
`;

export const SubscriptionWrapper = styled.div`
  width: 26.89788rem;
  height: 14.58869rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  ${media.lessThan('tablet')`
  width: 26rem;
  height: 8rem;
  flex-shrink: 0;
  `}
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 400;
  font-size: 1rem;
  color: ${(props) => props.theme.highlight};
`;

export const DownloadButtonWrapper = styled.div`
  margin-right: 0.063rem;
`;

export const SubscriptionText = styled.div`
  text-align: center;
  height: 6.125rem;
  font-weight: 400;
  font-size: 2.0625rem;
  font-style: normal;
  margin-bottom: 2.688rem;
  line-height: 2.8125rem;
  color: ${(props) => props.theme.textPrimary};
  ${media.lessThan('tablet')`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  `}
`;

export const ScheduleButtons = styled.div`
  display: flex;
  flex-direction: column;
  height: 8.25rem;
  width: 14.75569rem;
  margin-left: 2.07rem;
  margin-top: 7rem;
  flex-shrink: 0;
  ${media.lessThan('tablet')`
  height: 6rem;
  width: 20rem;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
  `}
`;

export const AddPaymentBtn = styled(PrimaryButton)`
  align-self: flex-end;
  cursor: pointer;
`;

export const StickerWrapper = styled.div`
  margin-left: 10rem;
  margin-top: auto;
  margin-bottom: auto;
  ${media.lessThan('tablet')`
  display: none;
  `}
`;
export const ScheduleClass = styled(PrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 0.87rem;
  cursor: pointer;
`;
export const HomeButton = styled(PrimaryButton)`
  background-color: inherit;
  color: ${(props) => props.theme.highlight};
  border: 0.063rem solid ${(props) => props.theme.highlight};
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.87rem;
  cursor: pointer;
`;
export const AppBtnPrimary = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.highlight};
  color: ${(props) => props.theme.white};
  border: 0.063rem solid ${(props) => props.theme.highlight};
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.87rem;
  cursor: pointer;
`;

export const ContainerIllustrationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4.12rem;
`;
