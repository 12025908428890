import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomAlert } from './CustomAlert/CustomAlert';
import { CustomizedToast } from './ToastMessage.styles';
import { NOTIFICATION_TYPE } from 'tatvarnsdk/Web';
import WARNING from './images/WARNING.svg';
import ERROR from './images/ERROR.svg';
import SUCCESS from './images/SUCCESS.svg';
import DEFAULT from './images/DEFAULT.svg';

export const showToast = (props, action) => {
  const toastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined
  };
  switch (props.notificationType) {
    case NOTIFICATION_TYPE.ERROR:
      return toast.error(getCutomAlert(props, action), toastOptions);
    case NOTIFICATION_TYPE.WARNING:
      return toast.warn(
        getCutomAlert(props, action),
        {
          ...toastOptions,
          icon: () => <img src={WARNING} />
        },
        toastOptions
      );
    case NOTIFICATION_TYPE.SUCCESS:
      return toast.success(getCutomAlert(props, action), toastOptions);
    default:
      return toast.info(getCutomAlert(props, action), toastOptions);
  }
};

const iconSrcMap = {
  [NOTIFICATION_TYPE.ERROR]: ERROR,
  [NOTIFICATION_TYPE.SUCCESS]: SUCCESS,
  [NOTIFICATION_TYPE.WARNING]: WARNING,
  [NOTIFICATION_TYPE.DEFAULT]: DEFAULT
};

const getCutomAlert = (props, action) => {
  const { titleId, messageId, notificationType, title, message, buttonTextId, buttonText } = props;
  const iconSrc = iconSrcMap[notificationType] || iconSrcMap[NOTIFICATION_TYPE.DEFAULT];
  return (
    <CustomAlert
      type={notificationType}
      iconSrc={iconSrc}
      title={title}
      message={message}
      titleId={titleId}
      messageId={messageId}
      buttonTextId={buttonTextId}
      buttonText={buttonText}
      action={action}
    />
  );
};

export const ToastMessage = () => <CustomizedToast closeButton={false} limit={3} />;
