import React from 'react';
import { PaymentSummaryContainer, Row, Title, PlanName, OtherDesc, Divider } from './PaymentSummary.styles';
import { Translation } from '@modules/common/index';
export const PaymentSummary = (props) => {
  const { planData, planDetails, disciplineObj, coupon } = props;
  const discount = coupon ? coupon.coupon.name : '';
  const prev_credit = 'Credit from previous plan';
  return (
    <PaymentSummaryContainer>
      <Title>
        <Translation id="payment" />
      </Title>
      <Row>
        <PlanName>
          <Translation text={planDetails.name} />
        </PlanName>
        <OtherDesc>
          <Translation text={planData.planPrice} />
        </OtherDesc>
      </Row>
      {disciplineObj && (
        <OtherDesc>
          <Translation text={disciplineObj.name} />
        </OtherDesc>
      )}
      <OtherDesc>
        <Translation id="validity" />
        <Translation text={planData.validity} />
      </OtherDesc>
      {coupon ? (
        <Row>
          <OtherDesc>
            <Translation text={discount} />
          </OtherDesc>
          <OtherDesc>
            <Translation text={planData.couponDiscount} />
          </OtherDesc>
        </Row>
      ) : null}
      {planData.creditString ? (
        <Row>
          <OtherDesc>
            <Translation text={prev_credit} />
          </OtherDesc>
          <OtherDesc>
            <Translation text={planData.creditString} />
          </OtherDesc>
        </Row>
      ) : (
        ''
      )}
      <Divider />
      <Row>
        <PlanName>
          <Translation id="total" />
        </PlanName>
        <PlanName>
          <Translation text={planData.finalPrice} />
        </PlanName>
      </Row>
      {planData.creditForNextInvoice ? (
        <>
          {/* <Divider /> */}
          <Row>
            <PlanName>
              <Translation id="CREDIT_FOR_NEXT_INVOICE" />
            </PlanName>
            <PlanName>
              <Translation text={planData.creditForNextInvoice} />
            </PlanName>
          </Row>
        </>
      ) : (
        ''
      )}
    </PaymentSummaryContainer>
  );
};
