import { createSelector } from 'reselect';
import get from 'lodash/get';
import { initialState } from './reducer';

export const selectPaymentDomain = (state) => state.payment || initialState;

export const selectPayment = () => createSelector(selectPaymentDomain, (substate) => substate);

export const selectError = () => createSelector(selectPaymentDomain, (substate) => get(substate, 'error', null));

export const selectLoading = () => createSelector(selectPaymentDomain, (substate) => get(substate, 'loading', false));

export const selectSecretkey = () =>
  createSelector(selectPaymentDomain, (substate) => get(substate, 'secretkey', null));

export const selectPaymentMethodId = () =>
  createSelector(selectPaymentDomain, (substate) => get(substate, 'paymentMethodId', null));

export const selectPaymentStatus = () =>
  createSelector(selectPaymentDomain, (substate) => get(substate, 'paymentStatus', false));

export default selectPayment;
