import { createGlobalStyle } from 'styled-components';
import SFProDisplayRegular from './assets/fonts/SFProDisplay/SFPRODISPLAYREGULAR.otf';

const baseHeight = 1117;
const baseWidth = 1728;
export const baseRatio = baseWidth / baseHeight;

const GlobalStyle = createGlobalStyle`
  :root{
    --base-h : ${baseHeight};
    --base-w : ${baseWidth};
    --root-font-size: 16px;
    --multiplier: calc(var(--screen-w)/var(--base-w));
  }
  html{
    font-size: 3vw;
    font-family: "SFProDisplay";
  }
  @media screen and (min-width: 768px) {
    html {
      font-size: calc(var(--multiplier) * var(--root-font-size));
    }
  }
  @media screen and (min-width: 768px) and (min-aspect-ratio: ${baseRatio}) {
    :root{
      --multiplier: calc(var(--screen-h)/var(--base-h));
    }
  }

  @font-face {
    font-family: 'SFProDisplay';
    src: url(${SFProDisplayRegular}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  p,
  span,
  button,
  label {
    line-height: 1.5em;
    margin-bottom: 0;
  }

  ::-webkit-scrollbar {
    width: 0.0625rem;
    height: 0.625rem;
    margin-top: 0.3125rem;
    border-radius: 0.625rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.625rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.625rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

`;

export default GlobalStyle;
