import styled from 'styled-components';
import Select from 'react-select';
import { PrimaryButton } from '@app/modules/common/index';
import { BiCheckboxChecked, BiCheckbox } from 'react-icons/bi';
import { media } from '@app/themes/index';

export const CardDetailsContainer = styled.div`
  background-color: ${(props) => props.theme.transparent13};
  padding: 1.75rem 2.625rem;
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  margin-right: 3.375rem;
  border-radius: 0.75rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5;
  width: 31rem;
  font-size: 1.125rem;
  .input-option {
    box-sizing: content-box;
    border-radius: 0.625rem;
    border: 0.063rem solid ${(props) => props.theme.transparent13};
    option {
      background-color: ${(props) => props.theme.transparent13};
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 31rem;
  font-size: 1.125rem;
  .checkbox {
    display: flex;
    flex-direction: row;
    .button {
    }
    .label {
      margin-top: 0.5rem;
      font-size: 1.1rem;
      font-weight: 520;
      line-height: 1.1rem;
      letter-spacing: 0rem;
      text-align: left;
    }
  }
  .button {
    display: flex;
    flex-direction: row;
  }
`;

export const InputTitle = styled.p`
  color: ${(props) => props.theme.black};
  font-weight: 700;
  font-size: 0.938rem;
  margin-top: 1.625rem;
`;

export const InputStyle = styled.div`
  width: 13.75rem;
  height: 2.938rem;
  margin-top: 0.25rem;
`;
export const ReactSelect = styled(Select)`
  .Select__control {
    height: 3rem;
    min-height: 1rem;
    width: 13.75rem;
    color: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.black};
    border-radius: 0.625rem;
    cursor: pointer;
    &:hover {
      border: 1px solid ${(props) => props.theme.black};
    }
  }
  .Select__control--is-focused {
    border: solid ${(props) => props.theme.black};
    &:hover {
      border: solid ${(props) => props.theme.black};
    }
  }
  .Select__indicators {
    height: 3rem;
  }
`;

export const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35rem;
  border-radius: 1.25em;
  padding: 1em 1.2em;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
  ${media.lessThan('tablet')`
  width: 32rem;
  padding-left: 0.525em;
  border-radius: 1em;
  `}
`;

export const CardWrapper = styled.div`
  margin-top: 1.5;
  width: 13.75rem;
  font-size: 1.125rem;
`;

export const InputBox = styled.input`
  padding: 0.62rem 0.81rem 0.62rem 0.81rem;
  width: ${(props) => (props.width ? props.width : '20rem')};
  height: ${(props) => (props.height ? props.height : '3rem')};
  color: ${(props) => props.theme.black};
  background-color: ${(props) => props.theme.transparent13};
  font-size: 1.125rem;
  display: flex;
  border-radius: 0.625rem;
  justify-content: flex-start;
  margin-top: 0.38rem;
  border: 1px solid ${(props) => props.theme.black};
  cursor: pointer;
  &:hover {
    border: 1px solid ${(props) => props.theme.black};
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.black};
  font-weight: 700;
  font-size: 1.5rem;
`;

export const DownloadReceiptBtn = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.highlight};
  color: ${(props) => props.theme.highlight};
  margin-right: 0.25rem;
  width: 7.5rem;
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.highlight};
    border: 1px solid ${(props) => props.theme.highlight};
    color: ${(props) => props.theme.white};
    cursor: pointer;
  }
`;
export const AddPaymentBtn = styled(PrimaryButton)`
  width: 7.5rem;
  align-self: flex-end;
  background-color: ${(props) => (props.hasError ? 'grey' : props.theme.highlight)};
  cursor: pointer;
`;
export const PopUpHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CrossWrapper = styled.div`
  &:hover {
    color: ${(props) => props.theme.highlight};
    cursor: pointer;
  }
`;

export const CheckBoxChecked = styled(BiCheckboxChecked)`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 0.125rem;
  border: 0.063rem;
  color: ${(props) => props.theme.highlight};
`;

export const CheckBoxUnChecked = styled(BiCheckbox)`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 0.125rem;
  border: 0.063rem;
  color: ${(props) => props.theme.highlight};
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 0.9rem;
  margin-top: 0.313rem;
`;
