import React from 'react';
import NavbarLink from '../NavbarLink/NavbarLink';
import { Divider } from '../../Navbar.styles';
import { List, OuterList } from './Routes.styles';
import { Translation } from '@app/modules/common/index';

function Routes() {
  return (
    <OuterList>
      <List className="link">
        <NavbarLink link={'dashboard'}>
          <Translation id="HOME" />
        </NavbarLink>
      </List>
      <List className="link">
        <NavbarLink link={'subscription'}>
          <Translation id="SUBSCRIPTION" />
        </NavbarLink>
      </List>
      {/* TODO: add Our Team link */}
    </OuterList>
  );
}

export default Routes;
