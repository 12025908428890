import { PrimaryButton } from '@app/modules/common/index';
import { Modal } from '@modules/common/index';
import styled from 'styled-components';

export const StyledUpgradeSubscriptionCard = styled.div``;
export const UpgradeSubscriptionCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1rem;
  margin: 0 1.3rem;
  margin-bottom: 2rem;
  height: 39rem;
  width: 18rem;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.darkGrey};
  color: ${(props) => props.theme.white};
  font-size: 0.5rem;
  cursor: pointer;
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: flex-end;
    gap: 0.3rem;
    .price {
      &.stroked {
        font-size: 1.1rem;
        text-decoration: line-through;
        align-self: center;
      }
    }
    .subtitle {
      font-size: 1rem;
      font-weight: 400;
      line-height: 2.5em;
    }
  }
  .card {
    width: 20rem;
    font-size: 0.94rem;
    margin: 0.5rem 0;
  }
  .descriptions {
    font-size: 1.2rem;
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    li::marker {
      content: '•';
      color: ${(props) => props.theme.highlight};
      font-size: 2.5rem;
    }
    li {
      padding-left: 0.5em;
      vertical-align: top;
    }
  }
`;
export const PurchaseButton = styled(PrimaryButton)`
  width: fit-content;
  position: absolute;
  top: 37.5rem;
  min-width: 8.75rem;
`;
export const StyledModal = styled(Modal)`
  .modal {
    width: 20.9375rem;
    padding: 1.25rem;
    border-radius: 0.5rem;
    font-size: 1.0625rem;
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.black};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.13rem;
    .title {
      font-size: 1.3125rem;
      font-weight: 700;
      margin-bottom: 0.38rem;
      align-self: center;
    }
    .disciplines {
      display: flex;
      flex-direction: column;
      gap: 1.13rem;
      align-items: center;
    }
    .info {
      font-size: 0.75rem;
      color: ${(props) => props.theme.textMuted};
      text-align: center;
      margin: 0 1.2rem;
    }
    .confirmSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
export const StyledCancelButton = styled(PrimaryButton)`
  padding: 0.37rem 1.97rem;
  width: fit-content;
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.highlight};
  color: ${(props) => props.theme.black};
`;
export const StyledConfirmButton = styled(PrimaryButton)`
  padding: 0.37rem 1.97rem;
`;
export const StyledDisciplineButton = styled(PrimaryButton)`
  width: 16rem;
  border-width: ${(props) => (props.active ? '0.13rem' : '0.06rem')};
  border-style: solid;
  border-color: ${(props) => (props.active ? props.theme.highlight : props.theme.black)};
  color: ${(props) => props.theme.black};
  background-color: ${(props) => props.theme.white};
  font-size: 1.0625rem;
  border-radius: 0.56rem;
`;
