/**
 *
 * ProtectedRoute
 *
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navbar from '@modules/Navbar/index';
import routeConstants from '@utils/routeConstants';
import { routeConfig } from '@app/routeConfig';

const DynamicRouteInjector = ({ isLoggedIn, exact, routeKey, path, render: C }) => {
  const isUnprotectedRoute =
    Object.keys(routeConstants)
      .filter((key) => !routeConstants[key].isProtected)
      .map((key) => routeConstants[key].route)
      .includes(path) && exact;
  // TODO: what I propose is:
  // const isUnprotectedRoute = !routeConstants[routeKey].isProtected
  const showNavbar = routeConstants[routeKey]?.navbar === true;
  const handleRedirection = (renderProps) => {
    let to;
    let from;
    const props = { ...renderProps, isLoggedIn: isLoggedIn };
    if (!isLoggedIn && !isUnprotectedRoute) {
      // user is not logged in
      to = routeConstants.loginSignUp.route;
    } else if (!isLoggedIn && isUnprotectedRoute) {
      // not logged in and trying to access an unprotected route so don't redirect
      return <C {...props} />;
    } else if (isLoggedIn && !showNavbar) {
      // user is logged in
      return (
        <>
          <C {...props} />
        </>
      );
    } else if (isLoggedIn && showNavbar) {
      return (
        <>
          <Navbar />
          <C {...props} />
        </>
      );
    }
    return <Redirect to={to} />;
  };

  return <Route render={handleRedirection} />;
};

DynamicRouteInjector.propTypes = {
  render: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  handleLogout: PropTypes.func
};

export default DynamicRouteInjector;
