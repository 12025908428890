/**
 *
 * T
 *
 */

import React, { memo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import If from './If/If';
import { fonts } from '@app/themes/index';

const StyledText = styled.span`
  && {
    ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`};
  }
`;
// const getFontStyle = (type) => (fonts.style[type] ? fonts.style[type] : () => {});
export const Translation = ({ text, id, marginBottom, values, ...otherProps }) => (
  <StyledText data-testid="t" marginBottom={marginBottom} {...otherProps}>
    <If condition={id} otherwise={text}>
      <FormattedMessage id={id} values={values} />
    </If>
  </StyledText>
);

Translation.propTypes = {
  id: PropTypes.string,
  marginBottom: PropTypes.number,
  values: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(fonts.style))
};

Translation.defaultProps = {
  values: {}
};

const TextComponent = memo(Translation);
export default TextComponent;
