import styled from 'styled-components';
import Select from 'react-select';
import { Input, PrimaryButton } from '@modules/common/index';
import { media } from '@app/themes/index';

export const CardDetailsContainer = styled.div`
  background-color: ${(props) => props.theme.transparent13};
  /* padding: 1.75rem 2.625rem; */
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  margin-right: 3.375rem;
  border-radius: 0.75rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-top: 1.5;

  .input-option {
    box-sizing: content-box;
    border-radius: 0.625rem;
    border: 0.063rem solid ${(props) => props.theme.transparent13};
    option {
      background-color: ${(props) => props.theme.transparent13};
    }
    width: 13.75rem;
    font-size: 1.125rem;
  }
`;
export const SelectCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5;
  ${media.lessThan('tablet')`
    flex-direction: row;
  `}
  .selectMonthFirst {
    ${media.lessThan('tablet')`
    /* margin-right: 0.5rem; */
  `}
  }
  .selectMonthSecond {
    margin-top: 0.3rem;
    ${media.lessThan('tablet')`
    margin-top: 0rem;
    margin-left: -3.4rem;
  `}
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5;
  .inputBox {
    margin-right: 2.56rem;
  }
  ${media.lessThan('tablet')`
  flex-direction: column;
  `}
`;
export const InputTitle = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-weight: 300;
  font-size: 0.938rem;
  margin-top: 1.625rem;
`;

export const OuterCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.063rem;
  width: 31.75rem;
  height: 3.5rem;
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.textPrimary};
  ${media.lessThan('tablet')`
  width: 19rem;
  `}
`;
export const PlanName = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;
export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
`;
export const InputStyle = styled.div`
  width: 13.75rem;
  height: 3rem;
  margin-top: 0.25rem;
`;

export const ReactSelect = styled(Select)`
  .Select__control {
    min-height: 1rem;
    height: 3rem;
    width: 13.75rem;
    border: 1px solid ${(props) => props.theme.textPrimary};
    border-radius: 0.625rem;
    cursor: pointer;
    &:hover {
      border: 1px solid ${(props) => props.theme.textPrimary};
    }
  }
  .Select__indicators {
    height: 3rem;
  }
  .Select__control--is-focused {
    box-shadow: 0 0 0 1px ${(props) => props.theme.textPrimary};
    border: solid black;
    &:hover {
      border: solid black;
    }
  }
  ${media.lessThan('tablet')`
  .Select__control {
      width: 8.75rem;
    }
  `}
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 0.9rem;
  margin-top: 0.313rem;
  ${media.lessThan('tablet')`
  font-size: 0.7rem;
  `}
`;

export const PayButton = styled(PrimaryButton)`
  width: 7.25rem;
  height: 2.5rem;
  padding: 0.875rem 0.625rem;
  margin-left: auto;
  background-color: ${(props) => (props.submit ? props.theme.highlight : 'grey')};
  ${media.lessThan('tablet')`
  margin-top: 2rem;
  margin-left: 6.3rem;
  margin-right: 6.5rem;
  `}
`;
