import styled from 'styled-components';
import { Modal, StyledContainer } from '@app/modules/common/index';
import { media } from '@app/themes/index';

export const List = styled.div`
`;
export const OuterList = styled.div`
  flex-grow: 1;
  display: flex;
  margin-left: 7.38rem;
  gap: 5.69rem;
  ${media.lessThan('tablet')`
      display: none;
    `}
`;
