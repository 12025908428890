import styled from 'styled-components';
import { media } from '@app/themes/index';

export const Container = styled.div`
  background-color: ${(props) => props.theme.transparent13};
  padding: 1.75rem 2.625rem;
  display: flex;
  flex-direction: column;
  width: 55.375rem;
  min-height: 18.438rem;
  margin-right: 3.5rem;
  border-radius: 0.75rem;
  order: 1;
  ${media.lessThan('tablet')`
  width: 25rem;
  margin-right: auto;
  margin-left: auto;
  border-radius: 2rem;
  order: 2;
  `}
`;

export const Title = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-weight: 700;
  font-size: 1.125rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 0.2rem;
  /* width: 90%;
  margin-top: 24px; */
`;
