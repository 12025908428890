import React, { useEffect, memo, useState } from 'react';
import UserDetails from './components/UserDetails/UserDetails';
import { FormContainer, OtpButton, StyledInput, StyledLogoImg } from './Signup.styles';
import { tatvaLandingLogo } from '@app/themes/images';

export default function Signup(props) {


  return (
    <>
      <UserDetails />
      <FormContainer>
        <StyledLogoImg src={tatvaLandingLogo} />
        <div className="title">Sign Up</div>
        {/* <StyledInput
          value={mobile}
          onChange={(event) => {
            setMobile(event.target.value);
          }}
        />
        {verifyOTP && (
          <StyledInput
            value={otp}
            onChange={(event) => {
              setOtp(event.target.value);
            }}
          />
        )}
        {verifyOTP ? (
          <OtpButton
            text="Verify OTP"
            onClick={() => {
              verify(otp);
            }}
          />
        ) : mobile ? (
          <OtpButton
            text="Send OTP"
            disabled={false}
            onClick={() => {
              sendOtp(setVerifyOTP, setTimer, setIsTimerRunning);
            }}
          />
        ) : (
          <OtpButton text="Send OTP" disabled={true} />
        )}
        {verifyOTP ? (
          isTimerRunning ? (
            <div className="resend">Resend in {formatTimer(timer)}</div>
          ) : (
            <div
              className="resend"
              onClick={() => {
                sendOtp();
              }}
            >
              Resend OTP
            </div>
          )
        ) : (
          ''
        )}
        <div className="signup_text">
          <span className="grey_text">Don’t have an account? </span>
          <span
            className="highlight_text"
            onClick={() => {
              setIsSignUp(true);
            }}
          >
            Sign Up
          </span>
        </div> */}
      </FormContainer>
    </>
  );
}
