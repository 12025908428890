import React from 'react';
import { textStyle, titleStyle } from '../../PrivacyPolicy.styles';

export default function LogData() {
  return (
    <div>
      <p style={textStyle}>
        <strong style={titleStyle}>Log Data</strong>
      </p>
      <p style={textStyle}>
        We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and
        information (through third-party products) on your phone called Log Data. This Log Data may include information
        such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration
        of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
      </p>
    </div>
  );
}
