import React, { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { firebaseApp } from '@app/firebase';
import { NOTIFICATION_TYPE, recordErrorInCrashalytics } from 'tatvarnsdk/Web';
import { LoadingPageContainer, StyledLoadingPage } from './LoginSignUpPageContainer.styles';
import { Loading, Modal } from '../common/index';
import Login from './Components/Login/Login';
import Signup from './Components/Signup/Signup';
import { showToast } from '@modules/common/index';
import QRContainer from './Components/QRContainer/QRContainer';

const db = getFirestore();

export default function LoginSignUpPageContainer(props) {
  const { userDocId, setuserDocId } = props;
  const [isSignUp, setIsSignUp] = useState(false);
  const [webQr, setQR] = useState(null);
  const [otp, setOtp] = useState('');
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [mobile, setMobile] = useState('');
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unsubscribe;
    if (userDocId) {
      unsubscribe = onSnapshot(doc(db, 'web_qr_codes', userDocId), async (doc) => {
        let docData = doc.data();
        if (docData && docData.token) {
          setLoading(true);
          signInWithCustomToken(getAuth(firebaseApp), docData.token)
            .then(() => {
              showToast({
                titleId: 'success',
                message: 'User logged in successfully',
                notificationType: NOTIFICATION_TYPE.SUCCESS
              });
            })
            .catch((error) => {
              showToast({
                titleId: 'LOGIN_FAILURE',
                message: 'Please try again!',
                notificationType: NOTIFICATION_TYPE.ERROR
              });
              recordErrorInCrashalytics('LoginSignUp saga checkQR', error);
              return false;
            })
            .finally(() => {
              unsubscribe();
            });
          setLoading(false);
        }
      });
    }
    return () => {
      typeof unsubscribe === 'function' ? unsubscribe() : '';
    };
  }, []);

  return (
    <>
      {userDocId ? (
        ''
      ) : (
        <StyledLoadingPage>
          <LoadingPageContainer>
            {isSignUp ? (
              <Signup />
            ) : (
              <Login
                mobile={mobile}
                setMobile={setMobile}
                verifyOTP={verifyOTP}
                setVerifyOTP={setVerifyOTP}
                otp={otp}
                setOtp={setOtp}
                isTimerRunning={isTimerRunning}
                setIsTimerRunning={setIsTimerRunning}
                timer={timer}
                setTimer={setTimer}
                setLoading={setLoading}
                setIsSignUp={setIsSignUp}
              />
            )}
            <QRContainer userDocId={userDocId} webQr={webQr} setQR={setQR} setLoading={setLoading} />
          </LoadingPageContainer>
        </StyledLoadingPage>
      )}

      {loading && (
        <Modal isOpen={loading}>
          <Loading />
        </Modal>
      )}
    </>
  );
}
