import React from 'react';
import { ButtonContainer } from './PrimaryButton.styles';
import { Translation } from '../../index';
export const PrimaryButton = ({ onClick, textId, text, className, btnstyle }) => {
  return (
    <ButtonContainer id="button" onClick={onClick} className={className} style={{ btnstyle }}>
      <Translation id={textId} text={text} />
    </ButtonContainer>
  );
};
