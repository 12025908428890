/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect, useState, memo } from 'react';
import { Switch, useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { getStripePublishKey } from 'tatvarnsdk/Web';
import { routeConfig } from '@app/routeConfig';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '@app/global-styles';
import { getAuth } from 'firebase/auth';
import For from './components/For/For';
import DynamicRouteInjector from './components/DynamicRouteInjector/DynamicRouteInjector';
import { themeColors } from '@app/themes/index';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AppBody } from './App.styles';
import { compose } from 'redux';
import { ToastMessage } from '@modules/common/index';
import { GlobalContainer } from '@app/modules/common/index';

let timeout = null;
const onResize = () => {
  const root = window.document.documentElement;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  root.style.setProperty('--screen-w', screenWidth);
  root.style.setProperty('--screen-h', screenHeight);
};

export function App(props) {
  const { location } = props;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState();
  const [userTheme, setUserTheme] = useState('dark');
  const [secretKey, setSecretKey] = useState(null);
  const [userDocId, setuserDocId] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState(null);
  // const [mobileViewChange, setMobileViewChange] = useState(false);
  // const [popUp, setPopUp] = useState(false);
  //TODO: create a separate hook for useTheme(<value>)
  let theme;
  switch (userTheme) {
    case 'light':
      theme = { ...themeColors.light };
      break;
    case 'dark':
      theme = { ...themeColors.dark };
      break;
    default:
      theme = { ...themeColors.dark };
      break;
  }

  async function fetchData() {
    let stripeKeyVal = await getStripePublishKey();
    setSecretKey(stripeKeyVal);
  }
  if (secretKey === null) {
    fetchData();
  }
  const stripePromise = loadStripe(String(secretKey));

  const history = useHistory();

  //UTIL function
  function findIsProtected(routeConfig, targetRoute) {
    for (const key in routeConfig) {
      if (routeConfig[key].route === targetRoute) {
        return routeConfig[key].isProtected || false;
      }
    }
    return false; // Return false if the route is not found
  }
  useEffect(() => {
    setIsUserLoggedIn(getAuth().currentUser ? true : false);
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      if (user) {
        setIsUserLoggedIn(true);
        let redirectAfterLoginTo =
          location.pathname === '/' ? routeConfig.dashboard.route : location.pathname + location.search;
        if (location.search) {
          const searchArray = location.search.split('&');
          if (searchArray && searchArray.length > 0) {
            searchArray.forEach((search) => {
              if (search.split('=')[0] === '?qrId') {
                redirectAfterLoginTo = location.pathname === '/' ? routeConfig.dashboard.route : location.pathname;
              }
            });
          }
        }
        history.push(redirectAfterLoginTo);
      } else {
        if (findIsProtected(routeConfig, window.location.pathname)) {
          history.push(routeConfig.loginSignUp.route);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     if (!mobileViewChange) {
  //       const isMobile = window.innerWidth < 767;
  //       if (isMobile) {
  //         setMobileViewChange(true);
  //       } else {
  //         setMobileViewChange(false);
  //         setPopUp(false);
  //       }
  //     } else {
  //       const isPortrait = window.matchMedia('(orientation: portrait)').matches;
  //       if (!isPortrait) {
  //         setPopUp(true);
  //         setMobileViewChange(false);
  //       } else {
  //         setPopUp(false);
  //       }
  //     }
  //   };

  //   window.addEventListener('resize', handleOrientationChange);

  //   return () => {
  //     window.removeEventListener('resize', handleOrientationChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (mobileViewChange) {
  //     console.log('mobileViewChange is true');
  //   }
  // }, [mobileViewChange]);

  if (!userDocId && location.search) {
    const searchArray = location.search.split('&');
    if (searchArray && searchArray.length > 0) {
      searchArray.forEach((search) => {
        if (search.split('=')[0] === '?qrId') {
          setuserDocId(search.split('=')[1]);
        } else if (search.split('=')[0] === 'classId') {
          setSelectedClassId(search.split('=')[1]);
        }
      });
    }
  }

  useEffect(() => {
    onResize();
    const resizeEventCB = () => {
      const delay = 500;
      clearTimeout(timeout);
      // start timing for event "completion"
      timeout = setTimeout(onResize, delay);
    };
    window.addEventListener('resize', resizeEventCB);
    return () => {
      window.removeEventListener('resize', resizeEventCB);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {secretKey && (
        <Elements stripe={stripePromise}>
          <AppBody>
            <GlobalContainer>
              <ToastMessage />
              {/* TODO: PHONE VERTICAL TO HORINZONTAL ROTATE POP_UP */}
              {/* <FailedModal isOpen={popUp} setIsOpen={setPopUp}>
              {mobileViewChange && (
                <AlertPopUp
                  title="Warning"
                  description1="Our Website Works best on vertical view"
                  setIsOpen={setPopUp}
                />
              )}
            </FailedModal> */}
              <For
                ParentComponent={(props) => <Switch {...props} />}
                of={map(Object.keys(routeConfig))}
                renderItem={(routeKey, index) => {
                  const Component = routeConfig[routeKey].component;
                  return (
                    <DynamicRouteInjector
                      isLoggedIn={isUserLoggedIn}
                      exact={routeConfig[routeKey].exact}
                      routeKey={routeKey}
                      path={routeConfig[routeKey].route}
                      render={(props) => {
                        const updatedProps = {
                          ...props,
                          ...routeConfig[routeKey].props,
                          userDocId,
                          selectedClassId,
                          setuserDocId
                        };
                        return <Component {...updatedProps} />;
                      }}
                      key={index}
                    />
                  );
                }}
              />
            </GlobalContainer>
          </AppBody>
        </Elements>
      )}
    </ThemeProvider>
  );
}
// }
App.propTypes = {
  location: PropTypes.object
};
export default compose(withRouter)(App);
