import React, { useEffect, memo, useState } from 'react';
import { getFirestore } from 'firebase/firestore';
import { tatvaLandingLogo } from '@app/themes/images';
import { checkOTP, formatTimer, reCaptcha, verifyUser } from '../Utils/utils';
import {
  Captcha,
  FormContainer,
  OtpButton,
  SMSIcon,
  StyledInput,
  StyledLogoImg,
  StyledMobileInput
} from './Login.styles';
// import * as yup from 'yup';

// let otpSchema = yup.object({
//   otp: yup.number().required('required field').min(6, 'input correct otp')
// });

export default function Login(props) {
  const {
    mobile,
    setMobile,
    verifyOTP,
    setVerifyOTP,
    otp,
    setOtp,
    isTimerRunning,
    setIsTimerRunning,
    timer,
    setTimer,
    setLoading,
    setIsSignUp
  } = props;
  const [captchaResult, setCaptchaResult] = useState('');
  const [enable, setEnable] = useState(true);
  const sendOtp = async () => {
    setLoading(true);
    const res = await verifyUser({ mobile });
    if (res) {
      setLoading(false);
      setEnable(false);
      const result = await reCaptcha(mobile);
      setCaptchaResult(result);
      if (result.status) {
        setVerifyOTP(true);
        setTimer(60);
        setIsTimerRunning(true);
      }
    }
    setLoading(false);
  };
  const verify = async (otp) => {
    setLoading(true);
    await checkOTP(captchaResult.confirmationResult, otp);
    setLoading(false);
  };
  useEffect(() => {
    let interval;
    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning, timer]);
  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
    };
    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, null, window.location.href);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  return (
    <FormContainer>
      <StyledLogoImg src={tatvaLandingLogo} />
      <div className="title">Log in</div>
      <StyledMobileInput
        value={mobile}
        onChange={(phone) => {
          setMobile(phone);
        }}
      />

      {verifyOTP && (
        <StyledInput
          adornment={<SMSIcon />}
          placeholder={'Enter OTP'}
          value={otp}
          onChange={(event) => {
            setOtp(event.target.value);
          }}
          maxLength="6"
        />
      )}
      {verifyOTP ? (
        <OtpButton
          text="Verify OTP"
          onClick={() => {
            verify(otp);
          }}
        />
      ) : mobile && enable ? (
        <OtpButton
          text="Send OTP"
          disabled={false}
          onClick={() => {
            sendOtp(setVerifyOTP, setTimer, setIsTimerRunning);
          }}
        />
      ) : (
        <OtpButton text="Send OTP" disabled={true} />
      )}
      <Captcha>
        <div id="recaptcha-container" />
      </Captcha>
      {verifyOTP ? (
        isTimerRunning ? (
          <div className="resend">Resend in {formatTimer(timer)}</div>
        ) : (
          <div
            className="resend active"
            onClick={() => {
              setVerifyOTP(false);
              sendOtp();
            }}
          >
            Resend OTP
          </div>
        )
      ) : (
        ''
      )}
      {/* <div className="signup_text">
        <span className="grey_text">Don’t have an account? </span>
        <span
          className="highlight_text"
          onClick={() => {
            setIsSignUp(true);
          }}
        >
          Sign Up
        </span>
      </div> */}
    </FormContainer>
  );
}
