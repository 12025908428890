/* eslint-disable react/prop-types */
import React from 'react';
import {
  StyledWhiteT,
  ClassListContainer,
  ClassCard,
  ClassImage,
  ClassVideo,
  BelowImageContainer,
  ClassTitle,
  Row,
  LanguageAndTimeContainer,
  ClassTiming,
  Teacher,
  CommunityClassTag,
  CommunityClassContainer,
  FirstCard,
  LastCard,
  OuterContainer
} from './ClassSlider.styles';
import moment from 'moment';
import { getDateFromTimestampObject } from '@app/utils/functionalUtils';
import { Translation } from '@app/modules/common/index';

//TODO: use moment instead of time
export default function ClassSlider({ category, onClicked }) {
  const getTodaysTime = (sessionTime) => {
    if (!sessionTime) {
      return '';
    }
    const date = getDateFromTimestampObject(sessionTime);
    const time = moment.unix(sessionTime._seconds).format('hh:mm A');
    let timeString = `${date.toDateString()}, ${time}`;
    timeString = `${timeString.substring(0, 3)}, ${timeString.substring(3)} `;
    return timeString;
  };

  const getLanguages = (langList) => {
    if (!langList || langList.length < 1) {
      return '';
    }
    const len = langList.length;
    if (len > 1) {
      return `${langList[0]} + ${len - 1}`;
    }
    return `${langList[0]}`;
  };

  const getDuration = (durationInMin) => {
    if (durationInMin < 60) {
      return `${durationInMin} min`;
    } else {
      const hours = Math.floor(durationInMin / 60);
      const min = durationInMin % 60;
      if (min > 0) {
        return `${hours} hr ${min} min`;
      }
      return `${hours} hr`;
    }
  };

  const isCommunity = (session) => {
    try {
      return session.categories.split(',').includes('CC');
    } catch (e) {
      return false;
    }
  };

  const DisplyCover = ({ classDetails }) => {
    let displayCoverPic = classDetails?.coverPicUrl;
    if (classDetails?.classMedia?.length > 0) {
      for (let i = 0; i < classDetails?.classMedia.length; i++) {
        if (!classDetails?.classMedia[i].downloadUrl.includes('.mp4')) {
          displayCoverPic = classDetails?.classMedia[i].downloadUrl;
          break;
        }
      }
    }

    if (!displayCoverPic.includes('.mp4')) {
      return <ClassImage src={displayCoverPic} />;
    }
    return (
      <ClassVideo autoplay muted>
        <source src={displayCoverPic} type="video/mp4" />
        Unable to launch video
      </ClassVideo>
    );
  };

  return (
    <OuterContainer>
      <StyledWhiteT text={category.name.toUpperCase()} />
      <ClassListContainer>
        {category.classes.map((item, index) => {
          if (index === 0) {
            return (
              <FirstCard key={`${index}`} onClick={() => onClicked(item)}>
                {isCommunity(item) ? (
                  <CommunityClassContainer>
                    <CommunityClassTag>
                      <Translation id="COMMUNITY" />
                    </CommunityClassTag>
                  </CommunityClassContainer>
                ) : null}
                <DisplyCover classDetails={item} />
                <BelowImageContainer>
                  <ClassTitle>
                    <Translation text={item.title} />
                  </ClassTitle>
                  <Row>
                    <Teacher>
                      <Translation text={item.teacher} />
                    </Teacher>
                    <LanguageAndTimeContainer>
                      <Translation text={getLanguages(item.languages)} />
                    </LanguageAndTimeContainer>
                  </Row>
                  <Row>
                    <ClassTiming>
                      <Translation text={getTodaysTime(item.timeStamp)} />
                    </ClassTiming>
                    <LanguageAndTimeContainer>
                      <Translation text={getDuration(item.averageDuration)} />
                    </LanguageAndTimeContainer>
                  </Row>
                </BelowImageContainer>
              </FirstCard>
            );
          } else if (index === category.classes.length - 1) {
            return (
              <LastCard key={`${index}`} onClick={() => onClicked(item)}>
                {isCommunity(item) ? (
                  <CommunityClassContainer>
                    <CommunityClassTag>
                      <Translation id="COMMUNITY" />
                    </CommunityClassTag>
                  </CommunityClassContainer>
                ) : null}
                <DisplyCover classDetails={item} />
                <BelowImageContainer>
                  <ClassTitle>
                    <Translation text={item.title} />
                  </ClassTitle>
                  <Row>
                    <Teacher>
                      <Translation text={item.teacher} />
                    </Teacher>
                    <LanguageAndTimeContainer>
                      <Translation text={getLanguages(item.languages)} />
                    </LanguageAndTimeContainer>
                  </Row>
                  <Row>
                    <ClassTiming>
                      <Translation text={getTodaysTime(item.timeStamp)} />
                    </ClassTiming>
                    <LanguageAndTimeContainer>
                      <Translation text={getDuration(item.averageDuration)} />
                    </LanguageAndTimeContainer>
                  </Row>
                </BelowImageContainer>
              </LastCard>
            );
          } else {
            return (
              <ClassCard key={`${index}`} onClick={() => onClicked(item)}>
                {isCommunity(item) ? (
                  <CommunityClassContainer>
                    <CommunityClassTag>
                      <Translation id="COMMUNITY" />
                    </CommunityClassTag>
                  </CommunityClassContainer>
                ) : null}
                <DisplyCover classDetails={item} />
                <BelowImageContainer>
                  <ClassTitle>
                    <Translation text={item.title} />
                  </ClassTitle>
                  <Row>
                    <Teacher>
                      <Translation text={item.teacher} />
                    </Teacher>
                    <LanguageAndTimeContainer>
                      <Translation text={getLanguages(item.languages)} />
                    </LanguageAndTimeContainer>
                  </Row>
                  <Row>
                    <ClassTiming>
                      <Translation text={getTodaysTime(item.timeStamp)} />
                    </ClassTiming>
                    <LanguageAndTimeContainer>
                      <Translation text={getDuration(item.averageDuration)} />
                    </LanguageAndTimeContainer>
                  </Row>
                </BelowImageContainer>
              </ClassCard>
            );
          }
        })}
      </ClassListContainer>
    </OuterContainer>
  );
}
