import React, { memo, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory, Redirect } from 'react-router-dom';
import { routeConfig } from '@app/routeConfig';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { selectError, selectLoading, selectSecretkey, selectPaymentMethodId, selectPaymentStatus } from './selectors';
import { selectPlans, selectCouponLoading, selectCoupon } from '../Subscription/selectors';
import { paymentCreators } from './reducer';
import { connect } from 'react-redux';
import paymentSaga from './saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';
import CouponView from '../CouponView/CouponView';

import {
  PaymentScreenContainer,
  LoadingContainer,
  Body,
  Row1,
  Row2,
  HeaderWrapper,
  BackButtonWrapper,
  Arrow,
  CouponContainer
} from './Payment.styles';
import { HeaderWithLogo, Loading, showToast, Translation } from '@modules/common/index';
import { PaymentSummary } from './components/PaymentSummary/PaymentSummary';
import { CardDetailsAndSavedCards } from './components/CardDetailsAndSavedCards/CardDetailsAndSavedCards';
import { CancellationPolicy } from './components/CancellationPolicy/CancellationPolicy';
import { logToCrashalytics, recordErrorInCrashalytics, NOTIFICATION_TYPE, getCheckoutDetails } from 'tatvarnsdk/Web';

function Payment(props) {
  const history = useHistory();
  const stripe = useStripe();
  const {
    error,
    loading,
    secretKey,
    paymentMethodId,
    paymentStatus,
    dispatchResotrePaymentStatus,
    dispatchDeclineCardPayment,
    dispatchPayForProduct,
    allPlans,
    couponLoading,
    coupon
  } = props;

  const state = props.history.location.state;
  if (!state) {
    return <Redirect to={routeConfig.dashboard.route} />;
  }
  const { planId, userProfile, userSubscription, cardDetails, disciplineObj } = state;
  const planDetailsArr = (allPlans || []).filter((plan) => planId === plan.id);
  const planDetails = planDetailsArr && planDetailsArr.length > 0 ? planDetailsArr[0] : null;
  let updatedPlan = {
    ...planDetails,
    discipline: disciplineObj
  };
  const planData = getCheckoutDetails(planDetails, userProfile);
  const [showSavedCards, setShowSavedCard] = useState(cardDetails ? true : false);
  const price = planDetails.discount ? planDetails.discountedPrice : planDetails.unit_amount;
  const [sticker, setSticker] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setSticker(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const onPayUsingSavedCard = (paymentMethodId) => {
    if (!paymentMethodId) {
      recordErrorInCrashalytics('Payment Error', 'No paymentmethod id');
      return;
    }
    const subscriptionData = { ...planDetails, discipline: disciplineObj?.id };
    dispatchPayForProduct(price, null, subscriptionData, coupon, paymentMethodId, updatedPlan);
  };

  const onPayUsingNewCard = (newCardDetails) => {
    const subscriptionData = { ...planDetails, discipline: disciplineObj?.id };
    dispatchPayForProduct(price, newCardDetails, subscriptionData, coupon, null, updatedPlan);
  };

  const onBackBtnClicked = () => {
    if (!cardDetails) {
      history.push({
        pathname: routeConfig.subscription.route,
        state: { success: true, revertToPreviousPath: coupon ? true : false }
      });
      return;
    }
    if (showSavedCards) {
      history.push({
        pathname: routeConfig.subscription.route,
        state: { success: true, revertToPreviousPath: coupon ? true : false }
      });
      return;
    }
    setShowSavedCard(true);
    return;
  };
  useEffect(() => {
    if (paymentStatus == true) {
      //TODO:remove this error being passed error is handled here only!
      history.push({
        pathname: routeConfig.paymentSuccess.route,
        state: {
          success: true,
          planDetails: planDetails,
          error: null,
          userProfile: userProfile,
          userSubscription: userSubscription,
          disciplineObj: disciplineObj
        }
      });
    }
    // }, [secretKey, paymentMethodId, paymentStatus]);
  }, [paymentStatus]);

  useEffect(() => {
    if (secretKey && paymentMethodId) {
      stripe
        .confirmCardPayment(secretKey, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          let paymentSuccess = true;
          let errorResult = null;

          if (result.error) {
            showToast({
              titleId: 'OOPS!',
              message: 'PAYMENT DECLINED',
              notificationType: NOTIFICATION_TYPE.ERROR
            });
            //MAKE A DISPATCH FUNCTION
            dispatchDeclineCardPayment();
          } else {
            //TODO:remove this error being passed error is handled here only!
            history.push({
              pathname: routeConfig.paymentSuccess.route,
              state: {
                success: paymentSuccess,
                planDetails: planDetails,
                error: errorResult,
                userProfile: userProfile,
                //TODO: why send the userSubscription?
                userSubscription: userSubscription,
                disciplineObj: disciplineObj
              }
            });
          }
        })
        .catch((err) => {
          recordErrorInCrashalytics('confirmCardPayment => modules/payment/payment.jsx', err);
        });
    }
  }, [paymentMethodId, secretKey]);

  useEffect(() => {
    if (error) {
      dispatchResotrePaymentStatus();
      // history.push({
      //   pathname: routeConfig.paymentSuccess.route,
      //   state: { success: false, planDetails: planDetails, error: error }
      // });
    }
  }, [error]);

  useEffect(() => {
    logToCrashalytics('Payment Screen Opened');
    dispatchResotrePaymentStatus();
  }, []);

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
      const state = { success: true, revertToPreviousPath: coupon ? true : false };
      // window.history.pushState(state, null, routeConfig.subscription.route);
      history.push({
        pathname: routeConfig.subscription.route,
        state: { success: true, revertToPreviousPath: coupon ? true : false }
      });
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  useEffect(() => {
    logToCrashalytics('Payment Container');
  }, []);
  return (
    <PaymentScreenContainer>
      <HeaderWrapper>
        <Arrow onClick={onBackBtnClicked} />
        <HeaderWithLogo />
      </HeaderWrapper>
      {loading || couponLoading ? (
        <LoadingContainer>
          <div>
            <Loading />
            <p>
              <Translation id="RELOAD_PAGE_WARNING" />
            </p>
          </div>
        </LoadingContainer>
      ) : (
        <Body>
          <Row1>
            <CardDetailsAndSavedCards
              showSavedCards={showSavedCards}
              setShowSavedCard={setShowSavedCard}
              userCardDetails={cardDetails}
              onPayUsingSavedCard={onPayUsingSavedCard}
              onPayUsingNewCard={onPayUsingNewCard}
              loading={loading}
            />
            <PaymentSummary
              planDetails={planDetails}
              disciplineObj={disciplineObj}
              planData={planData}
              coupon={coupon}
            />
          </Row1>
          <CouponContainer>
            <CouponView />
          </CouponContainer>
          <Row2>
            <div className="cancelPolicy">
              <CancellationPolicy />
            </div>
            {sticker >= 767 ? <BackButtonWrapper id="back" onClick={onBackBtnClicked} /> : ''}
          </Row2>
        </Body>
      )}
    </PaymentScreenContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: selectError(),
  couponLoading: selectCouponLoading(),
  coupon: selectCoupon(),
  loading: selectLoading(),
  secretKey: selectSecretkey(),
  paymentMethodId: selectPaymentMethodId(),
  paymentStatus: selectPaymentStatus(),
  allPlans: selectPlans()
});

export function mapDispatchToProps(dispatch) {
  const { resetKeys, restorePaymentStatus, declineCardPayment, payForProduct } = paymentCreators;
  return {
    dispatchPayForProduct: (price, cardDetails, subscriptionData, coupon, paymentMethodId, product) =>
      dispatch(payForProduct(price, cardDetails, subscriptionData, coupon, paymentMethodId, product)),
    dispatchResetKeys: () => dispatch(resetKeys()),
    dispatchResotrePaymentStatus: () => dispatch(restorePaymentStatus()),
    dispatchDeclineCardPayment: () => dispatch(declineCardPayment())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect, memo, injectSaga({ key: 'payment', saga: paymentSaga }))(Payment);
