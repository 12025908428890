import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { media } from '@app/themes/index';

export const CustomizedToast = styled(ToastContainer)`
  &.Toastify__toast-container {
    max-width: 63.539rem;
    width: 100%;
    align-self: center;
  }
  .Toastify__toast {
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    padding: 0;
    min-height: 3rem;
  }
  .Toastify__toast-body {
    color: #1d2327;
    font-size: 1.125rem;
    font-family: SF Pro Text;
    font-weight: 1000;
    margin: 0;
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__toast--info {
    border: 0.5px solid #036fe5;
    background: #e6f2fe;
  }

  .Toastify__toast--success {
    border: 0.5px solid #4fb200;
    background: #e9f8ee;
  }

  .Toastify__toast--error {
    border: 0.5px solid #d70404;
    background: #fcede9;
  }
  .Toastify__toast--warning {
    border: 0.5px solid #f90;
    background: #fef7ea;
  }
`;
