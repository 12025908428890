import React from 'react';
import { Heading, Description } from './CancellationPolicy.styles';
import { Translation } from '@app/modules/common/index';

export const CancellationPolicy = () => {
  return (
    <div>
      <Heading>
        <Translation id="cancel_policy" />
      </Heading>
      <Description>
        <Translation id="cancel_desc" />
      </Description>
    </div>
  );
};
