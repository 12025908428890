import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavbarLink = styled(NavLink)`
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
`;

export const OuterNavbarLink = styled.div`
  a.active {
    color: ${(props) => props.theme.highlight};
  }
`;
